import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { TranslateService } from "@ngx-translate/core";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { ImageService } from "../../../shared/services/image.service";
import { CustomFormValidators } from "../../../shared/validators/custom-form-validators";

import { PartnerCampaignDetails } from "../../../shared/models/partnerCampaignDetail";

import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { PartnerGoogleAdPostLog } from "../../../shared/models/partnerGoogleAdPostLog";
import { CreateZipFileInteractor } from "../../../features/shared/create-zip-file.interactor";
import { DownloadFileInteractor } from "../../../features/shared/download-file.interactor";
import { PartnerContentDownloadTrackingService } from "../shared/services/partner-content-download-tracking.service";

export class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    return !control?.valid && !!form?.invalid;
  }
}

@Component({
  selector: "app-partner-campaign-google-ad-edit",
  templateUrl: "./partner-campaign-google-ad-edit.component.html",
  styleUrls: ["./partner-campaign-google-ad-edit.component.scss"],
})
export class PartnerCampaignGoogleAdEditComponent implements OnInit {
  @Input({ required: true }) public campaign!: PartnerCampaign;
  @Input({ required: true }) public campaignDetails!: PartnerCampaignDetails;
  @Input({ required: true }) public post!: PartnerGoogleAdPostLog;
  @Input() public showLongMockFormat = false;
  @Input() public showDownloadIcon = false;
  @Input() public validForm = true;

  @Output() public validFormChange: EventEmitter<any> = new EventEmitter<any>();

  protected customErrorStateMatcher = new CustomErrorStateMatcher();
  protected form!: UntypedFormGroup;
  protected showDescriptionIndex = 1;
  protected showHeadlineIndex = 1;

  private readonly GOOGLE_ADS_VALIDATORS = [
    CustomFormValidators.googleAdsChars,
    CustomFormValidators.twoConsecutiveDots,
  ];

  constructor(
    private readonly downloadTrackingService: PartnerContentDownloadTrackingService,
    private readonly imageService: ImageService,
    private readonly translateService: TranslateService,
    private readonly createZipFile: CreateZipFileInteractor,
    private readonly downloadFile: DownloadFileInteractor,
  ) {}

  public ngOnInit(): void {
    this.form = new UntypedFormGroup({
      headlineOne: new UntypedFormControl(this.post.headlineOne, [
        Validators.required,
        ...this.GOOGLE_ADS_VALIDATORS,
      ]),
      headlineTwo: new UntypedFormControl(
        this.post.headlineTwo,
        this.GOOGLE_ADS_VALIDATORS,
      ),
      headlineThree: new UntypedFormControl(
        this.post.headlineThree,
        this.GOOGLE_ADS_VALIDATORS,
      ),
      headlineFour: new UntypedFormControl(
        this.post.headlineFour,
        this.GOOGLE_ADS_VALIDATORS,
      ),
      headlineFive: new UntypedFormControl(
        this.post.headlineFive,
        this.GOOGLE_ADS_VALIDATORS,
      ),
      longHeadline: new UntypedFormControl(this.post.longHeadline, [
        Validators.required,
        ...this.GOOGLE_ADS_VALIDATORS,
      ]),
      descriptionOne: new UntypedFormControl(this.post.descriptionOne, [
        Validators.required,
        ...this.GOOGLE_ADS_VALIDATORS,
      ]),
      descriptionTwo: new UntypedFormControl(
        this.post.descriptionTwo,
        this.GOOGLE_ADS_VALIDATORS,
      ),
      descriptionThree: new UntypedFormControl(
        this.post.descriptionThree,
        this.GOOGLE_ADS_VALIDATORS,
      ),
      descriptionFour: new UntypedFormControl(
        this.post.descriptionFour,
        this.GOOGLE_ADS_VALIDATORS,
      ),
      descriptionFive: new UntypedFormControl(
        this.post.descriptionFive,
        this.GOOGLE_ADS_VALIDATORS,
      ),
    });

    this.form.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.sendFormEditedEvent();
      });
  }

  protected async download(): Promise<void> {
    const url = URL.createObjectURL(
      await this.createZipFile.execute(
        this.post.images.map((slide) => slide.url),
      ),
    );

    this.trackDownload();
    this.downloadFile.execute(url);
  }

  private trackDownload(): void {
    this.downloadTrackingService.trackCampaignContentDownload(
      this.campaign.id,
      {
        postId: !this.post.isPostLog ? this.post.id : undefined,
        postLogId: this.post.isPostLog ? this.post.id : undefined,
      },
    );
  }

  /* TODO: Replicate what we did in campaign-content-form-post-video-dialog.component.ts */

  private sendFormEditedEvent(): void {
    const value = (field: string) => this.form.get(field)?.value ?? "";

    this.validForm = this.form.valid;

    this.post.headlineOne = value("headlineOne");
    this.post.headlineTwo = value("headlineTwo");
    this.post.headlineThree = value("headlineThree");
    this.post.headlineFour = value("headlineFour");
    this.post.headlineFive = value("headlineFive");
    this.post.longHeadline = value("longHeadline");
    this.post.descriptionOne = value("descriptionOne");
    this.post.descriptionTwo = value("descriptionTwo");
    this.post.descriptionThree = value("descriptionThree");
    this.post.descriptionFour = value("descriptionFour");
    this.post.descriptionFive = value("descriptionFive");

    this.validFormChange.emit(this.validForm);
  }

  protected resizeImage(url: string, imagePublicId: string): string {
    return this.imageService.resizeImage(url, 1000, imagePublicId);
  }

  protected getForbiddenCharErrorMessage(): string {
    return (
      this.translateService.instant("googleAd.edit.charError") +
      ": " +
      CustomFormValidators.GOOGLE_ADS_FORBIDDEN_CHARS.join(" , ")
    );
  }

  protected showNextHeadline(): void {
    this.showHeadlineIndex++;
  }

  protected showNextDescription(): void {
    this.showDescriptionIndex++;
  }
}
