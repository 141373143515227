import { Component } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { PreApprovalSource } from "../../../shared/models/partnerCampaign";

export type PartnerCampaignPreApproveDialogData = undefined;
export type PartnerCampaignPreApproveDialogResult = PreApprovalSource;

@Component({
  selector: "app-partner-campaign-pre-approve-dialog",
  templateUrl: "./partner-campaign-pre-approve-dialog.component.html",
  styleUrl: "./partner-campaign-pre-approve-dialog.component.scss",
})
export class PartnerCampaignPreApproveDialogComponent {
  // This flag must be removed when the feature is activated
  // https://app.asana.com/0/1202486247410321/1201706619670057/f
  protected readonly canPreApproveABrand = false;
  protected readonly Sources = PreApprovalSource;
  protected form: UntypedFormGroup;

  constructor(
    private readonly dialogRef: MatDialogRef<
      PartnerCampaignPreApproveDialogComponent,
      PartnerCampaignPreApproveDialogResult
    >,
  ) {
    this.form = new UntypedFormGroup({
      source: new UntypedFormControl(PreApprovalSource.Campaign),
    });
  }

  protected onActionPreApprove(): void {
    this.dialogRef.close(this.form.get("source")!.value);
  }
}
