<div class="post-stats post-picker">
  <div class="post-picker-content">
    <h2 class="publish-title">
      {{ "partner.publishPost.selectDate" | translate }}
    </h2>
    <mat-form-field
      class="white-field"
      [ngClass]="{ stacked: false, compressed: false }"
    >
      <mat-label>{{
        "partner.publishPost.selectDatePlaceholder" | translate
      }}</mat-label>
      <input
        [(ngModel)]="scheduledPublishDate"
        [min]="minDate | dayjsTransform"
        [max]="maxDate | dayjsTransform"
        matInput
        [matDatepicker]="picker"
        tabindex="-1"
        (dateChange)="updatePostDate()"
        (focus)="picker.open()"
      />
      <mat-hint *ngIf="!checkDateSelected" class="error-hint">
        {{ "partner.publishPost.invalidDate" | translate }}
      </mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="white-field">
      <mat-label>{{ "shared.hours" | translate }}</mat-label>
      <mat-select
        [disabled]="!scheduledPublishDate"
        [(ngModel)]="scheduledPublishDateHour"
        (selectionChange)="updatePostDate()"
      >
        <mat-option *ngFor="let hour of HOURS_24" [value]="hour">
          {{ hour }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="white-field">
      <mat-label>{{ "shared.minutes" | translate }}</mat-label>
      <mat-select
        [disabled]="!scheduledPublishDate"
        [(ngModel)]="scheduledPublishDateMinute"
        (selectionChange)="updatePostDate()"
      >
        <mat-option *ngFor="let minute of MINUTES" [value]="minute">
          {{ minute }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- Facebook Post -->
    <section *ngIf="showPostToCheckboxes()" class="facebook-post-platform">
      <div>
        <div class="facebook-post-platform-title">
          {{ "partner.publishPost.wherePublish" | translate }}
        </div>
        <div class="facebook-post-platform-checkbox">
          <mat-checkbox
            class="white"
            [(ngModel)]="postToFacebook"
            name="post-to-facebook"
          >
            {{ "partner.publishPost.facebook" | translate }}
          </mat-checkbox>
        </div>
        <div class="facebook-post-platform-checkbox">
          <mat-checkbox
            class="white"
            [disabled]="isPostToInstagramDisabled"
            [(ngModel)]="postToInstagram"
            name="post-to-instagram"
          >
            {{ "partner.publishPost.instagram" | translate }}
          </mat-checkbox>
        </div>
      </div>
    </section>
    <!-- Google Ad Partner Pending case -->
    <section
      *ngIf="
        !showPromoAdsOption() &&
        postLog &&
        !isAPostLogWithAlreadyPaidAd() &&
        campaign.hasReachedAdLimit &&
        !postLog?.isLinkedInPost
      "
      class="promo-ads"
    >
      <div *ngIf="campaign.hasReachedAdLimit && !campaign.hasFinished">
        <div class="max-allowance-text">
          {{ "partner.publishPost.maxAdAllowanceReached" | translate }}
        </div>
        <mat-checkbox
          class="add-more-budget-checkbox white"
          [(ngModel)]="isPromoAd"
          [disabled]="true"
          name="use-promo-ads-check"
        >
          {{ "partner.publishPost.addMoreBudget" | translate }}
        </mat-checkbox>
      </div>
    </section>
    <!-- Facebook Ads -->
    <section *ngIf="showPromoAdsOption()" class="promo-ads">
      <div class="promo-ads-title">
        {{ "partner.publishPost.promoAds" | translate }}
      </div>
      <!-- POST LOG CASE -->
      <div class="promo-ads-content" *ngIf="postLog">
        <div *ngIf="isAPostLogWithAlreadyPaidAd()">
          <mat-checkbox
            class="white"
            [disabled]="true"
            [(ngModel)]="isPromoAd"
            name="use-promo-ads-check"
          >
            {{ "partner.publishPost.usePromoAds" | translate }}
          </mat-checkbox>
          <div class="promo-ads-own-budget">
            {{
              "partner.publishPost.adPromotedWithOwnBudget"
                | translate
                  : {
                      budget: postLog.ad.budget | number: ".2-2",
                      currency: campaign.currencySymbol
                    }
            }}
          </div>
        </div>
        <div *ngIf="!isAPostLogWithAlreadyPaidAd()">
          <div *ngIf="postLog.hasAd">
            <!-- Existing ad already approved-->
            <div *ngIf="postLog.isPartnerApproved">
              <mat-checkbox
                class="white"
                [(ngModel)]="isPromoAd"
                name="use-promo-ads-check"
              >
                {{ "partner.publishPost.usePromoAds" | translate }}
              </mat-checkbox>
            </div>
            <!-- Existing ad partner pending to be approved-->
            <div *ngIf="!postLog.isPartnerApproved">
              <!-- If has not reached the limit -->
              <div *ngIf="!campaign.hasReachedAdLimit">
                <mat-checkbox
                  class="white"
                  [(ngModel)]="isPromoAd"
                  name="use-promo-ads-check"
                >
                  {{ "partner.publishPost.usePromoAds" | translate }}
                </mat-checkbox>
              </div>
              <!-- If has reached the limit -->
              <div *ngIf="campaign.hasReachedAdLimit && !campaign.hasFinished">
                <div class="max-allowance-text">
                  {{ "partner.publishPost.maxAdAllowanceReached" | translate }}
                </div>
                <mat-checkbox
                  class="add-more-budget-checkbox white"
                  [(ngModel)]="isPromoAd"
                  name="use-promo-ads-check"
                >
                  {{ "partner.publishPost.addMoreBudget" | translate }}
                </mat-checkbox>
              </div>
            </div>
          </div>
          <div *ngIf="!postLog.hasAd">
            <div *ngIf="!campaign.hasReachedAdLimit">
              <mat-checkbox
                class="white"
                [(ngModel)]="isPromoAd"
                name="use-promo-ads-check"
              >
                {{ "partner.publishPost.usePromoAds" | translate }}
              </mat-checkbox>
            </div>
            <div *ngIf="campaign.hasReachedAdLimit && !campaign.hasFinished">
              <div class="max-allowance-text">
                {{ "partner.publishPost.maxAdAllowanceReached" | translate }}
              </div>
              <mat-checkbox
                class="add-more-budget-checkbox white"
                [(ngModel)]="isPromoAd"
                name="use-promo-ads-check"
              >
                {{ "partner.publishPost.addMoreBudget" | translate }}
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
      <!-- POST CASE -->
      <div *ngIf="post" class="promo-ads-content">
        <div *ngIf="!campaign.hasReachedAdLimit">
          <mat-checkbox
            class="white"
            [(ngModel)]="isPromoAd"
            name="use-promo-ads-check"
          >
            {{ "partner.publishPost.usePromoAds" | translate }}
          </mat-checkbox>
        </div>
        <div *ngIf="campaign.hasReachedAdLimit && !campaign.hasFinished">
          <div class="max-allowance-text">
            {{ "partner.publishPost.maxAdAllowanceReached" | translate }}
          </div>
          <mat-checkbox
            class="add-more-budget-checkbox white"
            [(ngModel)]="isPromoAd"
            name="use-promo-ads-check"
          >
            {{ "partner.publishPost.addMoreBudget" | translate }}
          </mat-checkbox>
        </div>
      </div>
    </section>
    <div class="buttons-wrapper-margin"></div>
    <!-- New Post -->
    <div *ngIf="post" class="buttons-wrapper buttons-wrapper--stick-to-bottom">
      <button
        [disabled]="isPublishDisabled()"
        class="btn btn--primary btn--full-width promote-ad-button"
        (click)="publishButtonClick()"
      >
        <span *ngIf="isPromoAd && !publishing"
          >{{ "shared.promoteAd" | translate }} ({{
            getAdsCreatedString()
          }})</span
        >
        <span *ngIf="isPromoAd && publishing">{{
          "shared.promoting" | translate
        }}</span>
        <span *ngIf="!isPromoAd && !publishing">{{
          "shared.publishPost" | translate
        }}</span>
        <span *ngIf="!isPromoAd && publishing">{{
          "shared.publishing" | translate
        }}</span>
      </button>
    </div>
    <!-- Existing Post Log -->
    <div
      *ngIf="postLog"
      [ngClass]="{ 'buttons-wrapper--stick-to-bottom': stickButtonToBottom }"
      class="buttons-wrapper"
    >
      <button
        [disabled]="isPublishDisabled()"
        class="btn btn--primary btn--full-width"
        (click)="saveButtonClick()"
      >
        <span *ngIf="!publishing && isPromoAd">{{
          "shared.promoteAd" | translate
        }}</span>
        <span *ngIf="!publishing && !isPromoAd">{{
          "shared.savePost" | translate
        }}</span>
        <span *ngIf="publishing">{{ "shared.saving" | translate }}</span>
      </button>
    </div>
  </div>
</div>
