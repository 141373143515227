import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UrlPrefixes } from "../../shared/models/appData";
import {
  CampaignPartner,
  PartnerCampaign,
} from "../../shared/models/partnerCampaign";
import { PartnerService } from "../../shared/services/api/partner.service";
import { NotificationService } from "../../shared/services/notification.service";
import { UrlFormatterService } from "../../shared/services/url-formatter-service";
import { CustomFormValidators } from "../../shared/validators/custom-form-validators";

export interface PartnerCampaignInvalidShortUrlDialogData {
  readonly campaign: PartnerCampaign;
}

export type PartnerCampaignInvalidShortUrlDialogResult = CampaignPartner;

@Component({
  selector: "app-partner-campaign-invalid-short-url-dialog",
  templateUrl: "./partner-campaign-invalid-short-url-dialog.component.html",
  styleUrl: "./partner-campaign-invalid-short-url-dialog.component.scss",
})
export class PartnerCampaignInvalidShortUrlDialogComponent {
  protected readonly URL_VALIDATION_PATTERN =
    CustomFormValidators.URL_WITH_OBLIGATORY_NO_PROTOCOL_PATTERN;
  protected readonly UrlPrefixes = UrlPrefixes;
  protected campaignPartner: CampaignPartner;
  protected saving = false;

  private campaign: PartnerCampaign;

  constructor(
    private readonly partnerService: PartnerService,
    private readonly notificationService: NotificationService,
    private readonly urlFormatterService: UrlFormatterService,
    private readonly dialogRef: MatDialogRef<
      PartnerCampaignInvalidShortUrlDialogComponent,
      PartnerCampaignInvalidShortUrlDialogResult
    >,
    @Inject(MAT_DIALOG_DATA) data: PartnerCampaignInvalidShortUrlDialogData,
  ) {
    this.campaign = data.campaign;
    this.campaignPartner = this.campaign.partner;
  }

  protected updateCampaignPartner(): void {
    this.saving = true;

    this.partnerService
      .updateCampaignPartner(
        this.campaignPartner.id,
        this.campaign.id,
        this.campaignPartner,
      )
      .subscribe({
        next: (campaign) => {
          this.saving = false;
          this.campaignPartner.shortUrl = campaign.partner.shortUrl;
          this.campaignPartner.shortUrlPrefix = campaign.partner.shortUrlPrefix;
          this.dialogRef.close(this.campaignPartner);
        },
        error: () => {
          this.saving = false;
          this.notificationService.error("shared.changesCouldNotBeSaved");
        },
      });
  }

  protected formatShortUrl(): void {
    if (this.campaignPartner.shortUrl) {
      this.campaignPartner.shortUrl = this.urlFormatterService.formatUrl(
        this.campaignPartner.shortUrl,
      );
    }
  }
}
