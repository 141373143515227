import { Component, Inject, OnDestroy, OnInit, computed } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { Partner } from "../../../shared/models/partner";
import { PartnerService } from "../../../shared/services/api/partner.service";
import { NotificationService } from "../../../shared/services/notification.service";
import { LanguageService } from "../../../shared/services/language.service";

export interface PartnerAuthorizeFacebookAdsDialogData {
  partner: Partner;
}

export type PartnerAuthorizeFacebookAdsDialogResult = true;

@Component({
  selector: "app-partner-authorize-facebook-ads-dialog",
  templateUrl: "./partner-authorize-facebook-ads-dialog.component.html",
  styleUrls: ["./partner-authorize-facebook-ads-dialog.component.scss"],
})
export class PartnerAuthorizeFacebookAdsDialogComponent
  implements OnInit, OnDestroy
{
  public assetsLanguage = computed(() =>
    this.languageService.language.toUpperCase(),
  );
  public facebookPageId: string;
  public loading = false;
  public showPermissionsNotGiven = false;
  public partner: Partner;

  private subscriptions = new Subscription();

  constructor(
    private readonly partnerService: PartnerService,
    private readonly notificationService: NotificationService,
    private readonly languageService: LanguageService,
    private readonly dialogRef: MatDialogRef<
      PartnerAuthorizeFacebookAdsDialogComponent,
      PartnerAuthorizeFacebookAdsDialogResult
    >,
    @Inject(MAT_DIALOG_DATA) data: PartnerAuthorizeFacebookAdsDialogData,
  ) {
    this.partner = data.partner;
    this.facebookPageId = this.partner.facebookPageId;
  }

  public ngOnInit(): void {
    this.claimPage();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public async claimPage(): Promise<void> {
    if (!this.partner) {
      return;
    }
    this.partnerService.claimPage(this.partner.id).subscribe(
      (partner: Partner) => {
        this.partner.facebookPageClaimStatus = partner.facebookPageClaimStatus;
        if (this.partner.isAuthorizedFBAds) {
          this.completeAndClose();
        }
      },
      () => {
        // OK, the page may already be claimed
      },
    );
  }

  protected completeAndClose(): void {
    this.notificationService.success("partner.setUpFacebookAds.authSuccess");
    this.dialogRef.close(true);
  }

  public checkClaimStatus(): void {
    if (!this.partner) {
      return;
    }
    this.loading = true;
    this.showPermissionsNotGiven = false;
    this.subscriptions.add(
      this.partnerService.claimStatus(this.partner.id).subscribe(
        (partner: Partner) => {
          this.partner.facebookPageClaimStatus =
            partner.facebookPageClaimStatus;
          if (this.partner.isAuthorizedFBAds) {
            this.completeAndClose();
          } else {
            this.showPermissionsNotGiven = true;
          }
          this.loading = false;
        },
        () => {
          this.loading = false;
          this.showPermissionsNotGiven = true;
        },
      ),
    );
  }
}
