import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PartnerFacebookPostLog } from "../../../shared/models/partnerFacebookPostLog";
import { PostLog } from "../../../shared/models/postLog";
import { PartnerCampaignPostLogService } from "../../shared/services/partner-campaign-post-log.service";
import { NotificationService } from "../../../shared/services/notification.service";
import { RequestErrorService } from "../../../shared/services/request-error.service";
import { lastValueFrom } from "rxjs";

export interface DeletePostDialogData {
  post: PostLog;
}

export type DeletePostDialogResult = true;

@Component({
  selector: "app-delete-post-dialog",
  templateUrl: "./delete-post-dialog.component.html",
  styleUrls: ["./delete-post-dialog.component.scss"],
})
export class DeletePostDialogComponent {
  public deleting = false;
  public readonly post: PostLog;

  constructor(
    private readonly partnerCampaignPostLogService: PartnerCampaignPostLogService,
    private readonly notificationService: NotificationService,
    private readonly requestErrorService: RequestErrorService,
    private readonly dialogRef: MatDialogRef<
      DeletePostDialogComponent,
      DeletePostDialogResult
    >,
    @Inject(MAT_DIALOG_DATA) data: DeletePostDialogData,
  ) {
    this.post = data.post;
  }

  public async onActionDeletePost(): Promise<void> {
    this.deleting = true;
    try {
      await lastValueFrom(
        this.partnerCampaignPostLogService.deleteCampaignPostLog(this.post.id),
      );
      this.notificationService.success("shared.changesSavedSuccessfully");
    } catch (err) {
      const error = (err as any).error;
      if (error && error.key === "FB_PARTNER_AUTH_ERROR") {
        this.requestErrorService.partnerNotConnectedError();
      } else {
        this.notificationService.error("shared.changesCouldNotBeSaved");
      }
    }
    this.deleting = false;
    this.dialogRef.close(true);
  }

  public isAd(): boolean {
    return (
      this.post.isGoogleAd ||
      (this.post instanceof PartnerFacebookPostLog && this.post.isDarkPost)
    );
  }
}
