<a class="close-icon" [mat-dialog-close]="false">
  <mat-icon>close</mat-icon>
</a>
<mat-dialog-content>
  <app-loader
    [text]="
      'partner.campaign.contentCalendar.setupDialog.checkingDetails' | translate
    "
  >
  </app-loader>
  <div class="row">
    <div class="col width-100">
      <h1>
        {{ "partner.campaign.contentCalendar.setupDialog.allSet" | translate }}
      </h1>
      <p>
        {{
          "partner.campaign.contentCalendar.setupDialog.allSetSubtitle"
            | translate
        }}
      </p>
      <ng-container *ngIf="postsWithError?.length">
        <hr />
        <h3>
          {{
            "partner.campaign.contentCalendar.setupDialog.postErrors"
              | translate
          }}
        </h3>
        <ul class="post-with-errors-list">
          <li *ngFor="let errorRow of postsWithError">
            <div class="post-image">
              <img
                *ngIf="errorRow.postLog.image"
                src="{{ errorRow.postLog.image }}"
              />
              <em>{{
                errorRow.postLog.scheduledPublishDate
                  | localizeDate: "mediumDate"
              }}</em>
            </div>
            <div class="post-text">
              <span *ngIf="errorRow.postLog.isFacebookPost">
                {{ getGoogleAdPostLog(errorRow.postLog).descriptionOne }}
              </span>
              <span *ngIf="errorRow.postLog.isGoogleAd">
                {{ getFacebookPostLog(errorRow.postLog).text }}
              </span>
              <div class="error-message">
                <b>{{
                  "partner.campaign.contentCalendar.setupDialog.errorsWhy"
                    | translate
                }}</b
                >&nbsp;
                <ng-container
                  *ngIf="errorRow.errorInfo === CampaignError.PostInPast"
                >
                  {{
                    "partner.campaign.contentCalendar.setupDialog.errorPostDatePast"
                      | translate
                  }}
                </ng-container>
                <ng-container
                  *ngIf="errorRow.errorInfo === CampaignError.AdOutOfBudget"
                >
                  {{
                    "partner.campaign.contentCalendar.setupDialog.errorBudgetSpent"
                      | translate
                  }}
                </ng-container>
                <ng-container
                  *ngIf="errorRow.errorInfo === CampaignError.MaxAdsReached"
                >
                  {{
                    "partner.campaign.contentCalendar.setupDialog.errorMaxAds"
                      | translate
                  }}
                </ng-container>
                <ng-container
                  *ngIf="errorRow.errorInfo === CampaignError.AdsNotAuth"
                >
                  {{
                    "partner.campaign.contentCalendar.setupDialog.errorNotAuth"
                      | translate
                  }}
                </ng-container>
                <ng-container
                  *ngIf="
                    errorRow.errorInfo === CampaignError.PostOutOfCampaignDates
                  "
                >
                  {{
                    "partner.campaign.contentCalendar.setupDialog.errorCampaignTimeFrame"
                      | translate
                  }}
                </ng-container>
                <ng-container *ngIf="isUnknownError(errorRow.errorInfo)">
                  {{
                    "partner.campaign.contentCalendar.setupDialog.errorUnknown"
                      | translate
                  }}
                </ng-container>
              </div>
            </div>
          </li>
        </ul>
      </ng-container>
    </div>
  </div>
</mat-dialog-content>
