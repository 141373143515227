<div class="budget-plan">
  <p>
    <strong>{{ "budgetPlan.title" | translate }}:</strong>
  </p>

  @if (hasBudget()) {
    <ul class="budget">
      <li>
        <strong
          >{{ budgetPerAd() | number: FORMAT }}{{ currencySymbol() }}</strong
        >
        {{ "budgetPlan.perAd" | translate }}
      </li>
      <li>
        <strong
          >{{ budgetPerDay() | number: FORMAT }}{{ currencySymbol() }}</strong
        >
        {{ "budgetPlan.perDay" | translate }}
      </li>
    </ul>
  } @else {
    <p>{{ "budgetPlan.instructions" | translate }}</p>
  }

  @if (showBudgetPerDayWarning()) {
    <p class="warning">
      <strong>{{ "budgetPlan.warning" | translate }}:</strong>
      {{
        "budgetPlan.warningText"
          | translate: { threshold: BUDGET_PER_DAY_WARNING_THRESHOLD }
      }}
    </p>
  }
</div>
