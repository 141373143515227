<h2 *ngIf="address.id" mat-dialog-title>
  {{
    (address.id
      ? "partner.edit.editAddressTitle"
      : "partner.edit.newAddressTitle"
    ) | translate
  }}
</h2>
<mat-dialog-content class="for-address-edition-form">
  <app-address-edition-form
    [canChangeDefault]="canChangeDefault"
    (cancelEvent)="cancel()"
    (updatedEvent)="addressCreatedOrUpdated($event)"
    (createdEvent)="addressCreatedOrUpdated($event)"
    [sourceAddress]="address"
    [addressType]="address.type"
  />
</mat-dialog-content>
