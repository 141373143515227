import { EventEmitter, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, lastValueFrom, of } from "rxjs";
import { map } from "rxjs/operators";

import { PostPlatform } from "../../enums/campaignPost.enums";
import { CampaignNotFoundError } from "../../errors/campaign-not-found-error";
import {
  PartnerCampaign,
  PartnerCampaignStatus,
} from "../../models/partnerCampaign";
import { PartnerCampaignDetails } from "../../models/partnerCampaignDetail";
import { PartnerFacebookPostLog } from "../../models/partnerFacebookPostLog";
import { PartnerGoogleAdPostLog } from "../../models/partnerGoogleAdPostLog";
import { PostLog } from "../../models/postLog";
import { HttpClient } from "../http-client";
import { PartnerCampaignList } from "../responses/partner-campaign-list";

import { PartnerService } from "./partner.service";

export type PartnerCampaignStatusFilter = Partial<
  Record<PartnerCampaignStatus, boolean>
>;

export class AcceptedPartnerCampaignResponse {
  constructor(public readonly errors: AcceptedPartnerCampaignErrorResponse[]) {}
}

export class AcceptedPartnerCampaignErrorResponse {
  constructor(
    public readonly postLog: PostLog | undefined,
    public readonly errorInfo: AcceptedPartnerCampaignError,
  ) {}
}

export enum AcceptedPartnerCampaignError {
  MaxAdsReached = "max_ads_reached",
  AdsNotAuth = "ads_not_authorized",
  AdOutOfBudget = "ad_out_of_budget",
  PostOutOfCampaignDates = "post_out_of_campaign_dates",
  PostInPast = "post_in_past",
}

@Injectable()
export class PartnerCampaignService {
  public readonly currentPartnerCampaign$ = new BehaviorSubject<
    PartnerCampaign | undefined
  >(undefined);
  // todo: deprecated properties to be removed after the revamp
  private loadedCampaign?: PartnerCampaign;
  private loadedCampaignId?: number;
  public partnerCampaignUpdated: EventEmitter<PartnerCampaign>;
  public partnerCampaignDetailsUpdated: EventEmitter<
    [PartnerCampaign, PartnerCampaignDetails]
  >;
  public selectedMenuCategoryIndex?: number;
  public partnerCampaignBudgetEditedEvent: EventEmitter<void>;

  constructor(
    private http: HttpClient,
    private partnerService: PartnerService,
  ) {
    this.partnerCampaignUpdated = new EventEmitter();
    this.partnerCampaignDetailsUpdated = new EventEmitter();
    this.partnerCampaignBudgetEditedEvent = new EventEmitter();
  }

  private getCurrentPartnerId(): number {
    return this.partnerService.currentPartnerId;
  }

  public get currentCampaign(): PartnerCampaign | undefined {
    return this.loadedCampaign;
  }

  public get currentPartnerCampaign(): PartnerCampaign {
    if (!this.currentPartnerCampaign$.value) {
      throw new CampaignNotFoundError();
    }
    return this.currentPartnerCampaign$.value;
  }

  public get currentPartnerCampaignId(): number {
    return this.currentPartnerCampaign.id;
  }

  public setCurrentCampaignId(campaignId: number) {
    this.loadedCampaignId = campaignId;
  }

  public getCurrentPartnerCampaign(
    forceCacheReload = false,
  ): Observable<PartnerCampaign> {
    if (
      !forceCacheReload &&
      this.loadedCampaign &&
      this.loadedCampaign.id === this.loadedCampaignId
    ) {
      return of(this.loadedCampaign);
    }

    return this.loadPartnerCampaign(this.loadedCampaignId, forceCacheReload);
  }

  public loadPartnerCampaign(
    campaignId: number | undefined,
    forceCacheReload = false,
  ): Observable<PartnerCampaign> {
    this.currentPartnerCampaign$.next(undefined);

    const partnerId = this.getCurrentPartnerId();
    if (
      !forceCacheReload &&
      this.loadedCampaign &&
      this.loadedCampaign.id === campaignId
    ) {
      return of(this.loadedCampaign);
    }

    return this.http.get(`partner/${partnerId}/campaign/${campaignId}`).pipe(
      map(({ body }) => {
        const partnerCampaign = new PartnerCampaign(body);
        this.currentPartnerCampaign$.next(partnerCampaign);
        this.loadedCampaign = partnerCampaign;
        this.partnerCampaignUpdated.emit(partnerCampaign);
        return partnerCampaign;
      }),
    );
  }

  public getPartnerCampaigns(
    offset: number,
    limit: number,
    sortMode: string,
    status: PartnerCampaignStatusFilter | null,
    brandId: number | null,
  ): Observable<PartnerCampaignList> {
    const partnerId = this.getCurrentPartnerId();

    if (!partnerId) {
      return of(new PartnerCampaignList([], 0));
    }

    let url = `partner/${partnerId}/campaign?offset=${offset}&limit=${limit}&sort_mode=${sortMode}`;

    if (status && status.active) {
      url = url + "&status_active=1";
    }

    if (status && status.past) {
      url = url + "&status_past=1";
    }

    if (status && status.upcoming) {
      url = url + "&status_upcoming=1";
    }

    if (brandId) {
      url = url + "&brand_id=" + brandId;
    }

    return this.http.get(url).pipe(
      map(
        ({ body }) =>
          new PartnerCampaignList(
            body.items.map((campaign: any) => new PartnerCampaign(campaign)),
            body._meta?.totalCount ?? 0,
          ),
      ),
    );
  }

  public cancelCampaign(
    partnerId: number,
    campaignId: number,
  ): Observable<void> {
    return this.http
      .put(`partner/${partnerId}/campaign/${campaignId}/cancel`, {})
      .pipe(map(() => {}));
  }

  public async getPartnerCampaignByInviteToken(
    inviteToken: string,
  ): Promise<PartnerCampaign> {
    return lastValueFrom(
      this.http
        .get(`public/partner-campaign/${inviteToken}`)
        .pipe(map(({ body }) => new PartnerCampaign(body))),
    );
  }

  public partnerAcceptCampaignFromToken(inviteToken: string): Observable<void> {
    return this.http
      .post(`public/partner-accept-campaign/${inviteToken}`, {})
      .pipe(map(() => {}));
  }

  public partnerDeclineCampaignFromToken(
    inviteToken: string,
    declineReason: string,
  ): Observable<void> {
    return this.http
      .post(`public/partner-decline-campaign/${inviteToken}`, {
        notes: declineReason,
      })
      .pipe(map(() => {}));
  }

  public partnerAcceptCampaign(campaignId: number): Observable<void> {
    const partnerId = this.getCurrentPartnerId();

    return this.http
      .post(`partner/${partnerId}/campaign/${campaignId}/accept`, {})
      .pipe(map(() => {}));
  }

  public acceptCalendar(
    campaignId: number,
    campaignDetailId: number,
    campaignCalendarId: number,
    adsData: any,
  ): Observable<AcceptedPartnerCampaignResponse> {
    const partnerId = this.getCurrentPartnerId();

    return this.http
      .post(
        `partner/${partnerId}/campaign/${campaignId}/detail/${campaignDetailId}/approve_calendar/${campaignCalendarId}`,
        adsData,
      )
      .pipe(
        map(
          ({ body }) =>
            new AcceptedPartnerCampaignResponse(
              (body.errors ?? []).map((errorData: any) => {
                let postLog: PostLog | undefined;

                if (errorData.post.platform === PostPlatform.Facebook) {
                  postLog = new PartnerFacebookPostLog(errorData.post);
                } else if (errorData.post.platform === PostPlatform.Google) {
                  postLog = new PartnerGoogleAdPostLog(errorData.post);
                }

                const error = new AcceptedPartnerCampaignErrorResponse(
                  postLog,
                  errorData.errorInfo,
                );

                return error;
              }),
            ),
        ),
      );
  }

  public editBudget(
    campaignId: number,
    budget: number | null,
    adDuration: number | null,
    numberOfAds: number | null,
  ): Observable<void> {
    const partnerId = this.getCurrentPartnerId();

    return this.http
      .put(`partner/${partnerId}/campaign/${campaignId}/budget`, {
        budget: budget,
        adDuration: adDuration,
        numberOfAds: numberOfAds,
      })
      .pipe(map(() => {}));
  }
}
