<mat-dialog-content [ngClass]="{ loading: loading }">
  <a class="close-icon" [mat-dialog-close]="">
    <mat-icon>close</mat-icon>
  </a>
  <div class="row all-right-box">
    <div class="col width-100">
      <h3>
        {{
          "partner.campaign.contentCalendar.setupDialog.checkAllOk" | translate
        }}
      </h3>
      <ul class="step-check">
        <li *ngIf="campaign.useGoogleAds">
          <p>
            <b
              ><span>{{
                "partner.campaign.contentCalendar.setupDialog.connectionGoogle"
                  | translate
              }}</span></b
            >
          </p>
          <ng-container *ngIf="partner">
            <img
              *ngIf="partner.areGoogleAdsAuthorized"
              src="../../../../../assets/images/icons/ic_check_pass.svg"
            />
            <ng-container *ngIf="!partner.areGoogleAdsAuthorized">
              <img src="../../../../../assets/images/icons/ic_check_fail.svg" />
              <p>
                {{
                  "partner.campaign.contentCalendar.setupDialog.googleDescription"
                    | translate
                }}
              </p>
              <mat-checkbox
                (change)="saveGoogleAdsChoice()"
                [ngModel]="partner.areGoogleAdsAuthorized"
                name="areGoogleAdsAuthorized"
              >
                {{ "partner.edit.googleIntegrationSubtitle" | translate }}
              </mat-checkbox>
            </ng-container>
          </ng-container>
        </li>
        <li>
          <p>
            <b
              ><span>{{
                "partner.campaign.contentCalendar.setupDialog.connectionFacebook"
                  | translate
              }}</span></b
            >
          </p>
          <img
            *ngIf="partner?.hasConnectedToFacebook"
            src="../../../../../assets/images/icons/ic_check_pass.svg"
          />
          <ng-container *ngIf="partner && !partner?.hasConnectedToFacebook">
            <img src="../../../../../assets/images/icons/ic_check_fail.svg" />
            <p>
              {{
                "partner.campaign.contentCalendar.setupDialog.facebookDescription"
                  | translate
              }}
            </p>
            <button class="button-blue" (click)="connectToFacebook()">
              <span class="icon-facebook"></span>
              {{
                "partner.campaign.contentCalendar.setupDialog.connectWithFacebook"
                  | translate
              }}
            </button>
          </ng-container>
        </li>
        <li *ngIf="campaign.promoAdsEnabled">
          <p>
            <b>{{
              "partner.campaign.contentCalendar.setupDialog.authSocialPals"
                | translate
            }}</b>
          </p>
          <img
            *ngIf="hasAuthorizedPromoAds()"
            src="../../../../../assets/images/icons/ic_check_pass.svg"
          />
          <ng-container *ngIf="!hasAuthorizedPromoAds()">
            <img src="../../../../../assets/images/icons/ic_check_fail.svg" />
            <p>
              {{
                "partner.campaign.contentCalendar.setupDialog.agencyPermission"
                  | translate
              }}
              <br />
              <b>
                <a (click)="openUnclaimedPageDialog()">
                  {{
                    "partner.campaign.contentCalendar.setupDialog.learnHow"
                      | translate
                  }}
                </a>
              </b>
            </p>
          </ng-container>
        </li>
      </ul>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn btn--primary" [mat-dialog-close]="true">
    {{ "shared.ok" | translate }}
  </button>
</mat-dialog-actions>
