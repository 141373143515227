import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Observer } from "rxjs";
import { getErrorTranslationKey } from "../../shared/functions/get-error-translation-key";
import { Partner } from "../../shared/models/partner";
import { PartnerCampaign } from "../../shared/models/partnerCampaign";
import { PartnerCampaignFacebookPost } from "../../shared/models/partnerCampaignFacebookPost";
import { PartnerCampaignLinkedInPost } from "../../shared/models/partnerCampaignLinkedInPost";
import { PostLog } from "../../shared/models/postLog";
import { PartnerCampaignPostLogService } from "../shared/services/partner-campaign-post-log.service";
import { NotificationService } from "../../shared/services/notification.service";
import { CreatePostLogFromPostData } from "../../shared/services/parameters/create-post-log-from-post-data";
import { PublishPostData } from "../partner-campaign-publish-post-time-picker/publish-post-data";
import { PartnerDialogService } from "../partner-dialog.service";

export interface PartnerCampaignPublishPostDialogData {
  readonly campaign: PartnerCampaign;
  readonly post: PartnerCampaignFacebookPost | PartnerCampaignLinkedInPost;
  readonly partner: Partner;
}

export type PartnerCampaignPublishPostDialogResult = undefined;

@Component({
  selector: "app-partner-campaign-publish-post-dialog",
  templateUrl: "./partner-campaign-publish-post-dialog.component.html",
  styleUrls: ["./partner-campaign-publish-post-dialog.component.scss"],
})
export class PartnerCampaignPublishPostDialogComponent {
  protected post: PartnerCampaignFacebookPost | PartnerCampaignLinkedInPost;
  protected campaign: PartnerCampaign;
  protected partner: Partner;
  protected publishing = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    protected readonly data: PartnerCampaignPublishPostDialogData,
    private readonly dialogRef: MatDialogRef<
      PartnerCampaignPublishPostDialogComponent,
      PartnerCampaignPublishPostDialogResult
    >,
    private readonly partnerDialogService: PartnerDialogService,
    private readonly notificationService: NotificationService,
    private readonly partnerCampaignPostLogService: PartnerCampaignPostLogService,
  ) {
    this.campaign = data.campaign;
    this.partner = data.partner;
    this.post = data.post;

    if (
      this.post instanceof PartnerCampaignFacebookPost &&
      !this.post.canBePostedToInstagram
    ) {
      this.post.postToInstagram = false;
    }
  }

  public schedulePost(publishData: PublishPostData): void {
    if (publishData.post.isLinkedInPost) {
      this.scheduleLinkedInPost(publishData);
    } else {
      this.scheduleFacebookPost(publishData);
    }
  }

  private async scheduleLinkedInPost(
    publishData: PublishPostData,
  ): Promise<void> {
    if (!this.partner.isConnectedToLinkedIn) {
      const connected =
        await this.partnerDialogService.showLinkedInNotConnected({
          partnerId: this.partner.id,
        });

      if (!connected) {
        return;
      }
    }

    this.publishing = true;

    const createPostData = new CreatePostLogFromPostData(
      this.post as PartnerCampaignLinkedInPost,
      publishData.scheduledPublishDateAndTime,
    );

    this.partnerCampaignPostLogService
      .createLinkedInPostLog(this.campaign.id, createPostData)
      .subscribe(this.handleCreatePostResponse);
  }

  private scheduleFacebookPost(publishData: PublishPostData): void {
    if (!this.partner.isConnectedToInstagram && publishData.postToInstagram) {
      this.partnerDialogService.showFacebookNotConnected({
        partner: this.partner,
      });
      return;
    }

    this.publishing = true;

    const createPostData = new CreatePostLogFromPostData(
      this.post as PartnerCampaignFacebookPost,
      publishData.scheduledPublishDateAndTime,
      publishData.postToFacebook,
      publishData.postToInstagram,
    );

    this.partnerCampaignPostLogService
      .createFacebookPostLog(this.campaign.id, createPostData)
      .subscribe(this.handleCreatePostResponse);
  }

  private handleCreatePostResponse: Observer<PostLog> = {
    next: (postLog) => {
      this.handleCreatePostSuccess(postLog);
    },
    error: ({ error }) => {
      const errorDetails =
        getErrorTranslationKey(error?.key) ??
        "partner.campaign.publishPostError";
      this.notificationService.errorDialog(
        errorDetails,
        "partner.campaign.publishPostErrorTitle",
      );
    },
    complete: () => {
      this.publishing = false;
    },
  };

  private handleCreatePostSuccess(postLog: PostLog): void {
    this.campaign.posts.push(postLog);
    this.dialogRef.close();
    this.partnerCampaignPostLogService.postLogCreatedOrUpdated.emit(postLog);
    this.partnerDialogService.showContentPublished({
      partnerId: this.partner.id,
      postLog: postLog,
      redirectToScheduledPosts: true,
    });
  }
}
