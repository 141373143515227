<span class="close-icon" (click)="closeDialog()">
  <mat-icon>close</mat-icon>
</span>
<h2 mat-dialog-title *ngIf="post.isFacebookPost">
  {{ "shared.scheduleFacebookPost" | translate }}
</h2>
<h2 mat-dialog-title *ngIf="post.isGoogleAd">
  {{ "shared.scheduleGoogleAd" | translate }}
</h2>
<mat-dialog-content>
  <p *ngIf="post.isFacebookPost">
    {{ "partner.campaign.schedulePost.facebookTitle" | translate }}
  </p>
  <p *ngIf="post.isGoogleAd">
    {{ "partner.campaign.schedulePost.googleTitle" | translate }}
  </p>
  <p class="warning">
    <em>{{ "partner.campaign.schedulePost.note" | translate }}</em>
  </p>
  <app-partner-campaign-posts-schedule-time-picker
    [minDate]="MIN_DATE"
    [maxDate]="MAX_DATE"
    [post]="post"
  />
</mat-dialog-content>
<mat-dialog-actions>
  <button
    class="btn btn--transparent delete-post-dismiss"
    (click)="closeDialog()"
  >
    {{ "shared.dismiss" | translate }}
  </button>
  <button
    *ngIf="isNewPost"
    class="btn btn--primary"
    [mat-dialog-close]="true"
    [disabled]="!post.scheduledPublishDate"
  >
    <span *ngIf="post.isFacebookPost">{{
      "shared.scheduleFacebookPost" | translate
    }}</span>
    <span *ngIf="post.isGoogleAd">{{
      "shared.scheduleGoogleAd" | translate
    }}</span>
  </button>
  <button
    *ngIf="!isNewPost"
    class="btn btn--primary"
    (click)="updatePost()"
    [disabled]="!post.scheduledPublishDate || saving"
  >
    <span *ngIf="saving">{{ "shared.saving" | translate }}</span>
    <span *ngIf="!saving">{{ "shared.save" | translate }}</span>
  </button>
</mat-dialog-actions>
