interface BaseDeferred<T, E> {
  isFulfilled: boolean;
  isResolved: boolean;
  isRejected: boolean;
  promise: Promise<T>;
  resolve: (value: T) => void;
  reject: (reason: E) => void;
}

interface DeferredPending<T, E> extends BaseDeferred<T, E> {
  isFullfilled: false;
  isRejected: false;
  isResolved: false;
}

interface DeferredResolved<T, E> extends BaseDeferred<T, E> {
  isFulfilled: true;
  isRejected: false;
  isResolved: true;
  value: T;
}

interface DeferredRejected<T, E> extends BaseDeferred<T, E> {
  isFulfilled: true;
  isRejected: true;
  isResolved: false;
  err: E;
}

export type Deferred<T, E = unknown> =
  | DeferredPending<T, E>
  | DeferredResolved<T, E>
  | DeferredRejected<T, E>;

export const createDeferred = <T, E = unknown>(): Deferred<T, E> => {
  const deferred: Partial<Deferred<T, E>> = {
    isFulfilled: false,
    isRejected: false,
    isResolved: false,
  };

  deferred.promise = new Promise<T>((resolve, reject) => {
    deferred.resolve = (value: T) => {
      // SAFETY `as unknown as T`: we're going to mutate the deferred in place
      // This allows us to type check that we're setting the correct resolved props
      const resolved = deferred as unknown as DeferredResolved<T, E>;

      resolved.isFulfilled = true;
      resolved.isRejected = false;
      resolved.isResolved = true;
      resolved.value = value;

      resolve(value);
    };

    deferred.reject = (reason: E) => {
      // SAFETY `as unknown as T`: we're going to mutate the deferred in place
      // This allows us to type check that we're setting the correct rejected props
      const rejected = deferred as unknown as DeferredRejected<T, E>;

      rejected.isFulfilled = true;
      rejected.isRejected = true;
      rejected.isResolved = false;
      rejected.err = reason;

      reject(reason);
    };
  });

  // SAFETY `as T`: at this point `deferred` is not partial anymore
  return deferred as DeferredPending<T, E>;
};
