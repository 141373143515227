<h2 mat-dialog-title>{{ "partner.leaveDialog.title" | translate }}</h2>
<mat-dialog-content>
  <p>
    {{ "partner.leaveDialog.description" | translate }}
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn btn--transparent" [mat-dialog-close]="">
    {{ "shared.cancel" | translate }}
  </button>
  <button class="btn btn--primary" [mat-dialog-close]="true">
    {{ "partner.leaveDialog.leave" | translate }}
  </button>
</mat-dialog-actions>
