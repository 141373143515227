import { Component, Input } from "@angular/core";

import { ImageService } from "../../../shared/services/image.service";
import { CampaignPostContentType } from "../../../shared/enums/campaignPost.enums";
import { PartnerContentDownloadTrackingService } from "../shared/services/partner-content-download-tracking.service";
import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { PartnerCampaignDetails } from "../../../shared/models/partnerCampaignDetail";
import { PartnerCampaignFacebookPost } from "../../../shared/models/partnerCampaignFacebookPost";
import { PartnerFacebookPostLog } from "../../../shared/models/partnerFacebookPostLog";
import { DownloadFileInteractor } from "../../../features/shared/download-file.interactor";
import { CreateZipFileInteractor } from "../../../features/shared/create-zip-file.interactor";

@Component({
  selector: "app-partner-campaign-post-edit",
  templateUrl: "./partner-campaign-post-edit.component.html",
  styleUrls: ["./partner-campaign-post-edit.component.scss"],
})
export class PartnerCampaignPostEditComponent {
  @Input() campaign!: PartnerCampaign;
  @Input() campaignDetails!: PartnerCampaignDetails;
  @Input() post!: PartnerFacebookPostLog | PartnerCampaignFacebookPost;
  @Input() showDownloadIcon = false;

  public readonly MEDIA_TYPE = CampaignPostContentType;

  constructor(
    private readonly downloadTrackingService: PartnerContentDownloadTrackingService,
    private readonly imageService: ImageService,
    private readonly createZipFile: CreateZipFileInteractor,
    private readonly downloadFile: DownloadFileInteractor,
  ) {}

  public getImageUrl(url: string, imagePublicId: string): string {
    return this.imageService.resizeImage(url, 1000, imagePublicId);
  }

  private async getDownloadUrl(): Promise<string> {
    switch (this.post.mediaType) {
      case CampaignPostContentType.Image:
        return this.imageService.directDownloadURL(
          this.post.image,
          this.post.imagePublicId,
        );

      case CampaignPostContentType.Carousel:
        return URL.createObjectURL(
          await this.createZipFile.execute(
            this.post.carouselSlides.map((slide) => slide.url),
          ),
        );

      case CampaignPostContentType.Video:
        return this.post.videoUrl as string;

      default:
        return "";
    }
  }

  protected async download(): Promise<void> {
    this.trackDownload();
    this.downloadFile.execute(await this.getDownloadUrl());
  }

  private trackDownload(): void {
    this.downloadTrackingService.trackCampaignContentDownload(
      this.campaign.id,
      {
        postId:
          this.post instanceof PartnerCampaignFacebookPost
            ? this.post.id
            : undefined,
        postLogId:
          this.post instanceof PartnerFacebookPostLog
            ? this.post.id
            : undefined,
      },
    );
  }
}
