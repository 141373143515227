<span class="close-icon" [mat-dialog-close]="">
  <mat-icon>close</mat-icon>
</span>
<h2 *ngIf="!isAd" mat-dialog-title>
  {{ "partner.campaign.cancelPost.title" | translate }}
</h2>
<h2 *ngIf="isAd" mat-dialog-title>
  {{ "partner.campaign.cancelPost.cancelAdTitle" | translate }}
</h2>
<mat-dialog-content *ngIf="!isAd && post.hasAd">
  <p>{{ "partner.campaign.cancelPost.description" | translate }}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn btn--transparent" [mat-dialog-close]="">
    {{ "partner.campaign.cancelPost.no" | translate }}
  </button>
  <button
    class="btn btn--primary"
    (click)="onActionDeletePost()"
    [disabled]="deleting"
  >
    <span *ngIf="deleting">{{ "shared.canceling" | translate }}</span>
    <span *ngIf="!deleting && !isAd">{{
      "partner.campaign.cancelPost.yes" | translate
    }}</span>
    <span *ngIf="!deleting && isAd">{{
      "partner.campaign.cancelPost.yesCancelAd" | translate
    }}</span>
  </button>
</mat-dialog-actions>
