import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";

import { Campaign } from "../../../shared/models/campaign";
import { PartnerFacebookPostLog } from "../../../shared/models/partnerFacebookPostLog";
import { PostLog } from "../../../shared/models/postLog";
import { PartnerCampaignPostLogService } from "../../shared/services/partner-campaign-post-log.service";
import { NotificationService } from "../../../shared/services/notification.service";
import { UpdateFacebookPostLogData } from "../../../shared/services/parameters/update-facebook-post-log-data";
import { RequestErrorService } from "../../../shared/services/request-error.service";

export interface SchedulePostDialogData {
  readonly campaign: Campaign;
  readonly post: PostLog;
}

export type SchedulePostDialogResult = undefined;

@Component({
  selector: "app-schedule-post-dialog",
  templateUrl: "./schedule-post-dialog.component.html",
  styleUrl: "./schedule-post-dialog.component.scss",
})
export class SchedulePostDialogComponent {
  protected readonly MIN_DATE: Date;
  protected readonly MAX_DATE: Date;
  protected isNewPost = false;
  protected post: PostLog;
  protected saving = false;

  private readonly initialScheduledPublishDate!: Date;

  constructor(
    private readonly notificationService: NotificationService,
    private readonly partnerCampaignPostLogService: PartnerCampaignPostLogService,
    private readonly requestErrorService: RequestErrorService,
    private readonly dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data: SchedulePostDialogData,
  ) {
    this.post = data.post;
    if (this.post.scheduledPublishDate) {
      this.initialScheduledPublishDate = this.post.scheduledPublishDate;
    } else {
      this.isNewPost = true;
    }

    this.MIN_DATE =
      data.campaign.startDate < new Date()
        ? new Date()
        : data.campaign.startDate;
    this.MAX_DATE = data.campaign.endDate;
  }

  protected updatePost(): void {
    this.saving = true;

    this.partnerCampaignPostLogService
      .updateCampaignPost(
        this.post.id,
        new UpdateFacebookPostLogData(
          this.post as PartnerFacebookPostLog,
          undefined,
        ),
      )
      .subscribe({
        next: () => {
          this.saving = false;
          this.dialog.closeAll();
          this.notificationService.success("shared.changesSavedSuccessfully");
        },
        error: (err) => {
          this.saving = false;
          if (err.error && err.error.key === "FB_PARTNER_AUTH_ERROR") {
            this.closeDialog();
            setTimeout(() => {
              this.requestErrorService.partnerNotConnectedError();
            }, 1000);
          } else {
            this.notificationService.error(
              "partner.campaign.schedulePost.note",
              "shared.changesCouldNotBeSaved",
            );
          }
        },
      });
  }

  protected closeDialog(): void {
    if (this.isNewPost) {
      // TODO: Remove `as any`
      this.post.scheduledPublishDate = null as any;
    } else {
      this.post.scheduledPublishDate = this.initialScheduledPublishDate;
    }

    this.dialog.closeAll();
  }
}
