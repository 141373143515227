<div class="form-container">
  <mat-form-field [ngClass]="{ compressed: compressedLayout }">
    <mat-label>{{ "shared.chooseADate" | translate }}</mat-label>
    <input
      [(ngModel)]="scheduledPublishDate"
      [min]="minDate | dayjsTransform"
      [max]="maxDate | dayjsTransform"
      matInput
      [matDatepicker]="picker"
      (dateChange)="updatePostDate()"
      (focus)="picker.open()"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <mat-form-field [ngClass]="{ compressed: compressedLayout }">
    <mat-label>{{ "shared.hours" | translate }}</mat-label>
    <mat-select
      [(ngModel)]="scheduledPublishDateHour"
      (selectionChange)="updatePostDate()"
    >
      <mat-option *ngFor="let hour of hours" [value]="hour">
        {{ hour }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field [ngClass]="{ compressed: compressedLayout }">
    <mat-label>{{ "shared.minutes" | translate }}</mat-label>
    <mat-select
      [(ngModel)]="scheduledPublishDateMinute"
      (selectionChange)="updatePostDate()"
    >
      <mat-option *ngFor="let minute of minutes" [value]="minute">
        {{ minute }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
