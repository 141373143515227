<div class="dialog-wrapper">
  <h2 mat-dialog-title>
    {{ "partner.campaign.shortUrlDialog.title" | translate }}
  </h2>
  <mat-dialog-content>
    <p class="title">
      {{ "partner.campaign.shortUrlDialog.partnerCampaignLink" | translate }}
    </p>
    <div class="row">
      <div class="col width-25">
        <mat-form-field style="width: 120px">
          <mat-select
            [(ngModel)]="campaignPartner.shortUrlPrefix"
            name="websiteUrlPrefix"
            required
          >
            <mat-option
              *ngFor="let urlPrefix of UrlPrefixes"
              [value]="urlPrefix"
            >
              {{ urlPrefix }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col width-75">
        <mat-form-field style="width: 90%">
          <mat-label>{{
            "partner.campaign.shortUrlDialog.partnerCampaignLinkPlaceholder"
              | translate
          }}</mat-label>
          <input
            matInput
            #shortUrl="ngModel"
            (change)="formatShortUrl()"
            [(ngModel)]="campaignPartner.shortUrl"
            name="shortUrl"
            pattern="{{ URL_VALIDATION_PATTERN }}"
          />
          <mat-error
            *ngIf="
              campaignPartner.shortUrl &&
              shortUrl.errors &&
              shortUrl.errors.pattern
            "
          >
            {{ "shared.enterValidUrl" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="button-grey" [mat-dialog-close]="" [disabled]="saving">
      {{ "shared.cancel" | translate }}
    </button>
    <button
      class="button-orange button-save"
      (click)="updateCampaignPartner()"
      [disabled]="saving || !campaignPartner.shortUrl || shortUrl.errors"
    >
      <span *ngIf="saving">{{ "shared.saving" | translate }}</span>
      <span *ngIf="!saving">{{ "shared.save" | translate }}</span>
    </button>
  </mat-dialog-actions>
</div>
