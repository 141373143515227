import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import dayjs from "dayjs";

import {
  CampaignPostContentType,
  LinkedInPostContentType,
} from "../../../../shared/enums/campaignPost.enums";
import { Partner } from "../../../../shared/models/partner";
import { PartnerCampaign } from "../../../../shared/models/partnerCampaign";
import { PartnerCampaignDetails } from "../../../../shared/models/partnerCampaignDetail";
import { PartnerFacebookPostLog } from "../../../../shared/models/partnerFacebookPostLog";
import { PartnerGoogleAdPostLog } from "../../../../shared/models/partnerGoogleAdPostLog";
import { PartnerLinkedInPostLog } from "../../../../shared/models/partnerLinkedInPostLog";
import { PostLog } from "../../../../shared/models/postLog";

import { PostsCarouselComponent } from "./post-carousel/posts-carousel.component";
import { PostRescheduleDialogComponent } from "./post-reschedule-dialog/post-reschedule-dialog.component";

export interface PostAcceptanceDialogData {
  readonly partner: Partner;
  readonly campaign: PartnerCampaign;
  readonly campaignDetails: PartnerCampaignDetails;
}

interface PostSummary {
  post: PostLog;
  publishing: boolean;
  hasPromoAd: boolean;
  rescheduled: boolean;
  date: string | Date;
}

export type PostAcceptanceDialogResult = PostLog[];

@Component({
  selector: "app-post-acceptance-dialog",
  templateUrl: "./post-acceptance-dialog.component.html",
  styleUrl: "./post-acceptance-dialog.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class PostAcceptanceDialogComponent implements OnInit {
  protected readonly PostType = CampaignPostContentType;
  protected acceptedPosts: PostLog[] = [];
  protected campaign!: PartnerCampaign;
  protected campaignDetails!: PartnerCampaignDetails;
  protected hasMoreThanOneCalendar = false;
  protected numPosts!: number;
  protected postsToReschedule: PostLog[] = [];
  protected postSummaryList: PostSummary[] = [];
  protected progress = 0;
  protected rescheduleDialogRef?: MatDialogRef<PostRescheduleDialogComponent>;
  protected step: 1 | 2 = 1;

  @ViewChild("carousel")
  private carousel!: PostsCarouselComponent;
  private facebookAdsAuthorized?: boolean;
  private facebookConnected?: boolean;
  private googleAdsAuthorized?: boolean;
  private partner!: Partner;

  constructor(
    private readonly dialogRef: MatDialogRef<
      PostAcceptanceDialogComponent,
      PostAcceptanceDialogResult
    >,
    private readonly dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data: PostAcceptanceDialogData,
  ) {
    this.partner = data.partner;
    this.campaign = data.campaign;
    this.campaignDetails = data.campaignDetails;
  }

  public ngOnInit(): void {
    if (
      this.campaignDetails &&
      this.campaignDetails.calendars &&
      this.campaignDetails.calendars.length > 1
    ) {
      this.hasMoreThanOneCalendar = true;
    }

    // Deep copy without modifying the original posts array
    if (this.campaign.currentCalendar && this.partner) {
      this.googleAdsAuthorized = this.partner.areGoogleAdsAuthorized;
      this.facebookConnected = this.partner.hasConnectedToFacebook;
      this.facebookAdsAuthorized = this.partner.isAuthorizedFBAds;

      // When we have multiple calendars we may have Google ads here in partner pending status
      this.campaign.currentCalendar.calendarPosts.forEach((post: PostLog) => {
        if (post.isFacebookPost && !this.facebookConnected) {
          this.postSummaryList.push({
            post: post,
            publishing: false,
            hasPromoAd: this.facebookAdsAuthorized ? post.hasAd : false,
            rescheduled: false,
            date: post.scheduledPublishDate,
          });
        } else if (post.isGoogleAd && !this.googleAdsAuthorized) {
          this.postSummaryList.push({
            post: post,
            publishing: false,
            hasPromoAd: true,
            rescheduled: false,
            date: post.scheduledPublishDate,
          });
        } else if (this.needsToBeRescheduled(post)) {
          this.postsToReschedule.push(post);
        } else {
          let postHasPromoAd = false;
          if (post.isGoogleAd) {
            postHasPromoAd = true;
          } else {
            postHasPromoAd = this.facebookAdsAuthorized ? post.hasAd : false;
          }
          this.acceptedPosts.push(post);
          this.postSummaryList.push({
            post: post,
            publishing: true,
            hasPromoAd: postHasPromoAd,
            rescheduled: false,
            date: post.scheduledPublishDate,
          });
        }
      });

      // We also show accepted posts
      this.campaign.posts.map((post: PostLog) => {
        // We mark google ads as publishing only if google ads enabled
        if (post.isGoogleAd) {
          this.postSummaryList.push({
            post: post,
            publishing: !!this.googleAdsAuthorized,
            hasPromoAd: true,
            rescheduled: false,
            date: post.scheduledPublishDate,
          });
        } else if (post.isFacebookPost) {
          this.postSummaryList.push({
            post: post,
            publishing: !!this.facebookConnected,
            hasPromoAd: this.facebookAdsAuthorized ? post.hasAd : false,
            rescheduled: false,
            date: post.scheduledPublishDate,
          });
        }
      });
      this.sortPostSummaryList();
    } else {
      this.postsToReschedule = [];
    }

    this.numPosts = this.postsToReschedule.length;

    if (this.postsToReschedule.length === 0) {
      this.step = 2;
    } else if (
      this.postsToReschedule[this.progress] &&
      this.needsToBeRescheduled(this.postsToReschedule[this.progress])
    ) {
      this.setRescheduleDialog(this.postsToReschedule[this.progress]);
    }
  }

  private sortPostSummaryList(): void {
    this.postSummaryList.sort((summaryA: PostSummary, summaryB: PostSummary) =>
      dayjs(summaryA.post.date).unix() < dayjs(summaryB.date).unix() ? -1 : 1,
    );
  }

  private needsToBeRescheduled(post: PostLog): boolean {
    return post.scheduledPublishDate
      ? post.scheduledPublishDate.getTime() < Date.now()
      : true;
  }

  private setRescheduleDialog(post: PostLog): void {
    setTimeout(() => {
      this.rescheduleDialogRef = this.dialog.open(
        PostRescheduleDialogComponent,
        {
          hasBackdrop: false,
          data: {
            post: post,
            campaign: this.campaign,
          },
          width: "480px",
          autoFocus: false,
        },
      );

      this.rescheduleDialogRef.componentInstance.postRescheduled.subscribe(
        (rescheduledPost: PostLog) => {
          this.postsToReschedule[this.progress] = rescheduledPost;
          this.acceptedPosts.push(rescheduledPost);
          this.postSummaryList.push({
            post: rescheduledPost,
            publishing: !!this.facebookConnected,
            hasPromoAd: this.facebookAdsAuthorized ? post.hasAd : false,
            rescheduled: true,
            date: post.scheduledPublishDate,
          });
          this.sortPostSummaryList();
          this.acceptPost();
        },
      );

      this.rescheduleDialogRef.componentInstance.postSkipped.subscribe(
        (skippedPost: PostLog) => {
          this.postSummaryList.push({
            post: skippedPost,
            publishing: false,
            hasPromoAd: post.hasAd,
            rescheduled: false,
            date: post.scheduledPublishDate,
          });
          this.sortPostSummaryList();
          this.skipPost();
        },
      );
    });
  }

  private skipPost(): void {
    if (this.rescheduleDialogRef) {
      this.rescheduleDialogRef.close();
    }
    // TODO: Remove `as any`
    this.postsToReschedule[this.progress].scheduledPublishDate = null as any;
    this.progress += 1;
    if (this.progress === this.numPosts) {
      this.step = 2;
    } else {
      this.carousel.next();
      if (this.needsToBeRescheduled(this.postsToReschedule[this.progress])) {
        this.setRescheduleDialog(this.postsToReschedule[this.progress]);
      }
    }
  }

  private acceptPost(): void {
    this.progress += 1;
    if (this.progress === this.numPosts) {
      this.step = 2;
    } else {
      this.carousel.next();
      if (this.needsToBeRescheduled(this.postsToReschedule[this.progress])) {
        this.setRescheduleDialog(this.postsToReschedule[this.progress]);
      }
    }
  }

  protected getFacebookPostLog(postLog: PostLog): PartnerFacebookPostLog {
    return postLog as PartnerFacebookPostLog;
  }

  protected getGoogleAdPostLog(postLog: PostLog): PartnerGoogleAdPostLog {
    return postLog as PartnerGoogleAdPostLog;
  }

  private getLinkedInPostLog(postLog: PostLog): PartnerLinkedInPostLog {
    return postLog as PartnerLinkedInPostLog;
  }

  protected close(): void {
    this.rescheduleDialogRef?.close();
    this.dialogRef.close();
  }

  protected hasPromoAd(postSummary: PostSummary): boolean {
    return (
      (postSummary.post.isFacebookPost &&
        !this.getFacebookPostLog(postSummary.post).isDarkPost &&
        postSummary.hasPromoAd) ||
      postSummary.post.isGoogleAd
    );
  }

  protected getPostSummaryImage(postSummary: PostSummary): string {
    if (postSummary.post.mediaType === CampaignPostContentType.Carousel) {
      const post = this.getFacebookPostLog(postSummary.post);
      return post.carouselSlides[0]?.url;
    }

    if (
      postSummary.post.isLinkedInPost &&
      postSummary.post.mediaType === LinkedInPostContentType.MultiImage
    ) {
      const post = this.getLinkedInPostLog(postSummary.post);
      return post.images[0]?.url;
    }

    return `${postSummary.post.image}`;
  }
}
