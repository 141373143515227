import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PartnerService } from "./api/partner.service";
import { NotificationService } from "./notification.service";
import { PartnerDialogService } from "../../partner/partner-dialog.service";

@Injectable()
export class RequestErrorService {
  constructor(
    protected readonly dialog: MatDialog,
    protected readonly partnerService: PartnerService,
    protected readonly notificationService: NotificationService,
    protected readonly partnerDialogService: PartnerDialogService,
  ) {}

  public partnerNotConnectedError(errorDetails?: string): void {
    if (!errorDetails) {
      this.notificationService.error("shared.sessionInvalidated");
    }
    // If the access token has expired, we need to remove it from the partner.
    this.partnerService.currentPartner.hasConnectedToFacebook = false;
    this.partnerService.update(this.partnerService.currentPartner).subscribe({
      next: (partner) => {
        this.partnerService.setCurrentPartner(partner);
      },
      error: () => {
        this.notificationService.error("shared.changesCouldNotBeSaved");
      },
    });

    this.partnerDialogService.showFacebookNotConnected({
      partner: this.partnerService.currentPartner,
      errorDetails: errorDetails,
    });

    this.dialog.closeAll();
  }
}
