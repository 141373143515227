<h2 mat-dialog-title>
  {{ "partner.accountAccessRequest.title" | translate }}
</h2>
<mat-dialog-content>
  <p>
    {{
      "partner.accountAccessRequest.firstLine"
        | translate
          : {
              userEmail: data.userEmail
            }
    }}
  </p>
  <p class="confirm-message">
    {{ "partner.accountAccessRequest.secondLine" | translate }}
  </p>
</mat-dialog-content>
<mat-dialog-actions class="actions">
  <button class="btn btn--grey" [mat-dialog-close]="">
    {{ "shared.no" | translate }}
  </button>
  <button class="btn btn--primary" [mat-dialog-close]="true">
    {{ "shared.yes" | translate }}
  </button>
</mat-dialog-actions>
