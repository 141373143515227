import { Component, Inject } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";

import { UrlPrefixes } from "../../../shared/models/appData";
import {
  CampaignPartner,
  PartnerCampaign,
} from "../../../shared/models/partnerCampaign";
import { PartnerService } from "../../../shared/services/api/partner.service";
import { NotificationService } from "../../../shared/services/notification.service";
import { UrlFormatterService } from "../../../shared/services/url-formatter-service";
import { CustomFormValidators } from "../../../shared/validators/custom-form-validators";
import { PartnerUrl } from "../../partner.url";

export interface PartnerCampaignMissingUrlDialogData {
  readonly campaign: PartnerCampaign;
  readonly webPrefix: string;
  readonly webUrl: string;
}

export type PartnerCampaignMissingUrlDialogResult = true;

@Component({
  selector: "app-partner-campaign-missing-url-dialog",
  templateUrl: "./partner-campaign-missing-url-dialog.component.html",
  styleUrl: "./partner-campaign-missing-url-dialog.component.scss",
})
export class PartnerCampaignMissingUrlDialogComponent {
  protected readonly URL_VALIDATION_PATTERN =
    CustomFormValidators.URL_WITH_OBLIGATORY_NO_PROTOCOL_PATTERN;
  protected readonly UrlPrefixes = UrlPrefixes;
  protected form!: UntypedFormGroup;
  protected saving = false;

  private campaignId!: number;
  private campaignPartner!: CampaignPartner;

  constructor(
    private readonly partnerService: PartnerService,
    private readonly notificationService: NotificationService,
    private readonly urlFormatterService: UrlFormatterService,
    @Inject(MAT_DIALOG_DATA) data: PartnerCampaignMissingUrlDialogData,
    private readonly dialogRef: MatDialogRef<
      PartnerCampaignMissingUrlDialogComponent,
      PartnerCampaignMissingUrlDialogResult
    >,
    private readonly router: Router,
  ) {
    this.campaignId = data.campaign.id;
    this.campaignPartner = data.campaign.partner;

    this.form = new UntypedFormGroup({
      urlPrefix: new UntypedFormControl(data.webPrefix ?? UrlPrefixes[0], [
        Validators.required,
      ]),
      url: new UntypedFormControl(data.webUrl ?? "", [Validators.required]),
    });
  }

  protected updateCampaignPartner(): void {
    this.saving = true;
    this.campaignPartner.shortUrl = this.urlFormatterService.formatUrl(
      this.form.get("url")!.value,
    );
    this.campaignPartner.shortUrlPrefix = this.form.get("urlPrefix")!.value;

    this.partnerService
      .updateCampaignPartner(
        this.campaignPartner.id,
        this.campaignId,
        this.campaignPartner,
      )
      .subscribe({
        next: (campaign) => {
          this.saving = false;
          this.campaignPartner.shortUrl = campaign.partner.shortUrl;
          this.campaignPartner.shortUrlPrefix = campaign.partner.shortUrlPrefix;
          this.dialogRef.close();
        },
        error: () => {
          this.saving = false;
          this.notificationService.error("shared.changesCouldNotBeSaved");
        },
      });
  }

  protected cancel(): void {
    this.router.navigate([
      PartnerUrl.Home(this.partnerService.currentPartnerId),
    ]);
    this.dialogRef.close();
  }
}
