import { Component, EventEmitter, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";

import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { PartnerCampaignService } from "../../../shared/services/api/partner-campaign.service";
import { NotificationService } from "../../../shared/services/notification.service";

import { PartnerCampaignBudgetConfirmDialogData } from "./partner-campaign-budget-confirm-dialog-data";
const NOT_ENOUGH_BUDGET_LEFT =
  "PARTNER_EDIT_CAMPAIGN_BUDGET_NOT_ENOUGH_BUDGET_LEFT";

@Component({
  selector: "app-partner-campaign-budget-confirm-dialog",
  templateUrl: "./partner-campaign-budget-confirm-dialog.component.html",
  styleUrls: ["./partner-campaign-budget-confirm-dialog.component.scss"],
})
export class PartnerCampaignBudgetConfirmDialogComponent {
  public campaign: PartnerCampaign;
  public saving = false;
  public budget = 0;
  public adDuration = 0;
  public numberOfAds = 0;
  public backEvent: EventEmitter<PartnerCampaignBudgetConfirmDialogData>;
  constructor(
    public notificationService: NotificationService,
    public partnerCampaignService: PartnerCampaignService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: PartnerCampaignBudgetConfirmDialogData,
  ) {
    this.campaign = this.data.campaign;
    this.budget = this.data.budget;
    this.adDuration = this.data.adDuration;
    this.numberOfAds = this.data.numberOfAds;
    this.backEvent = new EventEmitter();
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  back(): void {
    this.backEvent.emit(this.data);
  }

  confirm(): void {
    this.saving = true;
    this.partnerCampaignService
      .editBudget(
        this.campaign.id,
        this.budget,
        this.adDuration,
        this.numberOfAds,
      )
      .subscribe(
        () => {
          this.campaign.customAdDuration = this.adDuration;
          this.campaign.customBudgetAmount = this.budget;
          this.campaign.customNumberOfAds = this.numberOfAds;
          this.campaign.hasDefinedCustomBudget = true;
          this.saving = false;
          this.partnerCampaignService.partnerCampaignBudgetEditedEvent.emit();
          this.notificationService.success("shared.changesSavedSuccessfully");
        },
        (err) => {
          const error = err.error;
          const errorMessage =
            error?.key === NOT_ENOUGH_BUDGET_LEFT
              ? "partner.campaign.confirmBudget.notEnoughBudgetLeft"
              : "shared.changesCouldNotBeSaved";
          this.saving = false;
          this.notificationService.error(errorMessage);
        },
      );
  }
}
