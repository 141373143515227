import { EventEmitter, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PostPlatform } from "../../../shared/enums/campaignPost.enums";
import { PartnerFacebookPostLog } from "../../../shared/models/partnerFacebookPostLog";
import { PartnerGoogleAdPostLog } from "../../../shared/models/partnerGoogleAdPostLog";
import { PartnerLinkedInPostLog } from "../../../shared/models/partnerLinkedInPostLog";
import { PostLog } from "../../../shared/models/postLog";
import { HttpClient } from "../../../shared/services/http-client";
import { CreatePostLogFromPostData } from "../../../shared/services/parameters/create-post-log-from-post-data";
import { UpdateFacebookPostLogData } from "../../../shared/services/parameters/update-facebook-post-log-data";
import { UpdateGoogleAdPostLogData } from "../../../shared/services/parameters/update-google-ad-post-log-data";
import { UpdateLinkedinPostLogData } from "../../../shared/services/parameters/update-linkedin-post-log-data";
import { PartnerService } from "../../../shared/services/api/partner.service";

@Injectable()
export class PartnerCampaignPostLogService {
  public postLogCreatedOrUpdated = new EventEmitter<PostLog>();

  constructor(
    private http: HttpClient,
    public partnerService: PartnerService,
  ) {}

  private getCurrentPartnerId(): number {
    return this.partnerService.currentPartnerId;
  }

  public getCampaignPostLog(postId: number): Observable<PostLog> {
    const partnerId = this.getCurrentPartnerId();

    return this.http.get(`partner/${partnerId}/post_log/${postId}`).pipe(
      map(({ body }) => {
        const platform: PostPlatform = body.platform;

        switch (platform) {
          case PostPlatform.Facebook:
            return new PartnerFacebookPostLog(body);
          case PostPlatform.Google:
            return new PartnerGoogleAdPostLog(body);
          case PostPlatform.LinkedIn:
            return new PartnerLinkedInPostLog(body);
        }
      }),
    );
  }

  public createLinkedInPostLog(
    campaignId: number,
    data: CreatePostLogFromPostData,
  ): Observable<PartnerLinkedInPostLog> {
    const partnerId = this.getCurrentPartnerId();

    return this.http
      .post(
        `partner/${partnerId}/campaign/${campaignId}/create_partner_linkedin_post`,
        JSON.stringify(data),
      )
      .pipe(map(({ body }) => new PartnerLinkedInPostLog(body)));
  }

  public createFacebookPostLog(
    campaignId: number,
    data: CreatePostLogFromPostData,
  ): Observable<PartnerFacebookPostLog> {
    const partnerId = this.getCurrentPartnerId();

    return this.http
      .post(
        `partner/${partnerId}/campaign/${campaignId}/facebook_post_and_or_ad`,
        JSON.stringify(data),
      )
      .pipe(map(({ body }) => new PartnerFacebookPostLog(body)));
  }

  public updateCampaignPost(
    postId: number,
    data:
      | UpdateFacebookPostLogData
      | UpdateGoogleAdPostLogData
      | UpdateLinkedinPostLogData,
  ): Observable<PostLog> {
    const partnerId = this.getCurrentPartnerId();

    return this.http.put(`partner/${partnerId}/post_log/${postId}`, data).pipe(
      map(({ body }) => {
        const platform: PostPlatform = body.platform;

        switch (platform) {
          case PostPlatform.Facebook:
            return new PartnerFacebookPostLog(body);
          case PostPlatform.Google:
            return new PartnerGoogleAdPostLog(body);
          case PostPlatform.LinkedIn:
            return new PartnerLinkedInPostLog(body);
        }
      }),
    );
  }

  public deleteCampaignPostLog(postId: number): Observable<void> {
    const partnerId = this.getCurrentPartnerId();

    return this.http
      .del(`partner/${partnerId}/post_log/${postId}`)
      .pipe(map(() => {}));
  }
}
