<section class="section">
  <div class="form-container">
    <div
      class="row form"
      [ngClass]="{ 'valign-center direction-down': !isEditForm }"
    >
      <div class="col">
        <div class="row">
          <div class="col width-100">
            <mat-form-field>
              <mat-label>{{
                "partner.address.addressPlaceholder" | translate
              }}</mat-label>
              <input
                matInput
                #addressStreet="ngModel"
                [(ngModel)]="address.streetAndNumber"
                name="street"
                required
              />
              <mat-error
                >{{ "partner.address.addressRequired" | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col width-50">
            <mat-form-field>
              <mat-label>{{
                "partner.address.zipCodePlaceholder" | translate
              }}</mat-label>
              <input
                matInput
                #addressPostalCode="ngModel"
                [(ngModel)]="address.postalCode"
                name="postalCode"
                required
              />
              <mat-error
                >{{ "partner.address.zipCodeRequired" | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col width-50">
            <mat-form-field>
              <mat-label>{{
                "partner.address.cityPlaceholder" | translate
              }}</mat-label>
              <input
                matInput
                #addressCity="ngModel"
                [(ngModel)]="address.city"
                name="city"
                required
              />
              <mat-error
                >{{ "partner.address.cityRequired" | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col width-100">
            <app-country-selector
              [(value)]="address.country"
              name="country"
              [placeholder]="'partner.address.countryPlaceholder' | translate"
              [required]="true"
            />
          </div>
        </div>
        <mat-form-field class="fieldset">
          <mat-label>{{
            "partner.address.additionalInformationPlaceholder" | translate
          }}</mat-label>
          <textarea
            class="address-additional-information"
            matInput
            maxlength="255"
            #addressAdditionalInformation="ngModel"
            [(ngModel)]="address.additionalInformation"
            name="additionalInformation"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="form-actions">
      <div class="default-address">
        <mat-checkbox
          *ngIf="canDisplayDefaultCheckbox()"
          [disabled]="!canChangeDefault"
          name="is-default-address"
          [(ngModel)]="address.isDefault"
          [value]="1"
          >{{ "partner.address.markAsDefault" | translate }}</mat-checkbox
        >
      </div>
      <button
        [disabled]="submitting"
        class="btn btn--grey"
        (click)="onCancel()"
      >
        {{ "shared.cancel" | translate }}
      </button>
      <button
        [disabled]="submitting"
        class="btn btn--primary"
        (click)="save()"
        [disabled]="!isValidForm()"
      >
        <span *ngIf="!submitting">{{ "shared.save" | translate }}</span>
        <span *ngIf="submitting">{{ "shared.saving" | translate }}</span>
      </button>
    </div>
  </div>
</section>
