import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { Address, AddressType } from "../../shared/models/address";
import { PartnerService } from "../../shared/services/api/partner.service";
import { NotificationService } from "../../shared/services/notification.service";

@Component({
  selector: "app-address-edition-form",
  templateUrl: "./address-edition-form.component.html",
  styleUrl: "./address-edition-form.component.scss",
})
export class AddressEditionFormComponent implements OnInit {
  @Input({ required: true }) public addressType!: AddressType;
  @Input({ required: true }) public canChangeDefault!: boolean;
  @Input({ required: true }) public sourceAddress!: Address;

  @Output() public cancelEvent = new EventEmitter<void>();
  @Output() public createdEvent = new EventEmitter<Address>();
  @Output() public updatedEvent = new EventEmitter<Address>();

  protected address!: Address;
  protected isEditForm = false;
  protected submitting = false;

  constructor(
    private readonly notificationService: NotificationService,
    private readonly partnerService: PartnerService,
    private readonly translateService: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.address = this.sourceAddress.clone();
  }

  protected canDisplayDefaultCheckbox(): boolean {
    return !this.sourceAddress.isDefault;
  }

  protected onCancel(): void {
    this.cancelEvent.emit();
  }

  protected save(): void {
    this.submitting = true;

    if (this.address.id) {
      this.updateAddress();
    } else {
      this.createNewAddress();
    }
  }

  private updateAddress(): void {
    if (this.address.isDefault) {
      this.markOtherAddressesAsNotDefault();
    }

    this.partnerService
      .updateAddress(this.partnerService.currentPartner.id, this.address)
      .subscribe({
        next: () => {
          this.showServerSuccess("partner.address.updatedSuccessfully");
          this.updatedEvent.emit(this.address);
          this.submitting = false;
        },
        error: (err) => {
          this.showServerError(err, "partner.address.saveError");
          this.cancelEvent.emit();
          this.submitting = false;
        },
      });
  }

  private createNewAddress(): void {
    if (this.address.isDefault) {
      this.markOtherAddressesAsNotDefault();
    }
    this.partnerService
      .createAddress(
        this.partnerService.currentPartner.id,
        this.getSanitizedAddress(this.address),
      )
      .subscribe({
        next: (result) => {
          this.showServerSuccess("partner.address.createdSuccessfully");
          this.partnerService.currentPartner.addresses.push(result);

          if (this.addressType === AddressType.Billing) {
            this.partnerService.currentPartner.billingAddressId = result.id;
          } else {
            this.partnerService.currentPartner.shippingAddressId = result.id;
          }

          this.submitting = false;
          this.createdEvent.emit(result);
          this.partnerService.setCurrentPartner(
            this.partnerService.currentPartner,
          );
        },
        error: (err) => {
          this.showServerError(err, "partner.address.saveError");
          this.cancelEvent.emit();
          this.submitting = false;
        },
      });
  }

  private markOtherAddressesAsNotDefault(): void {
    this.partnerService.currentPartner.addresses.map((address: Address) => {
      if (this.addressType === address.type) {
        address.isDefault = false;
      }
    });
  }

  private showServerSuccess(msg: string): void {
    this.submitting = false;
    this.notificationService.success(msg);
  }

  private showServerError(errorResponse: any, msg: string): void {
    const error = errorResponse.error;

    this.submitting = false;

    if (error.details) {
      this.notificationService.errorDialog(
        this.translateService.instant(msg) + error.message,
        this.translateService.instant("Error: ") + error.name,
        error.details,
      );
    } else {
      this.notificationService.errorDialog(
        this.translateService.instant(msg) + error.message,
        this.translateService.instant("Error: ") + error.name,
      );
    }
  }

  protected isValidForm(): boolean {
    const address = this.getSanitizedAddress(this.address);

    return !!(
      address.streetAndNumber &&
      address.postalCode &&
      address.city &&
      address.country
    );
  }

  private getSanitizedAddress(address: Address): Address {
    const processedAddress = address.clone();

    processedAddress.streetAndNumber = address.streetAndNumber?.trim();
    processedAddress.postalCode = address.postalCode?.trim();
    processedAddress.city = address.city?.trim();
    processedAddress.additionalInformation =
      address.additionalInformation?.trim();

    return processedAddress;
  }
}
