<button class="close-icon" tabindex="-1" [mat-dialog-close]="">
  <mat-icon>close</mat-icon>
</button>
<h2 mat-dialog-title>
  {{ "partner.campaign.contentCalendar.setupDialog.title" | translate }}
</h2>
<mat-dialog-content>
  <img class="image" src="/assets/images/as_pal_calendar.svg" />
  <p>
    {{ "partner.campaign.contentCalendar.setupDialog.subtitle" | translate }}
  </p>
  @if (showDetails) {
    <div class="calendar-selected-message">
      <p>
        {{
          "partner.campaign.contentCalendar.setupDialog.campaignSelected"
            | translate
        }}
      </p>
      <p>
        <strong>
          {{ currentCalendar.title }} ({{
            campaignDetails.language.name | translate
          }})
        </strong>
      </p>
    </div>
  }
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn btn--transparent" [mat-dialog-close]="">
    {{ "shared.cancel" | translate }}
  </button>
  <button class="btn btn--primary" [mat-dialog-close]="true">
    {{ "shared.ok" | translate }}
  </button>
</mat-dialog-actions>
