<mat-dialog-content>
  <p class="description" *ngIf="post.scheduledPublishDate">
    {{ "partner.campaign.rescheduleDialog.title" | translate }}
  </p>
  <p class="date" *ngIf="post.scheduledPublishDate">
    {{ post.scheduledPublishDate | date: "medium" }}
  </p>
  <p class="sub-description">
    {{ "partner.campaign.rescheduleDialog.description" | translate }}
  </p>
  <div class="picker-wrapper">
    <app-partner-campaign-posts-schedule-time-picker
      [minDate]="datePickerMinDate"
      [maxDate]="datePickerMaxDate"
      [compressedLayout]="true"
      [post]="post"
    />
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn btn--transparent" (click)="skipPost()">
    {{ "shared.skip" | translate }}
  </button>
  <button
    class="btn btn--primary"
    (click)="updatePostDate()"
    [disabled]="isRescheduleDisabled()"
  >
    {{ "partner.campaign.rescheduleDialog.publishPost" | translate }}
  </button>
</mat-dialog-actions>
