import { Component } from "@angular/core";

export type LinkedinDisconnectDialogData = undefined;
export type LinkedinDisconnectDialogResult = true;

@Component({
  selector: "app-linkedin-disconnect-dialog",
  templateUrl: "./linkedin-disconnect-dialog.component.html",
  styleUrls: ["../third-party-disconnect-dialog.component.scss"],
})
export class LinkedinDisconnectDialogComponent {}
