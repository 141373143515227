import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { LinkedInOrganization } from "../../../shared/models/linkedIn-organization";
import { LinkedInService } from "../../../shared/services/api/linkedin.service";
import { PartnerService } from "../../../shared/services/api/partner.service";

export type LinkedinOrganizationConnectDialogData = undefined;
export type LinkedinOrganizationConnectDialogResult = true;

@Component({
  selector: "app-linkedin-organization-connect-dialog",
  templateUrl: "./linkedin-organization-connect-dialog.component.html",
  styleUrls: ["./linkedin-organization-connect-dialog.component.scss"],
})
export class LinkedinOrganizationConnectDialogComponent implements OnInit {
  public selectedOrganization?: LinkedInOrganization;
  public isLoading = false;
  public isSaving = false;
  public noResultsFound = false;
  public organizations: LinkedInOrganization[] = [];
  private readonly partnerId: number;

  constructor(
    private readonly partnerService: PartnerService,
    private readonly linkedinService: LinkedInService,
    private readonly dialogRef: MatDialogRef<
      LinkedinOrganizationConnectDialogComponent,
      LinkedinOrganizationConnectDialogResult
    >,
  ) {
    this.partnerId = this.partnerService.currentPartner!.id;
  }

  public async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.organizations = await this.linkedinService.getOrganizations(
      this.partnerId,
    );

    if (this.organizations.length > 0) {
      this.selectedOrganization = this.organizations[0];
    } else {
      this.noResultsFound = true;
    }
    this.isLoading = false;
  }

  public async onActionSelectOrganization(): Promise<void> {
    this.isSaving = true;
    await this.linkedinService.setPartnerOrganization(
      this.partnerId,
      this.selectedOrganization!,
    );
    this.isSaving = false;
    this.dialogRef.close(true);
  }
}
