<mat-dialog-content>
  <img src="/assets/images/animation-flying-infinite.gif" />
  <h2 *ngIf="postLog.hasAd">
    {{ "partner.contentPublished.adTitle" | translate }}
  </h2>
  <h2 *ngIf="!postLog.hasAd && postLog.isFacebookPost">
    {{ "partner.contentPublished.postTitle" | translate }}
  </h2>
  <h2 *ngIf="!postLog.hasAd && postLog.isLinkedInPost">
    {{ "partner.contentPublished.linkedInPostTitle" | translate }}
  </h2>
  <p *ngIf="postLog.hasAd && postLog.isFacebookPost">
    {{ "partner.contentPublished.subtitle" | translate }}
  </p>
  <p *ngIf="postLog.hasAd && postLog.isGoogleAd">
    {{ "partner.contentPublished.subtitleGoogleAd" | translate }}
  </p>
  <button class="btn btn--primary" (click)="onActionContinue()">
    {{ "shared.continue" | translate }}
  </button>
</mat-dialog-content>
