<section class="carousel-wrapper" [ngStyle]="carouselWrapperStyle">
  <ul class="carousel-inner" #carousel>
    <li
      *ngFor="let item of items; let i = index"
      [className]="
        i === currentSlide ? 'posts-carousel-item' : 'posts-carousel-item'
      "
    >
      <ng-container [ngTemplateOutlet]="item.tpl"></ng-container>
    </li>
  </ul>
</section>
<div *ngIf="showControls" style="margin-top: 1em">
  <button (click)="next()" class="btn btn-default">Next</button>
  <button (click)="prev()" class="btn btn-default">Prev</button>
</div>
