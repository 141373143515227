<h2 mat-dialog-title>
  {{ "partner.campaign.editBudget.title" | translate }}
  <span class="close-icon" [mat-dialog-close]="">
    <mat-icon>close</mat-icon>
  </span>
</h2>
<mat-dialog-content class="content">
  <!-- Budget Section -->
  <div class="content-section content-section--first">
    <div class="content-section-title">
      {{ "partner.campaign.editBudget.budgetTitle" | translate }}
      <app-tooltip
        class="content-section-title-tooltip"
        text="{{ 'partner.campaign.editBudget.budgetTooltip' | translate }}"
      >
      </app-tooltip>
    </div>
    <div class="content-section-subtitle">
      {{ "partner.campaign.editBudget.subtitle" | translate }}
    </div>
    <div class="content-section-slider">
      <mat-slider
        [disabled]="disableBudgetSlider"
        step="5"
        name="budget"
        class="content-section-slider-bar"
        [max]="initialBudgetLeft"
        [min]="0"
        #ngSlider
        ><input
          [(ngModel)]="budget"
          [disabled]="disableBudgetSlider"
          matSliderThumb
          (change)="sliderChange()"
          #ngSliderThumb="matSliderThumb"
        />
      </mat-slider>
      <div class="content-section-slider-value">
        <input
          (input)="sliderChange()"
          [disabled]="disableBudgetSlider"
          matInput
          min="0"
          max="{{ initialBudgetLeft }}"
          [(ngModel)]="budget"
          name="budgetInput"
          type="number"
          required
        />
        <span>{{ campaign.currencySymbol }}</span>
      </div>
    </div>
    <div class="content-section-subtitle">
      {{
        "partner.campaign.editBudget.budgetLeft"
          | translate
            : {
                date: getFormattedBudgetAvailableUntilDate()
              }
      }}
    </div>
    <div class="content-section-slider content-section-slider--low-margin">
      <mat-slider
        [disabled]="true"
        step="1"
        name="initialBudgetLeft"
        [max]="initialBudgetLeft"
        [min]="0"
        class="content-section-slider-bar-budget-left"
        ><input matSliderThumb [(ngModel)]="adjustedBudgetLeft" />
      </mat-slider>
      <div class="content-section-slider-value">
        <input
          matInput
          [disabled]="true"
          [(ngModel)]="adjustedBudgetLeft"
          name="initialBudgetLeftInput"
          type="number"
          required
        />
        <span>{{ campaign.currencySymbol }}</span>
      </div>
    </div>
  </div>
  <!-- End of Budget Section -->
  <!-- Number of Ads Section -->
  <div class="content-section">
    <div class="content-section-title">
      {{ "partner.campaign.editBudget.numberOfAdsTitle" | translate }}
      <app-tooltip
        class="content-section-title-tooltip"
        text="{{
          'partner.campaign.editBudget.numberOfAdsToolTip' | translate
        }}"
      >
      </app-tooltip>
    </div>
    <div class="content-section-subtitle">
      {{ "partner.campaign.editBudget.numberOfAdsSubtitle" | translate }}
    </div>
    <div class="content-section-slider">
      <mat-slider
        [disabled]="!campaign.canDefineCustomBudget"
        step="1"
        [discrete]="true"
        name="maxNumberOfAds"
        class="content-section-slider-bar"
        [max]="maxNumberOfAds"
        [min]="0"
        #ngSlider
        ><input
          [(ngModel)]="numberOfAds"
          [disabled]="!campaign.canDefineCustomBudget"
          matSliderThumb
          (change)="sliderChange()"
          #ngSliderThumb="matSliderThumb"
        />
      </mat-slider>
      <div class="content-section-slider-value">
        <input
          (input)="sliderChange()"
          matInput
          [(ngModel)]="numberOfAds"
          min="0"
          max="{{ maxNumberOfAds }}"
          name="numberOfAdsInput"
          type="number"
          required
        />
        <span>{{ "shared.ads" | translate }}</span>
      </div>
    </div>
  </div>
  <!-- End of Number of Ads Section -->
  <!--Duration pe Ad Section -->
  <div class="content-section">
    <div class="content-section-title">
      {{ "partner.campaign.editBudget.adDurationTitle" | translate }}
      <app-tooltip
        class="content-section-title-tooltip"
        text="{{ 'partner.campaign.editBudget.adDurationToolTip' | translate }}"
      >
      </app-tooltip>
    </div>
    <div class="content-section-subtitle">
      {{ "partner.campaign.editBudget.adDurationSubtitle" | translate }}
    </div>
    <div class="content-section-slider">
      <mat-slider
        [disabled]="!campaign.canDefineCustomBudget"
        step="1"
        [discrete]="true"
        name="adDuration"
        class="content-section-slider-bar"
        [max]="maxAdDuration"
        [min]="0"
        #ngSlider
        ><input
          [(ngModel)]="adDuration"
          matSliderThumb
          [disabled]="!campaign.canDefineCustomBudget"
          (change)="sliderChange()"
          #ngSliderThumb="matSliderThumb"
        />
      </mat-slider>
      <div class="content-section-slider-value">
        <input
          (input)="sliderChange()"
          matInput
          min="0"
          max="{{ maxAdDuration }}"
          [(ngModel)]="adDuration"
          name="adDurationInput"
          type="number"
          required
        />
        <span>{{ "shared.days" | translate }}</span>
      </div>
    </div>
  </div>

  <div class="content-section">
    <app-budget-plan
      [currencySymbol]="campaign.currencySymbol"
      [budget]="budget"
      [maxAdsPerPartner]="numberOfAds"
      [maxAdDurationDays]="adDuration"
    />
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="round-button round-button--grey" [mat-dialog-close]="">
    {{ "shared.cancel" | translate }}
  </button>
  <button
    [disabled]="isSaveDisabled()"
    class="round-button round-button--orange button-left"
    (click)="editBudgetConfirm()"
  >
    <span *ngIf="!saving">{{ "shared.save" | translate }}</span>
    <span *ngIf="saving">{{ "shared.saving" | translate }}</span>
  </button>
</mat-dialog-actions>
