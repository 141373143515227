import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PostLog } from "../../shared/models/postLog";
import { PartnerUrl } from "../partner.url";

export interface ContentPublishedDialogData {
  readonly postLog: PostLog;
  readonly redirectToScheduledPosts: boolean;
  readonly partnerId: number;
}

export type ContentPublishedDialogResult = undefined;

@Component({
  selector: "app-content-published-dialog",
  templateUrl: "./content-published-dialog.component.html",
  styleUrls: ["./content-published-dialog.component.scss"],
})
export class ContentPublishedDialogComponent {
  public readonly postLog: PostLog;
  private readonly redirectToScheduledPosts: boolean;
  private readonly partnerId: number;

  constructor(
    private readonly router: Router,
    private readonly dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data: ContentPublishedDialogData,
  ) {
    this.postLog = data.postLog;
    this.redirectToScheduledPosts = data.redirectToScheduledPosts ?? false;
    this.partnerId = data.partnerId;
  }

  public onActionContinue(): void {
    this.dialog.closeAll();
    if (this.redirectToScheduledPosts) {
      this.router.navigate([
        PartnerUrl.CampaignContentCalendar(
          this.partnerId,
          this.postLog.campaignId,
        ),
      ]);
    }
  }
}
