import { Component, Inject, OnDestroy } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { lastValueFrom } from "rxjs";
import { AlertDialogComponent } from "../../../shared/components/alert-dialog/alert-dialog.component";
import { FacebookService } from "../../../shared/services/facebook.service";
import { FacebookConnectDialogComponent } from "../facebook-connect-dialog/facebook-connect-dialog.component";
import { Subscription } from "rxjs";
import { Partner } from "../../../shared/models/partner";
import { PartnerService } from "../../../shared/services/api/partner.service";
import { NotificationService } from "../../../shared/services/notification.service";
import {
  FacebookNotInitializedError,
  FacebookPageInfoResponse,
} from "../../../shared/entities/facebook";

export interface FacebookNotConnectedDialogData {
  readonly partner: Partner;
  readonly isReconnect?: boolean;
  readonly errorDetails?: string;
}

export type FacebookNotConnectedDialogResult = Partner;

@Component({
  selector: "app-facebook-not-connected-dialog",
  templateUrl: "./facebook-not-connected-dialog.component.html",
})
export class FacebookNotConnectedDialogComponent implements OnDestroy {
  public partner: Partner;
  public currentPartnerId?: number;
  public errorDetails: string | undefined;
  public isReconnect = false;
  private readonly subscription = new Subscription();

  constructor(
    private readonly dialog: MatDialog,
    private readonly partnerService: PartnerService,
    private readonly notificationService: NotificationService,
    private readonly facebookService: FacebookService,
    private readonly dialogRef: MatDialogRef<
      FacebookNotConnectedDialogComponent,
      FacebookNotConnectedDialogResult
    >,
    @Inject(MAT_DIALOG_DATA)
    data: FacebookNotConnectedDialogData,
  ) {
    this.partner = data.partner;
    this.errorDetails = data.errorDetails;
    this.currentPartnerId = partnerService.currentPartnerId;
    this.isReconnect = !!data.isReconnect;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public async connect(): Promise<void> {
    try {
      const accessToken = await this.facebookService.login();
      if (accessToken) {
        this.openFacebookConnectDialog();
      } else {
        this.dialogRef.close();
        this.notificationService.error(
          "partner.edit.facebookConnectPermissionsError",
        );
      }
    } catch (error) {
      const message =
        error instanceof FacebookNotInitializedError
          ? error.message
          : "partner.edit.facebookConnectPermissionsError";
      this.notificationService.error(message);
    }
  }

  public async openFacebookConnectDialog(): Promise<void> {
    const connectionData = await lastValueFrom(
      this.dialog
        .open(FacebookConnectDialogComponent, {
          width: "550px",
          disableClose: true,
        })
        .afterClosed(),
    );

    if (connectionData) {
      this.loginToFacebook(connectionData);
    }
  }

  private loginToFacebook(newPage: FacebookPageInfoResponse) {
    this.partnerService
      .connectPartnerToFacebook(
        newPage.id,
        newPage.accessToken,
        newPage.name,
        newPage.likes,
        newPage.picture,
        [],
      )
      .subscribe({
        next: (partner: Partner) => {
          this.partner.facebookPageId = partner.facebookPageId;
          this.partner.facebookPageName = partner.facebookPageName;
          this.partner.facebookPageLikes = partner.facebookPageLikes;
          this.partner.facebookPagePicture = partner.facebookPagePicture;
          this.partner.hasConnectedToFacebook = partner.hasConnectedToFacebook;
          this.partner.instagramAccountId = partner.instagramAccountId;
          this.partner.instagramAccountName = partner.instagramAccountName;
          this.partner.instagramAccountPicture =
            partner.instagramAccountPicture;
          this.partner.facebookPageClaimStatus =
            partner.facebookPageClaimStatus;
          this.partnerService.setCurrentPartner(partner);
          this.dialogRef.close(partner);
          this.notificationService.success("shared.changesSavedSuccessfully");
        },
        error: () => {
          this.dialog.open(AlertDialogComponent, {
            width: "550px",
            data: {
              message: "shared.errorConnectingToFacebook",
              subDescription: "shared.errorConnectingToFacebookMessage",
            },
          });
        },
      });
  }
}
