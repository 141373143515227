export const getErrorTranslationKey = (
  errorKey: string,
): string | undefined => {
  switch (errorKey) {
    case "INVALID_POST_PUBLISH_DATE":
      return "shared.publishErrorInvalidTime";
    case "NO_BUDGET_LEFT":
      return "shared.noBudgetAvailable";
    default:
      return undefined;
  }
};
