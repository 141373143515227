<div class="post-edit-row">
  <div class="dark-post-badge" *ngIf="post.isDarkPost">
    <img src="/assets/images/icons/ic_dark_post_Calendar.svg" />
    {{ "shared.darkPost" | translate }}
  </div>

  @if (post.isRenderedAsImage) {
    <img
      class="post-item-img"
      src="{{ getImageUrl(post.image?.toString() ?? '', post.imagePublicId) }}"
    />
  } @else if (post.mediaType === MEDIA_TYPE.Image360) {
    <div class="item-img-wrap">
      <app-panoramic-viewer [imageUrl]="post.image?.toString() ?? ''" />
    </div>
  } @else if (post.mediaType === MEDIA_TYPE.Carousel) {
    <app-carousel>
      <div
        carouselItem
        *ngFor="let item of post.carouselSlides"
        [image]="getImageUrl(item.url, item.imagePublicId)"
        [videoUrl]="item.videoUrl ?? ''"
        [text]="item.title"
        [link]="post.link"
      ></div>
    </app-carousel>
  } @else if (post.mediaType === MEDIA_TYPE.Video) {
    <video
      style="max-width: 100%"
      src="{{ post.videoUrl }}"
      controls
      controlslist="nofullscreen nodownload"
      oncontextmenu="return false;"
    ></video>
  }

  @if (showDownloadIcon) {
    <button
      class="download-button"
      (click)="download()"
      title="{{ 'shared.download' | translate }}"
    ></button>
  }
  <div class="post-text-editor">
    <app-partner-post-suggested-text-editor
      [showBulletPoint]="false"
      [showUseSuggestedText]="true"
      [showSuggestedText]="false"
      [post]="post"
    />
  </div>
</div>
