import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Partner } from "../../../../shared/models/partner";
import { PartnerCampaign } from "../../../../shared/models/partnerCampaign";
import { PartnerService } from "../../../../shared/services/api/partner.service";
import { NotificationService } from "../../../../shared/services/notification.service";
import { PartnerDialogService } from "../../../partner-dialog.service";

export interface PartnerCampaignListAddConnectionDialogData {
  readonly campaign: PartnerCampaign;
  readonly partner: Partner;
}

export type PartnerCampaignListAddConnectionDialogResult = true;

@Component({
  selector: "app-partner-campaign-list-add-connection-dialog",
  templateUrl: "./partner-campaign-list-add-connection-dialog.component.html",
  styleUrls: ["./partner-campaign-list-add-connection-dialog.component.scss"],
})
export class PartnerCampaignListAddConnectionDialogComponent {
  public loading = false;
  public partner: Partner;
  public campaign: PartnerCampaign;

  constructor(
    private readonly partnerService: PartnerService,
    private readonly notificationService: NotificationService,
    private readonly partnerDialogService: PartnerDialogService,
    @Inject(MAT_DIALOG_DATA) data: PartnerCampaignListAddConnectionDialogData,
  ) {
    this.campaign = data.campaign;
    this.partner = data.partner;
  }

  public async connectToFacebook(): Promise<void> {
    await this.partnerDialogService.showFacebookNotConnected({
      partner: this.partner,
    });
  }

  public async openUnclaimedPageDialog(): Promise<void> {
    await this.partnerDialogService.showPartnerAuthorizeFacebookAds({
      partner: this.partner,
    });
  }

  public hasAuthorizedPromoAds(): boolean {
    return this.partner && this.partner.isAuthorizedFBAds;
  }

  public saveGoogleAdsChoice(): void {
    this.partner.areGoogleAdsAuthorized = true;
    this.partnerService.update(this.partner).subscribe(
      () => {
        this.notificationService.success("shared.changesSavedSuccessfully");
      },
      () => {
        this.notificationService.error("shared.changesCouldNotBeSaved");
      },
    );
  }
}
