import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Order } from "../../models/order";
import { HttpClient } from "../http-client";

@Injectable()
export class CampaignService {
  public invitedCampaignId?: number;
  public partnerInviteToken?: string;

  constructor(private http: HttpClient) {}

  createOrder(
    partnerId: number,
    campaignId: number,
    order: Order,
  ): Observable<any> {
    return this.http
      .post(
        `partner/${partnerId}/campaign/${campaignId}/order`,
        JSON.stringify(order),
      )
      .pipe(map(({ body }) => body));
  }
}
