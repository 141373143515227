import { Injectable } from "@angular/core";
import {
  FacebookAuthResponse,
  FacebookMeAccountsParams,
  FacebookNotInitializedError,
  FacebookPage,
  FacebookResponse,
  FacebookStatusResponse,
} from "../entities/facebook";
import { Deferred, createDeferred } from "./deferred";
import { environment } from "../../../environments/environment";

// Types specification can be found in
// https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/facebook-js-sdk/index.d.ts
declare const FB: any;
declare global {
  interface Window {
    fbAsyncInit: () => void;
  }
}

@Injectable()
export class FacebookApiService {
  private deferredScript?: Deferred<void, FacebookNotInitializedError>;

  private api<Params extends object, Res>(
    path: string,
    params: Params,
  ): Promise<Res> {
    return new Promise((resolve, reject) => {
      FB.api(path, params, (res: any) => {
        if (res === undefined) {
          reject();
        } else if (res.error) {
          reject(res.error);
        } else {
          resolve(res);
        }
      });
    });
  }

  public async getAccessToken(): Promise<string | undefined> {
    await this.init();

    return new Promise<string | undefined>((resolve) => {
      FB.getLoginStatus((res: FacebookStatusResponse) => {
        if (res.status === "connected") {
          resolve(res.authResponse.accessToken);
        }

        resolve(undefined);
      });
    });
  }

  public async getUserPages(
    userLongLivedAccessToken: string,
  ): Promise<readonly FacebookPage[]> {
    return this.api<FacebookMeAccountsParams, FacebookResponse<FacebookPage>>(
      "/me/accounts",
      { access_token: userLongLivedAccessToken, limit: 500 },
    ).then((res) => res.data ?? []);
  }

  private async init(): Promise<void> {
    if (!this.deferredScript) {
      this.deferredScript = createDeferred<void, FacebookNotInitializedError>();

      try {
        window.fbAsyncInit = () => {
          FB.init(environment.facebookInfo);
          this.deferredScript!.resolve();
        };

        ((d, id) => {
          const tagType = "script";
          const fjs = d.getElementsByTagName(tagType)[0];
          if (d.getElementById(id)) {
            return;
          }
          const js = d.createElement(tagType);
          js.id = id;
          js.src = "https://connect.facebook.net/en_US/sdk.js";
          fjs?.parentNode?.insertBefore(js, fjs);
        })(document, "facebook-jssdk");
      } catch {
        this.deferredScript.reject(new FacebookNotInitializedError());
      }
    }

    return this.deferredScript.promise;
  }

  public login(scopes: string[]): Promise<undefined | FacebookAuthResponse> {
    return new Promise((resolve) => {
      FB.login(
        ({ authResponse, status }: FacebookStatusResponse) => {
          if (status === "connected") {
            resolve(authResponse);
          }

          resolve(undefined);
        },
        {
          scope: scopes.join(","),
          return_scopes: true,
          enable_profile_selector: true,
        },
      );
    });
  }

  public logout(): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        FB.logout(() => resolve());
      } catch {
        reject();
      }
    });
  }
}
