import { Component, EventEmitter, Inject, Output } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import dayjs from "dayjs";

import { Campaign } from "../../../../../shared/models/campaign";
import { PartnerCampaign } from "../../../../../shared/models/partnerCampaign";
import { PostLog } from "../../../../../shared/models/postLog";
import { CampaignService } from "../../../../../shared/services/api/campaign.service";

@Component({
  selector: "app-post-reschedule-dialog",
  templateUrl: "./post-reschedule-dialog.component.html",
  styleUrls: ["./post-reschedule-dialog.component.scss"],
})
export class PostRescheduleDialogComponent {
  public post: PostLog;
  public campaign: PartnerCampaign;
  public now: Date;
  public datePickerMinDate: Date;
  public datePickerMaxDate: Date;

  @Output() postRescheduled: EventEmitter<any> = new EventEmitter<any>();
  @Output() postSkipped: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public campaignService: CampaignService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PostRescheduleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.campaign = data.campaign ? data.campaign : new Campaign();
    this.datePickerMinDate = this.campaign.startDate;
    if (this.datePickerMinDate < new Date()) {
      this.datePickerMinDate = new Date();
    }
    this.datePickerMaxDate = this.campaign.endDate;
    this.post = data.post;
    this.now = new Date();
    // FB only accepts scheduling +10 minutes from now,
    // we add 4 more so we have time to process the post
    this.now = dayjs(this.now).add(14, "minutes").toDate();
  }

  updatePostDate() {
    this.post.rescheduled = true;
    this.postRescheduled.emit(this.post);
    this.dialogRef.close();
  }

  skipPost() {
    this.postSkipped.emit(this.post);
    this.dialogRef.close();
  }

  isRescheduleDisabled(): boolean {
    if (!this.post.scheduledPublishDate) {
      return false; // Initially is a valid date so it's enabled
    } else if (this.post.scheduledPublishDate < this.now) {
      return true;
    }
    return false;
  }
}
