import { DecimalPipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  standalone: true,
  selector: "app-budget-plan",
  templateUrl: "./budget-plan.component.html",
  styleUrl: "./budget-plan.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DecimalPipe, TranslateModule],
})
export class BudgetPlanComponent {
  public currencySymbol = input.required<string>();
  public budget = input.required<number>();
  public maxAdsPerPartner = input.required<number>();
  public maxAdDurationDays = input.required<number>();

  protected readonly FORMAT = "1.2-2";
  protected readonly BUDGET_PER_DAY_WARNING_THRESHOLD = 10;
  protected readonly hasBudget = computed(
    () =>
      this.budget() > 0 &&
      this.maxAdsPerPartner() > 0 &&
      this.maxAdDurationDays() > 0,
  );

  protected readonly budgetPerAd = computed(
    () => this.budget() / this.maxAdsPerPartner(),
  );

  protected readonly budgetPerDay = computed(
    () => this.budgetPerAd() / this.maxAdDurationDays(),
  );

  protected readonly showBudgetPerDayWarning = computed(
    () =>
      this.hasBudget() &&
      this.budgetPerDay() < this.BUDGET_PER_DAY_WARNING_THRESHOLD,
  );
}
