<h3 mat-dialog-title>
  {{ "partner.campaign.publishPostDialog.title" | translate }}
  <span class="close-icon" [mat-dialog-close]="">
    <mat-icon>close</mat-icon>
  </span>
</h3>
<mat-dialog-content class="dialog-content">
  <div class="dialog-content-wrap">
    <app-generic-post-mock-up [partner]="partner" [post]="post" />
    <app-partner-campaign-publish-post-time-picker
      (publishButtonClickedEvent)="schedulePost($event)"
      [publishing]="publishing"
      [campaign]="campaign"
      [post]="post"
    />
  </div>
</mat-dialog-content>
