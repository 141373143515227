import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { lastValueFrom } from "rxjs";
import { Partner } from "../../../shared/models/partner";
import { PartnerService } from "../../../shared/services/api/partner.service";
import { cloneWith } from "../../../shared/services/clonable";
import { NotificationService } from "../../../shared/services/notification.service";

export type PartnerGoogleAdsConnectionDialogData = undefined;
export type PartnerGoogleAdsConnectionDialogResult = boolean;

@Component({
  selector: "app-partner-google-ads-connection-dialog",
  templateUrl: "./partner-google-ads-connection-dialog.component.html",
  styleUrls: ["./partner-google-ads-connection-dialog.component.scss"],
})
export class PartnerGoogleAdsConnectionDialogComponent implements OnInit {
  public form!: UntypedFormGroup;
  public loading = false;
  private partner!: Partner;
  public isConnected!: boolean;

  constructor(
    private readonly dialogRef: MatDialogRef<
      PartnerGoogleAdsConnectionDialogComponent,
      PartnerGoogleAdsConnectionDialogResult
    >,
    private readonly partnerService: PartnerService,
    private readonly notificationService: NotificationService,
    private readonly fb: UntypedFormBuilder,
  ) {}

  public ngOnInit(): void {
    this.partner = this.partnerService.currentPartner!;
    this.isConnected = this.partner.areGoogleAdsAuthorized;
    this.form = this.fb.group({
      connection: [this.isConnected],
    });
  }

  public async save(): Promise<void> {
    this.loading = true;
    const isConnected = this.form.get("connection")?.value;

    const partnerToUpdate: Partner = cloneWith<Partner>(this.partner, {
      areGoogleAdsAuthorized: isConnected,
    });

    try {
      const updatedPartner = await lastValueFrom(
        this.partnerService.update(partnerToUpdate),
      );
      this.dialogRef.close(updatedPartner.areGoogleAdsAuthorized);
    } catch (e) {
      this.notificationService.error("shared.changesCouldNotBeSaved");
      this.dialogRef.close(false);
    }
    this.loading = false;
  }
}
