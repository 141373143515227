import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import dayjs from "dayjs";

import { HOURS_24, MINUTES } from "../../../shared/services/calendar.data";

import { PostLog } from "../../../shared/models/postLog";

@Component({
  selector: "app-partner-campaign-posts-schedule-time-picker",
  templateUrl: "./partner-campaign-posts-schedule-time-picker.component.html",
  styleUrls: ["./partner-campaign-posts-schedule-time-picker.component.scss"],
})
export class PartnerCampaignPostsScheduleTimePickerComponent implements OnInit {
  @Input({ required: true }) public post!: PostLog;
  @Input({ required: true }) public minDate!: Date;
  @Input({ required: true }) public maxDate!: Date;
  @Input() public compressedLayout = false;

  @Output() editedFormChange: EventEmitter<boolean> = new EventEmitter();

  protected readonly hours = HOURS_24;
  protected readonly minutes = MINUTES;
  protected scheduledPublishDate!: Date;
  protected scheduledPublishDateHour!: string;
  protected scheduledPublishDateMinute!: string;

  public ngOnInit(): void {
    // If post already has "scheduledPublishDate" that it's a scheduled post thus, not new
    const isNewPost = !this.post.scheduledPublishDate;

    this.scheduledPublishDate = isNewPost
      ? new Date()
      : this.post.scheduledPublishDate;
    this.scheduledPublishDateHour = isNewPost
      ? dayjs().add(15, "minutes").format("HH")
      : dayjs(this.scheduledPublishDate).format("HH");
    this.scheduledPublishDateMinute = isNewPost
      ? dayjs().add(15, "minutes").format("mm")
      : dayjs(this.scheduledPublishDate).format("mm");

    // Initialize and min date as today
    this.minDate = this.minDate ? this.minDate : new Date();

    // Initialize max date as 6 months from now
    this.maxDate = this.maxDate
      ? this.maxDate
      : dayjs().add(6, "months").toDate();
  }

  protected updatePostDate(): void {
    const newDate = dayjs(this.scheduledPublishDate)
      .hour(+this.scheduledPublishDateHour)
      .minute(+this.scheduledPublishDateMinute)
      .toDate();

    this.post.scheduledPublishDate = newDate;
    this.editedFormChange.emit(true);
  }
}
