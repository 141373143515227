<span class="close-icon" [mat-dialog-close]="">
  <mat-icon>close</mat-icon>
</span>
<h2 mat-dialog-title>
  {{ "partner.snConnectionsDialog.letsCheckEverything" | translate }}
</h2>
<mat-dialog-content>
  @if (viewState) {
    <p class="pre-approve__description">
      {{ viewState.description | translate }}
    </p>
    <div class="pre-approve__checks">
      <div class="check" *ngFor="let check of viewState.checks; let i = index">
        <img
          class="check__status-icon"
          [src]="check.statusIconPath"
          alt="check status"
        />
        <div class="check__content">
          <span class="check__title">
            {{ i + 1 }} -
            {{ "partner.snConnectionsDialog." + check.id | translate }}
          </span>
          <div *ngIf="check.$type === 'error'">
            <p class="check__instructions">
              {{ check.instructionsKey | translate }}
            </p>
            <button (click)="onActionLinkPressed(check.id)" class="check__link">
              {{ check.linkKey | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  }
</mat-dialog-content>
<mat-dialog-actions>
  <button [mat-dialog-close]="" class="btn btn--transparent">
    {{ "shared.cancel" | translate }}
  </button>
  <button
    class="btn btn--primary"
    [mat-dialog-close]="true"
    [disabled]="!viewState?.canPreApprove"
  >
    {{ viewState?.submitText | translate }}
  </button>
</mat-dialog-actions>
