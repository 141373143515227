<ng-container *ngIf="!partner.hasConnectedToFacebook">
  <h2 mat-dialog-title>
    {{ "partner.campaign.connectWithFacebook.title" | translate }}
  </h2>
  <mat-dialog-content>
    <p *ngIf="!errorDetails && isReconnect">
      {{ "partner.campaign.connectWithFacebook.reconnectTitle" | translate }}
    </p>
    <p *ngIf="!errorDetails && !isReconnect">
      {{ "partner.campaign.connectWithFacebook.connectTitle" | translate }}
    </p>
    <p *ngIf="errorDetails" [innerHtml]="errorDetails"></p>
  </mat-dialog-content>
</ng-container>

<ng-container
  *ngIf="partner.hasConnectedToFacebook && !partner.isConnectedToInstagram"
>
  <h2 mat-dialog-title>
    {{ "partner.campaign.connectWithInstagram.title" | translate }}
  </h2>
  <mat-dialog-content>
    <p *ngIf="!errorDetails && isReconnect">
      {{ "partner.campaign.connectWithInstagram.reconnectTitle" | translate }}
    </p>
    <p *ngIf="!errorDetails && !isReconnect">
      {{ "partner.campaign.connectWithInstagram.connectTitle" | translate }}
    </p>
    <p *ngIf="errorDetails" [innerHtml]="errorDetails"></p>
  </mat-dialog-content>
</ng-container>

<mat-dialog-actions>
  <button class="btn btn--transparent" [mat-dialog-close]="">
    {{ "partner.campaign.connectWithFacebook.dismiss" | translate }}
  </button>
  <button class="btn btn--primary" (click)="connect()">
    {{ "partner.campaign.connectWithInstagram.connect" | translate }}
  </button>
</mat-dialog-actions>
