import dayjs from "dayjs";

import { PartnerFacebookPostLog } from "../../models/partnerFacebookPostLog";
import { PostPartnerStatus } from "../../enums/campaignPost.enums";

export class UpdateFacebookPostLogData {
  public readonly scheduledPublishDate: number;
  public readonly text: string;
  public readonly willBecomeAd?: boolean;
  public readonly postToFacebook: boolean;
  public readonly postToInstagram: boolean;

  constructor(
    postLog: PartnerFacebookPostLog,
    public readonly partnerStatus?: PostPartnerStatus,
  ) {
    this.scheduledPublishDate = dayjs(postLog.scheduledPublishDate).unix();
    this.text = postLog.text;
    this.willBecomeAd = postLog.willBecomeAd;
    this.postToFacebook = postLog.postToFacebook;
    this.postToInstagram = postLog.postToInstagram;
  }
}
