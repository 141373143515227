<h2 mat-dialog-title>
  {{ "partner.campaign.confirmBudget.title" | translate }}
  <span class="close-icon" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </span>
</h2>
<mat-dialog-content class="content">
  <div class="content-line-one">
    {{ "partner.campaign.confirmBudget.lineOne" | translate }}
  </div>
  <!-- Budget Section -->
  <div class="content-bubble-wrap">
    <div class="content-bubble">
      <img src="../../../../assets/images/view-budget-girl-coin.svg" />
      <div class="content-bubble-item">
        {{ "partner.campaign.confirmBudget.totalBudget" | translate }}
        <span class="content-bubble-item-amount"
          >{{ budget }}{{ campaign.currencySymbol }}</span
        >
      </div>
      <div class="content-bubble-item">
        {{ "partner.campaign.confirmBudget.totalAds" | translate }}
        <span class="content-bubble-item-amount"
          >{{ numberOfAds }} {{ "shared.ads" | translate }}</span
        >
      </div>
      <div class="content-bubble-item">
        {{ "partner.campaign.confirmBudget.maxAds" | translate }}
        <span class="content-bubble-item-amount"
          >{{ adDuration }} {{ "shared.days" | translate }}</span
        >
      </div>
    </div>
  </div>
  <div class="content-confirm-title">
    {{ "partner.campaign.confirmBudget.confirmTitle" | translate }}
  </div>
  <div class="content-confirm-subtitle">
    {{ "partner.campaign.confirmBudget.confirmSubtitle" | translate }}
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button (click)="back()" class="round-button round-button--grey button-left">
    <span>{{ "shared.back" | translate }}</span>
  </button>
  <button
    [disabled]="saving"
    (click)="confirm()"
    class="round-button round-button--orange button-left"
  >
    <span *ngIf="!saving">{{ "shared.ok" | translate }}</span>
    <span *ngIf="saving">{{ "shared.saving" | translate }}</span>
  </button>
</mat-dialog-actions>
