import { Injectable } from "@angular/core";
import { Cloudinary } from "@cloudinary/url-gen";
import { scale } from "@cloudinary/url-gen/actions/resize";
import cloudinaryConfiguration from "../../cloudinary-config";

@Injectable()
export class ImageService {
  cloudinaryInstance: Cloudinary;

  constructor() {
    this.cloudinaryInstance = new Cloudinary({
      cloud: {
        cloudName: cloudinaryConfiguration.cloud_name,
      },
    });
  }

  directDownloadURL(url: any, imageId: string | null = null): string {
    if (!url || url.indexOf("res.cloudinary") === -1) {
      // If image is not from Cloudinary return URL straight up
      return url;
    }
    if (!imageId) {
      imageId = this.getImageId(url);
    }

    // Cloudinary offers a way to force download an image using the 'attachment' flag
    // More info: https://cloudinary.com/documentation/image_transformations#delivery_and_image_format_flags
    return this.cloudinaryInstance
      .image(imageId)
      .addFlag("attachment.lossy")
      .toURL();
  }

  optimizeImage(url: any, imageId: string | null = null): string {
    if (!url || url.indexOf("res.cloudinary") === -1) {
      // If image is not from Cloudinary return URL straight up
      return url;
    }
    if (!imageId) {
      imageId = this.getImageId(url);
    }
    return this.cloudinaryInstance.image(imageId).addFlag("lossy").toURL();
  }

  resizeImage(
    url: any,
    maxWidth: number,
    imageId: string | null = null,
  ): string {
    if (!url || url.indexOf("res.cloudinary") === -1) {
      // If image is not from Cloudinary return URL straight up
      return url;
    }
    if (!imageId) {
      imageId = this.getImageId(url);
    }

    // Cloudinary offers a way to dynamically resize images
    // More info: https://cloudinary.com/documentation/angular_image_transformations
    return this.cloudinaryInstance
      .image(imageId)
      .resize(scale().width(maxWidth))
      .addFlag("lossy")
      .toURL();
  }

  private getImageId(url: string): string {
    return url.slice(url.lastIndexOf("/") + 1);
  }
}
