<button class="close-icon" tabindex="-1" (click)="close()">
  <mat-icon>close</mat-icon>
</button>
<h2 *ngIf="step !== 2" mat-dialog-title class="title-orange">
  {{ "partner.campaign.postAcceptanceDialog.outOfDate" | translate }}
</h2>
<h2 *ngIf="step === 2" mat-dialog-title class="title-black">
  {{ "partner.campaign.postAcceptanceDialog.confirmPosts" | translate }}
</h2>
<div *ngIf="step === 2 && hasMoreThanOneCalendar" class="confirm-posts-warning">
  <img src="/assets/images/as_information.svg" width="16" />
  {{ "partner.campaign.postAcceptanceDialog.publishWarning" | translate }}
</div>
<div class="progress-bar-wrapper" *ngIf="step === 1">
  <span class="progress-percentage"
    >{{ progress + 1 }} of {{ numPosts }} {{ "shared.posts" | translate }}</span
  >
  <span class="progress-bar">
    <span
      class="progress-bar-percentage"
      [ngStyle]="{ width: ((progress + 1) / numPosts) * 100 + '%' }"
    ></span>
  </span>
</div>
<mat-dialog-content *ngIf="step === 1" class="no-scroll-content">
  <div class="dark-layout"></div>
  <div class="container">
    <app-posts-carousel #carousel [showControls]="false">
      <ng-container *ngFor="let post of postsToReschedule">
        <ng-container *postsCarouselItem>
          <div class="posts-acceptance-dialog-item">
            <app-partner-campaign-post-edit
              *ngIf="post.isFacebookPost"
              [campaign]="campaign"
              [campaignDetails]="campaignDetails"
              [post]="post"
            />
            <app-partner-campaign-google-ad-edit
              *ngIf="post.isGoogleAd"
              [showLongMockFormat]="true"
              [campaign]="campaign"
              [campaignDetails]="campaignDetails"
              [post]="post"
            />
          </div>
        </ng-container>
      </ng-container>
    </app-posts-carousel>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="mat-dialog-actions--out-of-date" *ngIf="step === 1">
</mat-dialog-actions>
<mat-dialog-content *ngIf="step === 2">
  <div class="container">
    <div
      *ngFor="let postSummary of postSummaryList"
      [ngClass]="
        postSummary.publishing ? 'post-summary' : 'post-summary no-publish'
      "
    >
      <div class="media">
        <img
          *ngIf="postSummary.post.isFacebookPost"
          class="accepted-post-icon"
          src="/assets/images/facebook-post-logo.svg"
          width="28"
        />
        <img
          *ngIf="postSummary.post.isGoogleAd"
          class="accepted-post-icon"
          src="/assets/images/google-ad-logo-filled.svg"
          width="28"
        />
        <img
          *ngIf="postSummary.post.isLinkedInPost"
          class="accepted-post-icon"
          src="/assets/images/icons/linkedin/ic_linkedin.svg"
          width="28"
        />
        <video
          *ngIf="postSummary.post.mediaType === PostType.Video"
          class="posts-acceptance-item-img"
          src="{{ getFacebookPostLog(postSummary.post).videoUrl }}"
          controls
        ></video>
        <img
          *ngIf="postSummary.post.mediaType !== PostType.Video"
          class="posts-acceptance-item-img"
          src="{{ getPostSummaryImage(postSummary) }}"
        />
      </div>
      <p *ngIf="postSummary.publishing" class="date-of-publication">
        <span class="publishing-text">
          {{ "partner.campaign.postAcceptanceDialog.publishing" | translate }} -
          {{ postSummary.post.scheduledPublishDate | date: "medium" }}
        </span>
        <span *ngIf="postSummary.rescheduled" class="rescheduled">
          ({{
            "partner.campaign.postAcceptanceDialog.rescheduled" | translate
          }})
        </span>
        <span
          class="post-ad-or-dark-post-text"
          *ngIf="
            postSummary.post.isFacebookPost &&
            getFacebookPostLog(postSummary.post).isDarkPost &&
            postSummary.hasPromoAd
          "
        >
          {{ "shared.darkPost" | translate }}
        </span>
        <span class="post-ad-or-dark-post-text" *ngIf="hasPromoAd(postSummary)">
          {{ "shared.promoAd" | translate }}
        </span>
      </p>
      <p
        *ngIf="
          postSummary.post.scheduledPublishDate &&
          postSummary.post.isFacebookPost
        "
        class="text-to-publish"
      >
        {{ getFacebookPostLog(postSummary.post).text }}
      </p>
      <p
        *ngIf="
          postSummary.post.scheduledPublishDate && postSummary.post.isGoogleAd
        "
        class="text-to-publish"
      >
        {{ getGoogleAdPostLog(postSummary.post).descriptionOne }}
      </p>
      <p *ngIf="!postSummary.publishing" class="date-of-publication">
        {{
          "partner.campaign.postAcceptanceDialog.notBeingPublished" | translate
        }}
      </p>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="step === 2">
  <button class="btn btn--transparent button-cancel" [mat-dialog-close]="">
    {{ "shared.cancel" | translate }}
  </button>
  <button
    class="btn btn--primary button-confirm"
    [mat-dialog-close]="acceptedPosts"
  >
    {{ "partner.campaign.postAcceptanceDialog.confirm" | translate }}
  </button>
</mat-dialog-actions>
