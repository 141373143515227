<div class="post-edit-row">
  <app-google-ad-mock-up
    *ngIf="!showLongMockFormat"
    [post]="post"
    [noMinHeight]="true"
    [formatWrapped]="false"
  />
  @if (showDownloadIcon) {
    <button
      class="download-button"
      (click)="download()"
      title="{{ 'shared.download' | translate }}"
    ></button>
  }
  <div *ngIf="showLongMockFormat" class="ad-preview-content">
    <div class="ad-preview-image">
      <img
        class="ad-preview-img"
        src="{{ resizeImage(post.image, post.imagePublicId) }}"
      />
    </div>
    <div class="ad-preview-mock">
      <img
        class="ad-preview-mock-image"
        src="/assets/images/google-ad-placeholder.svg"
      />
    </div>
  </div>
  <div *ngIf="!showLongMockFormat" class="ad-edit-content-divider"></div>
  <div class="ad-edit-content">
    <form [formGroup]="form">
      <div class="step-title">
        <span class="number">1</span>
        <span class="text">
          <span
            [innerHTML]="'googleAd.edit.customizeHeadlines' | translate"
          ></span>
          <span class="up-to-text">
            ({{ "shared.upTo" | translate: { amount: 5 } }})</span
          >
        </span>
        <mat-icon>info</mat-icon>
      </div>
      <div class="ad-fields">
        <mat-form-field>
          <input
            matInput
            maxlength="30"
            type="text"
            formControlName="headlineOne"
            [errorStateMatcher]="customErrorStateMatcher"
          />
          <mat-hint align="end"
            >{{ post.headlineOne ? post.headlineOne?.length : 0 }} / 30
          </mat-hint>
          <mat-error *ngIf="form.hasError('forbiddenChars', 'headlineOne')">{{
            getForbiddenCharErrorMessage()
          }}</mat-error>
          <mat-error *ngIf="form.hasError('twoConsecutiveDots', 'headlineOne')">
            {{ "shared.errorTwoDotsPattern" | translate }}
          </mat-error>
          <mat-error *ngIf="form.hasError('required', 'headlineOne')">
            {{ "shared.requiredField" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="showHeadlineIndex > 1" class="ad-fields">
        <mat-form-field>
          <input
            matInput
            maxlength="30"
            type="text"
            formControlName="headlineTwo"
            [errorStateMatcher]="customErrorStateMatcher"
          />
          <mat-hint align="end"
            >{{ post.headlineTwo ? post.headlineTwo?.length : 0 }} / 30
          </mat-hint>
          <mat-error *ngIf="form.hasError('forbiddenChars', 'headlineTwo')">{{
            getForbiddenCharErrorMessage()
          }}</mat-error>
          <mat-error *ngIf="form.hasError('twoConsecutiveDots', 'headlineTwo')">
            {{ "shared.errorTwoDotsPattern" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="showHeadlineIndex > 2" class="ad-fields">
        <mat-form-field>
          <input
            matInput
            maxlength="30"
            type="text"
            formControlName="headlineThree"
            [errorStateMatcher]="customErrorStateMatcher"
          />
          <mat-hint align="end"
            >{{ post.headlineThree ? post.headlineThree?.length : 0 }} / 30
          </mat-hint>
          <mat-error *ngIf="form.hasError('forbiddenChars', 'headlineThree')">{{
            getForbiddenCharErrorMessage()
          }}</mat-error>
          <mat-error
            *ngIf="form.hasError('twoConsecutiveDots', 'headlineThree')"
          >
            {{ "shared.errorTwoDotsPattern" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="showHeadlineIndex > 3" class="ad-fields">
        <mat-form-field>
          <input
            matInput
            maxlength="30"
            type="text"
            formControlName="headlineFour"
            [errorStateMatcher]="customErrorStateMatcher"
          />
          <mat-hint align="end"
            >{{ post.headlineFour ? post.headlineFour?.length : 0 }} / 30
          </mat-hint>
          <mat-error *ngIf="form.hasError('forbiddenChars', 'headlineFour')">{{
            getForbiddenCharErrorMessage()
          }}</mat-error>
          <mat-error
            *ngIf="form.hasError('twoConsecutiveDots', 'headlineFour')"
          >
            {{ "shared.errorTwoDotsPattern" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="showHeadlineIndex > 4" class="ad-fields">
        <mat-form-field>
          <input
            matInput
            maxlength="30"
            type="text"
            formControlName="headlineFive"
            [errorStateMatcher]="customErrorStateMatcher"
          />
          <mat-hint align="end"
            >{{ post.headlineFive ? post.headlineFive?.length : 0 }} / 30
          </mat-hint>
          <mat-error *ngIf="form.hasError('forbiddenChars', 'headlineFive')">{{
            getForbiddenCharErrorMessage()
          }}</mat-error>
          <mat-error
            *ngIf="form.hasError('twoConsecutiveDots', 'headlineFive')"
          >
            {{ "shared.errorTwoDotsPattern" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div
        class="add-link"
        *ngIf="showHeadlineIndex < 5"
        (click)="showNextHeadline()"
      >
        {{ "googleAdEditor.googleAdAddHeadline" | translate }}
      </div>
      <div class="step-title">
        <span class="number">2</span>
        <span class="text">
          <span
            [innerHTML]="'googleAd.edit.customizeLongHeadline' | translate"
          ></span>
        </span>
        <mat-icon>info</mat-icon>
      </div>
      <div class="ad-fields">
        <mat-form-field>
          <input
            matInput
            maxlength="90"
            type="text"
            formControlName="longHeadline"
            [errorStateMatcher]="customErrorStateMatcher"
          />
          <mat-hint align="end"
            >{{ post.longHeadline ? post.longHeadline?.length : 0 }} / 90
          </mat-hint>
          <mat-error *ngIf="form.hasError('forbiddenChars', 'longHeadline')">{{
            getForbiddenCharErrorMessage()
          }}</mat-error>
          <mat-error
            *ngIf="form.hasError('twoConsecutiveDots', 'longHeadline')"
          >
            {{ "shared.errorTwoDotsPattern" | translate }}
          </mat-error>
          <mat-error *ngIf="form.hasError('required', 'longHeadline')">
            {{ "shared.requiredField" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="step-title">
        <span class="number">3</span>
        <span class="text">
          <span
            [innerHTML]="'googleAd.edit.customizeDescriptions' | translate"
          ></span>
          <span class="up-to-text">
            ({{ "shared.upTo" | translate: { amount: 5 } }})</span
          >
        </span>
        <mat-icon>info</mat-icon>
      </div>
      <div class="ad-fields">
        <mat-form-field>
          <input
            matInput
            maxlength="90"
            type="text"
            formControlName="descriptionOne"
            [errorStateMatcher]="customErrorStateMatcher"
          />
          <mat-hint align="end"
            >{{ post.descriptionOne ? post.descriptionOne?.length : 0 }} / 90
          </mat-hint>
          <mat-error
            *ngIf="form.hasError('forbiddenChars', 'descriptionOne')"
            >{{ getForbiddenCharErrorMessage() }}</mat-error
          >
          <mat-error
            *ngIf="form.hasError('twoConsecutiveDots', 'descriptionOne')"
          >
            {{ "shared.errorTwoDotsPattern" | translate }}
          </mat-error>
          <mat-error *ngIf="form.hasError('required', 'descriptionOne')">
            {{ "shared.requiredField" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="showDescriptionIndex > 1" class="ad-fields">
        <mat-form-field>
          <input
            matInput
            maxlength="90"
            type="text"
            formControlName="descriptionTwo"
            [errorStateMatcher]="customErrorStateMatcher"
          />
          <mat-hint align="end"
            >{{ post.descriptionTwo ? post.descriptionTwo?.length : 0 }} / 90
          </mat-hint>
          <mat-error
            *ngIf="form.hasError('forbiddenChars', 'descriptionTwo')"
            >{{ getForbiddenCharErrorMessage() }}</mat-error
          >
          <mat-error
            *ngIf="form.hasError('twoConsecutiveDots', 'descriptionTwo')"
          >
            {{ "shared.errorTwoDotsPattern" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="showDescriptionIndex > 2" class="ad-fields">
        <mat-form-field>
          <input
            matInput
            maxlength="90"
            type="text"
            formControlName="descriptionThree"
            [errorStateMatcher]="customErrorStateMatcher"
          />
          <mat-hint align="end"
            >{{ post.descriptionThree ? post.descriptionThree?.length : 0 }} /
            90
          </mat-hint>
          <mat-error
            *ngIf="form.hasError('forbiddenChars', 'descriptionThree')"
            >{{ getForbiddenCharErrorMessage() }}</mat-error
          >
          <mat-error
            *ngIf="form.hasError('twoConsecutiveDots', 'descriptionThree')"
          >
            {{ "shared.errorTwoDotsPattern" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="showDescriptionIndex > 3" class="ad-fields">
        <mat-form-field>
          <input
            matInput
            maxlength="90"
            type="text"
            formControlName="descriptionFour"
            [errorStateMatcher]="customErrorStateMatcher"
          />
          <mat-hint align="end"
            >{{ post.descriptionFour ? post.descriptionFour?.length : 0 }} / 90
          </mat-hint>
          <mat-error
            *ngIf="form.hasError('forbiddenChars', 'descriptionFour')"
            >{{ getForbiddenCharErrorMessage() }}</mat-error
          >
          <mat-error
            *ngIf="form.hasError('twoConsecutiveDots', 'descriptionFour')"
          >
            {{ "shared.errorTwoDotsPattern" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="showDescriptionIndex > 4" class="ad-fields">
        <mat-form-field>
          <input
            matInput
            maxlength="90"
            type="text"
            formControlName="descriptionFive"
            [errorStateMatcher]="customErrorStateMatcher"
          />
          <mat-hint align="end"
            >{{ post.descriptionFive ? post.descriptionFive?.length : 0 }} / 90
          </mat-hint>
          <mat-error
            *ngIf="form.hasError('forbiddenChars', 'descriptionFive')"
            >{{ getForbiddenCharErrorMessage() }}</mat-error
          >
          <mat-error
            *ngIf="form.hasError('twoConsecutiveDots', 'descriptionFive')"
          >
            {{ "shared.errorTwoDotsPattern" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div
        class="add-link"
        *ngIf="showDescriptionIndex < 5"
        (click)="showNextDescription()"
      >
        {{ "googleAdEditor.googleAdAddDescription" | translate }}
      </div>
    </form>
  </div>
</div>
