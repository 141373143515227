<div class="dialog-wrapper">
  <h2 mat-dialog-title>
    {{ "partner.campaign.shortUrlDialog.title" | translate }}
  </h2>
  <mat-dialog-content>
    <p class="title">
      {{ "partner.campaign.shortUrlDialog.partnerCampaignLink" | translate }}
    </p>
    <form [formGroup]="form">
      <div class="row">
        <div class="col width-30">
          <mat-form-field class="mo-auto-width">
            <mat-select formControlName="urlPrefix" required>
              <mat-option
                *ngFor="let urlPrefix of UrlPrefixes"
                [value]="urlPrefix"
              >
                {{ urlPrefix }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col width-70">
          <mat-form-field class="mo-auto-width">
            <mat-label>{{
              "partner.campaign.shortUrlDialog.partnerCampaignLinkPlaceholder"
                | translate
            }}</mat-label>
            <input
              matInput
              formControlName="url"
              name="shortUrl"
              pattern="{{ URL_VALIDATION_PATTERN }}"
            />
            @if (form.hasError("pattern", "url")) {
              <mat-error>{{ "shared.enterValidUrl" | translate }}</mat-error>
            }
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="btn btn--light-grey" [disabled]="saving" (click)="cancel()">
      {{ "shared.cancel" | translate }}
    </button>
    <button
      class="btn btn--primary"
      (click)="updateCampaignPartner()"
      [disabled]="saving || !form.valid"
    >
      <span *ngIf="saving">{{ "shared.saving" | translate }}</span>
      <span *ngIf="!saving">{{ "shared.save" | translate }}</span>
    </button>
  </mat-dialog-actions>
</div>
