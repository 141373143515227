import { ViewState } from "../../../../harmony/core";
import { PartnerCampaign } from "../../../shared/models/partnerCampaign";

export enum CheckId {
  GoogleConnection = "googleConnection",
  FbConnection = "fbConnection",
  IgConnection = "igConnection",
  LinkedInConnection = "linkedInConnection",
  FbAdsPermissions = "fbAdsPermissions",
  ShortUrlExists = "shortUrlExists",
}

type CheckViewState = CheckErrorViewState | CheckSuccessViewState;

class CheckErrorViewState implements ViewState {
  public readonly $type = "error";
  public readonly statusIconPath = "/assets/images/icons/ic_check_fail.svg";
  public readonly instructionsKey: string;
  public readonly linkKey: string;

  constructor(public readonly id: CheckId) {
    switch (id) {
      case CheckId.FbConnection:
      case CheckId.IgConnection:
      case CheckId.GoogleConnection:
      case CheckId.LinkedInConnection:
        this.instructionsKey = "partner.snConnectionsDialog.calendarNeed";
        this.linkKey = `partner.snConnectionsDialog.connectTo.${this.id}`;
        break;
      case CheckId.FbAdsPermissions:
        this.instructionsKey = "partner.snConnectionsDialog.authorizeInfo";
        this.linkKey = "partner.snConnectionsDialog.learnHow";
        break;
      case CheckId.ShortUrlExists:
        this.instructionsKey =
          "partner.campaign.contentCalendar.setupDialog.shortUrlInvalid";
        this.linkKey =
          "partner.campaign.contentCalendar.setupDialog.openShortUrlDialog";
        break;
    }
  }
}

class CheckSuccessViewState implements ViewState {
  public readonly $type = "success";
  public readonly statusIconPath = "/assets/images/icons/ic_check_pass.svg";

  constructor(public readonly id: CheckId) {}
}

export class SnConnectionsViewState {
  public readonly checks: CheckViewState[];
  public readonly submitText: string;
  public readonly description: string;

  constructor(
    context: "calendar" | "pre-approve",
    campaign: PartnerCampaign,
    isGAdsConnected: boolean,
    isFbConnected: boolean,
    isIgConnected: boolean,
    isFbAdsAuthorized: boolean,
    isLinkedInConnected: boolean,
    private readonly thirdPartyConnections?: CheckId[],
  ) {
    if (context === "pre-approve") {
      this.submitText = "shared.ok";
      this.description = "partner.snConnectionsDialog.preApproveDescription";
    } else {
      this.submitText =
        "partner.campaign.contentCalendar.setupDialog.scheduleAllPosts";
      this.description = "partner.snConnectionsDialog.calendarDescription";
    }

    this.checks = [
      ...(this.isCheckVisible(CheckId.GoogleConnection)
        ? [this.createCheckViewState(CheckId.GoogleConnection, isGAdsConnected)]
        : []),
      ...(this.isCheckVisible(CheckId.FbConnection)
        ? [this.createCheckViewState(CheckId.FbConnection, isFbConnected)]
        : []),
      ...(this.isCheckVisible(CheckId.IgConnection)
        ? [this.createCheckViewState(CheckId.IgConnection, isIgConnected)]
        : []),
      ...(this.isCheckVisible(CheckId.LinkedInConnection)
        ? [
            this.createCheckViewState(
              CheckId.LinkedInConnection,
              isLinkedInConnected,
            ),
          ]
        : []),
    ];

    if (campaign.hasLandingPageRetailerOwnLink) {
      this.checks.push(
        this.createCheckViewState(
          CheckId.ShortUrlExists,
          !campaign.partner.isShortUrlMissing,
        ),
      );
    }

    if (isFbConnected && this.isCheckVisible(CheckId.FbAdsPermissions)) {
      this.checks.push(
        this.createCheckViewState(CheckId.FbAdsPermissions, isFbAdsAuthorized),
      );
    }
  }

  private isCheckVisible(id: CheckId): boolean {
    return (
      !this.thirdPartyConnections || this.thirdPartyConnections.includes(id)
    );
  }

  protected createCheckViewState(
    id: CheckId,
    connected: boolean,
  ): CheckViewState {
    return connected
      ? new CheckSuccessViewState(id)
      : new CheckErrorViewState(id);
  }

  public get canPreApprove(): boolean {
    return !!this.checks.find(
      (check) => check instanceof CheckSuccessViewState,
    );
  }
}
