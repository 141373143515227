<span class="close-icon" [mat-dialog-close]="" *ngIf="noResultsFound">
  <mat-icon>close</mat-icon>
</span>
<h2 mat-dialog-title>{{ "linkedinConnectDialog.title" | translate }}</h2>
<mat-dialog-content [ngClass]="{ loading: isLoading }">
  <p>{{ "linkedinConnectDialog.subtitle" | translate }}</p>
  <mat-form-field class="full-width">
    <mat-label>{{
      "linkedinConnectDialog.linkedinOrganization" | translate
    }}</mat-label>
    <mat-select [(ngModel)]="selectedOrganization">
      <mat-option *ngFor="let org of organizations" [value]="org">
        {{ org.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <p
    class="linkedin-connect-feedback text-dark-grey text-small"
    *ngIf="isLoading"
  >
    <img
      src="../../../../assets/images/icons/ic_loader.svg"
      class="icon spin-loader"
    />
    {{ "linkedinConnectDialog.loadingText" | translate }}
  </p>
  <p
    class="linkedin-connect-feedback text-dark-grey text-small"
    *ngIf="noResultsFound"
  >
    {{ "linkedinConnectDialog.noResults" | translate }}
  </p>
  <app-loader
    [text]="'linkedinConnectDialog.connectingLoader' | translate"
    [ngClass]="{ visible: isLoading }"
  >
  </app-loader>
  <div *ngIf="isSaving" class="linkedin-connect-bar">
    <span class="connecting-to-linkedin">
      {{ "linkedinConnectDialog.connecting" | translate }}
    </span>
    <mat-progress-bar
      style="margin-top: 10px; margin-bottom: 5px"
      [color]="'accent'"
      mode="indeterminate"
    >
    </mat-progress-bar>
    <span class="connecting-to-linkedin-subtitle">
      {{ "linkedinConnectDialog.connectingPatient" | translate }}
    </span>
  </div>
</mat-dialog-content>
<mat-dialog-actions [ngClass]="{ loading: isLoading }">
  <button
    class="btn btn--primary btn--full-width"
    [disabled]="isLoading || !selectedOrganization"
    (click)="onActionSelectOrganization()"
  >
    {{ "shared.save" | translate }}
  </button>
</mat-dialog-actions>
