import { Injectable } from "@angular/core";
import { CommonDialogService } from "../shared/services/common-dialog.service";
import {
  LeaveDialogComponent,
  LeaveDialogData,
  LeaveDialogResult,
} from "./partner-profile/edit-partner/leave-dialog/leave-dialog.component";
import {
  ChooseAddressDialogComponent,
  ChooseAddressDialogData,
  ChooseAddressDialogResult,
} from "./choose-address-dialog/choose-address-dialog.component";
import {
  PartnerAccountAccessRequestDialogComponent,
  PartnerAccountAccessRequestDialogData,
  PartnerAccountAccessRequestDialogResult,
} from "./partner-account-access-request/partner-account-access-request-dialog.component";
import {
  PartnerCampaignPublishPostDialogData,
  PartnerCampaignPublishPostDialogResult,
  PartnerCampaignPublishPostDialogComponent,
} from "./partner-campaign-publish-post-dialog/partner-campaign-publish-post-dialog.component";
import {
  ContentPublishedDialogData,
  ContentPublishedDialogResult,
  ContentPublishedDialogComponent,
} from "./partner-campaign-content-published-dialog/content-published-dialog.component";
import {
  PartnerCampaignInvalidShortUrlDialogData,
  PartnerCampaignInvalidShortUrlDialogResult,
  PartnerCampaignInvalidShortUrlDialogComponent,
} from "./partner-campaign-invalid-short-url-dialog/partner-campaign-invalid-short-url-dialog.component";
import {
  DeletePostDialogData,
  DeletePostDialogResult,
  DeletePostDialogComponent,
} from "./partner-campaign/delete-post-dialog/delete-post-dialog.component";
import {
  PartnerCampaignBudgetEditDialogData,
  PartnerCampaignBudgetEditDialogResult,
  PartnerCampaignBudgetEditDialogComponent,
} from "./partner-campaign/partner-campaign-budget-edit-dialog/partner-campaign-budget-edit-dialog.component";
import {
  CampaignPostsCheckDialogData,
  CampaignPostsCheckDialogResult,
  CampaignPostsCheckDialogComponent,
} from "./partner-campaign/partner-campaign-content-calendar/campaign-posts-check-dialog/campaign-posts-check-dialog.component";
import {
  PostAcceptanceDialogData,
  PostAcceptanceDialogResult,
  PostAcceptanceDialogComponent,
} from "./partner-campaign/partner-campaign-content-calendar/post-acceptance-dialog/post-acceptance-dialog.component";
import {
  CalendarSetupStartDialogData,
  CalendarSetupStartDialogResult,
  CalendarSetupStartDialogComponent,
} from "./partner-campaign/partner-campaign-content-calendar/setup-start-dialog/setup-start-dialog.component";
import {
  PartnerCampaignListAddConnectionDialogData,
  PartnerCampaignListAddConnectionDialogResult,
  PartnerCampaignListAddConnectionDialogComponent,
} from "./partner-campaign/partner-campaign-list/partner-campaign-list-add-connection-dialog/partner-campaign-list-add-connection-dialog.component";
import {
  PartnerCampaignMissingUrlDialogData,
  PartnerCampaignMissingUrlDialogResult,
  PartnerCampaignMissingUrlDialogComponent,
} from "./partner-campaign/partner-campaign-missing-url-dialog/partner-campaign-missing-url-dialog.component";
import {
  PartnerCampaignPreApproveDialogData,
  PartnerCampaignPreApproveDialogResult,
  PartnerCampaignPreApproveDialogComponent,
} from "./partner-campaign/partner-campaign-pre-approve-dialog/partner-campaign-pre-approve-dialog.component";
import {
  PartnerCheckThirdPartyConnectionsDialogData,
  PartnerCheckThirdPartyConnectionsDialogResult,
  PartnerCheckThirdPartyConnectionsDialogComponent,
} from "./partner-campaign/partner-check-third-party-connections-dialog/partner-check-third-party-connections-dialog.component";
import {
  SchedulePostDialogData,
  SchedulePostDialogResult,
  SchedulePostDialogComponent,
} from "./partner-campaign/schedule-post-dialog/schedule-post-dialog.component";
import {
  FacebookNotConnectedDialogData,
  FacebookNotConnectedDialogResult,
  FacebookNotConnectedDialogComponent,
} from "./third-party-connection-dialogs/facebook-not-connected-dialog/facebook-not-connected-dialog.component";
import {
  LinkedInNotConnectedDialogData,
  LinkedInNotConnectedDialogResult,
  LinkedInNotConnectedDialogComponent,
} from "./third-party-connection-dialogs/linkedin-not-connected-dialog/linkedin-not-connected-dialog.component";
import {
  LinkedinOrganizationConnectDialogData,
  LinkedinOrganizationConnectDialogResult,
  LinkedinOrganizationConnectDialogComponent,
} from "./third-party-connection-dialogs/linkedin-organization-connect-dialog/linkedin-organization-connect-dialog.component";
import {
  PartnerAuthorizeFacebookAdsDialogData,
  PartnerAuthorizeFacebookAdsDialogResult,
  PartnerAuthorizeFacebookAdsDialogComponent,
} from "./third-party-connection-dialogs/partner-authorize-facebook-ads-dialog/partner-authorize-facebook-ads-dialog.component";
import {
  PartnerGoogleAdsConnectionDialogData,
  PartnerGoogleAdsConnectionDialogResult,
  PartnerGoogleAdsConnectionDialogComponent,
} from "./third-party-connection-dialogs/partner-google-ads-connection-dialog/partner-google-ads-connection-dialog.component";
import {
  LinkedinDisconnectDialogData,
  LinkedinDisconnectDialogResult,
  LinkedinDisconnectDialogComponent,
} from "./third-party-connection-dialogs/third-party-disconnect-dialogs/linkedin-disconnect-dialog/linkedin-disconnect-dialog.component";

@Injectable()
export class PartnerDialogService {
  constructor(private dialog: CommonDialogService) {}

  public readonly showChooseAddress = this.dialog.createOpenDialogFn<
    ChooseAddressDialogData,
    ChooseAddressDialogResult
  >(ChooseAddressDialogComponent, {
    panelClass: ["sp-choose-address"],
  });

  public readonly showLeave = this.dialog.createOpenDialogFn<
    LeaveDialogData,
    LeaveDialogResult
  >(LeaveDialogComponent);

  public readonly showPartnerAccountAccessRequest =
    this.dialog.createOpenDialogFn<
      PartnerAccountAccessRequestDialogData,
      PartnerAccountAccessRequestDialogResult
    >(PartnerAccountAccessRequestDialogComponent, {
      disableClose: true,
      panelClass: ["sp-partner-account-access-request"],
    });

  public readonly showPartnerCampaignPublishPost =
    this.dialog.createOpenDialogFn<
      PartnerCampaignPublishPostDialogData,
      PartnerCampaignPublishPostDialogResult
    >(PartnerCampaignPublishPostDialogComponent, {
      disableClose: true,
      panelClass: ["sp-partner-campaign-publish-post"],
    });

  public readonly showPreApprove = this.dialog.createOpenDialogFn<
    PartnerCampaignPreApproveDialogData,
    PartnerCampaignPreApproveDialogResult
  >(PartnerCampaignPreApproveDialogComponent);

  public readonly showThirdPartyConnectionsCheck =
    this.dialog.createOpenDialogFn<
      PartnerCheckThirdPartyConnectionsDialogData,
      PartnerCheckThirdPartyConnectionsDialogResult
    >(PartnerCheckThirdPartyConnectionsDialogComponent);

  public readonly showPartnerAuthorizeFacebookAds =
    this.dialog.createOpenDialogFn<
      PartnerAuthorizeFacebookAdsDialogData,
      PartnerAuthorizeFacebookAdsDialogResult
    >(PartnerAuthorizeFacebookAdsDialogComponent, {
      panelClass: ["sp-authorize-fb-ads"],
    });

  public readonly showStartSetupCalendar = this.dialog.createOpenDialogFn<
    CalendarSetupStartDialogData,
    CalendarSetupStartDialogResult
  >(CalendarSetupStartDialogComponent, {
    disableClose: true,
  });

  public readonly showFacebookNotConnected = this.dialog.createOpenDialogFn<
    FacebookNotConnectedDialogData,
    FacebookNotConnectedDialogResult
  >(FacebookNotConnectedDialogComponent);

  public readonly showInvalidCampaignShortUrl = this.dialog.createOpenDialogFn<
    PartnerCampaignInvalidShortUrlDialogData,
    PartnerCampaignInvalidShortUrlDialogResult
  >(PartnerCampaignInvalidShortUrlDialogComponent);

  public readonly showPostAcceptance = this.dialog.createOpenDialogFn<
    PostAcceptanceDialogData,
    PostAcceptanceDialogResult
  >(PostAcceptanceDialogComponent, {
    panelClass: ["sp-post-acceptance", "no-padding-dialog"],
    disableClose: true,
  });

  public readonly showPartnerCampaignListAddConnection =
    this.dialog.createOpenDialogFn<
      PartnerCampaignListAddConnectionDialogData,
      PartnerCampaignListAddConnectionDialogResult
    >(PartnerCampaignListAddConnectionDialogComponent, {
      panelClass: ["sp-campaign-list-add-connection"],
      disableClose: true,
    });

  public readonly showCampaignPostsCheck = this.dialog.createOpenDialogFn<
    CampaignPostsCheckDialogData,
    CampaignPostsCheckDialogResult
  >(CampaignPostsCheckDialogComponent);

  public readonly showGoogleAdsConnection = this.dialog.createOpenDialogFn<
    PartnerGoogleAdsConnectionDialogData,
    PartnerGoogleAdsConnectionDialogResult
  >(PartnerGoogleAdsConnectionDialogComponent, {
    panelClass: ["sp-google-ads-connection"],
  });

  public readonly showPartnerCampaignMissingUrl =
    this.dialog.createOpenDialogFn<
      PartnerCampaignMissingUrlDialogData,
      PartnerCampaignMissingUrlDialogResult
    >(PartnerCampaignMissingUrlDialogComponent, {
      panelClass: ["sp-google-short-url"],
      disableClose: true,
    });

  public readonly showLinkedinOrganizationsConnection =
    this.dialog.createOpenDialogFn<
      LinkedinOrganizationConnectDialogData,
      LinkedinOrganizationConnectDialogResult
    >(LinkedinOrganizationConnectDialogComponent, {
      disableClose: true,
    });

  public readonly showLinkedInDisconnect = this.dialog.createOpenDialogFn<
    LinkedinDisconnectDialogData,
    LinkedinDisconnectDialogResult
  >(LinkedinDisconnectDialogComponent, {
    panelClass: ["sp-linkedin-disconnect"],
    disableClose: true,
  });

  public readonly showLinkedInNotConnected = this.dialog.createOpenDialogFn<
    LinkedInNotConnectedDialogData,
    LinkedInNotConnectedDialogResult
  >(LinkedInNotConnectedDialogComponent, {
    disableClose: true,
  });

  public readonly showContentPublished = this.dialog.createOpenDialogFn<
    ContentPublishedDialogData,
    ContentPublishedDialogResult
  >(ContentPublishedDialogComponent, {
    disableClose: true,
  });

  public readonly showPartnerCampaignBudgetEdit =
    this.dialog.createOpenDialogFn<
      PartnerCampaignBudgetEditDialogData,
      PartnerCampaignBudgetEditDialogResult
    >(PartnerCampaignBudgetEditDialogComponent, {
      panelClass: ["sp-partner-campaign-budget-edit"],
      disableClose: true,
    });

  public readonly showSchedulePost = this.dialog.createOpenDialogFn<
    SchedulePostDialogData,
    SchedulePostDialogResult
  >(SchedulePostDialogComponent, {
    panelClass: ["sp-schedule-post"],
    disableClose: true,
  });

  public readonly showDeletePost = this.dialog.createOpenDialogFn<
    DeletePostDialogData,
    DeletePostDialogResult
  >(DeletePostDialogComponent, {
    disableClose: true,
  });
}
