import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface PartnerAccountAccessRequestDialogData {
  userEmail: string;
}

export type PartnerAccountAccessRequestDialogResult = true;

@Component({
  selector: "app-partner-account-access-request-dialog",
  templateUrl: "./partner-account-access-request-dialog.component.html",
  styleUrls: ["./partner-account-access-request-dialog.component.scss"],
})
export class PartnerAccountAccessRequestDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    protected readonly data: PartnerAccountAccessRequestDialogData,
  ) {}
}
