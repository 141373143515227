import dayjs from "dayjs";
import { PartnerCampaignFacebookPost } from "../../models/partnerCampaignFacebookPost";
import { PartnerCampaignLinkedInPost } from "../../models/partnerCampaignLinkedInPost";

export class CreatePostLogFromPostData {
  public readonly postId: number;
  public readonly text: string;
  public readonly scheduledPublishDate: number;
  public readonly willBecomeAd = false;

  constructor(
    post: PartnerCampaignFacebookPost | PartnerCampaignLinkedInPost,
    scheduledPublishDate: Date,
    public readonly postToFacebook: boolean = false,
    public readonly postToInstagram: boolean = false,
  ) {
    this.postId = post.id;
    this.text = post.text;
    this.scheduledPublishDate = dayjs(scheduledPublishDate).unix();
  }
}
