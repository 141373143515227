import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { PartnerCampaignFacebookPost } from "../../../shared/models/partnerCampaignFacebookPost";
import { PartnerCampaignLinkedInPost } from "../../../shared/models/partnerCampaignLinkedInPost";
import { PartnerFacebookPostLog } from "../../../shared/models/partnerFacebookPostLog";
import { PartnerLinkedInPostLog } from "../../../shared/models/partnerLinkedInPostLog";
import { PostLog } from "../../../shared/models/postLog";
import { PartnerCampaignService } from "../../../shared/services/api/partner-campaign.service";

export type PostTypeWithSuggestedText =
  | PartnerCampaignFacebookPost
  | PartnerCampaignLinkedInPost
  | PartnerFacebookPostLog
  | PartnerLinkedInPostLog;

@Component({
  selector: "app-partner-post-suggested-text-editor",
  templateUrl: "./partner-post-suggested-text-editor.component.html",
  styleUrls: ["./partner-post-suggested-text-editor.component.scss"],
})
export class PartnerPostSuggestedTextEditorComponent
  implements OnInit, OnDestroy
{
  public loading = false;
  public campaign?: PartnerCampaign;
  public postCharacters = 0;
  public suggestedText = "";
  public textHasChanged = false;
  @Input() public bigHeading = false;
  @Input() public post!: PostTypeWithSuggestedText;
  @Input() public showUseSuggestedText = true;
  @Input() public showSuggestedText = true;
  @Input() public showBulletPoint = true;
  private readonly subscription = new Subscription();

  constructor(public readonly partnerCampaignService: PartnerCampaignService) {}

  public ngOnInit(): void {
    this.loading = true;

    this.subscription.add(
      this.partnerCampaignService
        .getCurrentPartnerCampaign()
        .subscribe((campaign?: PartnerCampaign) => {
          if (campaign) {
            this.initData(campaign);
          }
        }),
    );

    this.subscription.add(
      this.partnerCampaignService.partnerCampaignUpdated.subscribe(
        (campaign: PartnerCampaign) => {
          this.initData(campaign);
        },
      ),
    );

    this.countPostCharacters(this.post.text);
  }

  public initData(campaign: PartnerCampaign) {
    this.campaign = campaign;
    this.loading = false;

    if (this.post instanceof PostLog) {
      const postLog = this.post as PartnerFacebookPostLog;
      if (postLog.calendarPost && postLog.calendarPost.suggestedText) {
        this.suggestedText = postLog.calendarPost.suggestedText;
      }
    } else {
      this.suggestedText = this.post.text;
    }

    if (this.campaign!.partner && this.campaign!.partner.postsAdditionalText) {
      this.suggestedText += "\n" + this.campaign.partner.postsAdditionalText;
    }
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public useSuggestedText(post: PostTypeWithSuggestedText): void {
    post.text = this.suggestedText;
    this.textHasChanged = false;
    this.countPostCharacters(post.text);
  }

  private countPostCharacters(postText?: string): void {
    this.postCharacters = postText ? postText.length : 0;
  }

  public onTextEdit(postText: string): void {
    this.countPostCharacters(postText);
    this.textHasChanged = true;
  }
}
