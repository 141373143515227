import { Injectable } from "@angular/core";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { ApiRequestService } from "../../../../harmony/core";
import { ApiEndpoint } from "../../../features/api-endpoint";
import { LinkedInOrganization } from "../../models/linkedIn-organization";

@Injectable()
export class LinkedInService {
  public readonly $partnerLinkedinOrganization = new BehaviorSubject<
    LinkedInOrganization | undefined
  >(undefined);

  constructor(private readonly requestService: ApiRequestService) {}

  public getAccessUrl(partnerId: number): Promise<string> {
    return lastValueFrom(
      this.requestService
        .builder<string>(ApiEndpoint.LinkedInGetAccessUrl)
        .setQueryParameters({
          partnerId: partnerId,
        })
        .get(),
    ) as Promise<string>;
  }

  public getOrganizations(partnerId: number): Promise<LinkedInOrganization[]> {
    return lastValueFrom(
      this.requestService
        .builder<LinkedInOrganization[]>(ApiEndpoint.LinkedInGetOrgs)
        .setQueryParameters({
          partnerId: partnerId,
        })
        .get(),
    ) as Promise<LinkedInOrganization[]>;
  }

  public requestPartnerAccess(
    partnerId: number,
    code: string,
    state: string,
  ): Promise<void> {
    return lastValueFrom(
      this.requestService
        .builder<void>(ApiEndpoint.LinkedInAskForAccess)
        .setQueryParameters({
          partnerId: partnerId,
          linkedinCode: code,
          linkedinState: state,
        })
        .get(),
    );
  }

  public async setPartnerOrganization(
    partnerId: number,
    org: LinkedInOrganization,
  ): Promise<void> {
    await lastValueFrom(
      this.requestService
        .builder<void>(ApiEndpoint.LinkedInSetOrg)
        .setBody({
          partnerId: partnerId,
          linkedinOrganizationUrn: org.urn,
          linkedinOrganizationName: org.name,
        })
        .post(),
    );

    this.$partnerLinkedinOrganization.next(org);
  }

  public disconnect(partnerId: number): Promise<void> {
    return lastValueFrom(
      this.requestService
        .builder<void>(ApiEndpoint.LinkedInDisconnect)
        .setQueryParameters({
          partnerId: partnerId,
        })
        .delete(),
    );
  }
}
