import { Component, Inject } from "@angular/core";
import { DatePipe } from "@angular/common";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { PartnerCampaignBudgetConfirmDialogData } from "../partner-campaign-budget-confirm-dialog/partner-campaign-budget-confirm-dialog-data";
import { PartnerCampaignBudgetConfirmDialogComponent } from "../partner-campaign-budget-confirm-dialog/partner-campaign-budget-confirm-dialog.component";
import { DateService } from "../../../shared/services/date.service";

export interface PartnerCampaignBudgetEditDialogData {
  readonly campaign: PartnerCampaign;
  readonly budget?: number;
  readonly adDuration?: number;
  readonly numberOfAds?: number;
}

export type PartnerCampaignBudgetEditDialogResult =
  MatDialogRef<PartnerCampaignBudgetConfirmDialogComponent>;

@Component({
  selector: "app-partner-campaign-budget-edit-dialog",
  templateUrl: "./partner-campaign-budget-edit-dialog.component.html",
  styleUrl: "./partner-campaign-budget-edit-dialog.component.scss",
})
export class PartnerCampaignBudgetEditDialogComponent {
  protected initialBudgetLeft = 0;
  protected adjustedBudgetLeft = 0;
  protected maxAdDuration = 30;
  protected maxNumberOfAds = 50;
  protected budget = 0;
  protected adDuration = 0;
  protected numberOfAds = 0;
  protected campaign: PartnerCampaign;
  protected saving = false;
  protected disableBudgetSlider = false;

  constructor(
    private readonly dateService: DateService,
    private readonly dialog: MatDialog,
    private readonly dialogRef: MatDialogRef<
      PartnerCampaignBudgetEditDialogComponent,
      PartnerCampaignBudgetEditDialogResult
    >,
    @Inject(MAT_DIALOG_DATA) public data: PartnerCampaignBudgetEditDialogData,
    private readonly datePipe: DatePipe,
  ) {
    this.campaign = data.campaign;
    if (data.budget !== undefined) {
      this.budget = data.budget;
    } else if (this.campaign.hasDefinedCustomBudget) {
      this.budget = this.campaign.customBudgetAmount;
    } else if (
      this.campaign.budget < this.campaign.totalPartnerAvailableBudget
    ) {
      this.budget = this.campaign.budget;
    } else {
      this.budget = this.campaign.totalPartnerAvailableBudget;
    }
    if (data.numberOfAds !== undefined) {
      this.numberOfAds = data.numberOfAds;
    } else if (this.campaign.customNumberOfAds > 0) {
      this.numberOfAds = this.campaign.customNumberOfAds;
    } else {
      this.numberOfAds = this.campaign.maxAdsCampaignPartner;
    }
    if (data.adDuration !== undefined) {
      this.adDuration = data.adDuration;
    } else if (this.campaign.customAdDuration > 0) {
      this.adDuration = this.campaign.customAdDuration;
    } else {
      this.adDuration = this.campaign.adDuration;
    }
    this.initialBudgetLeft = this.campaign.totalPartnerAvailableBudget;
    this.adjustedBudgetLeft = this.campaign.totalPartnerAvailableBudget;
    this.calculateBudgetFeedback();
    this.disableBudgetSlider =
      !this.campaign.canDefineCustomBudget || this.initialBudgetLeft === 0;
  }

  protected sliderChange(): void {
    this.calculateBudgetFeedback();
  }

  protected isSaveDisabled(): boolean {
    return (
      this.saving ||
      !this.campaign.canDefineCustomBudget ||
      this.budget === 0 ||
      this.adDuration === 0 ||
      this.numberOfAds === 0
    );
  }

  protected editBudgetConfirm(): void {
    const dialogRef = this.dialog.open(
      PartnerCampaignBudgetConfirmDialogComponent,
      {
        width: "536px",
        data: new PartnerCampaignBudgetConfirmDialogData(
          this.campaign,
          this.budget,
          this.adDuration,
          this.numberOfAds,
        ),
        disableClose: true,
      },
    );

    this.dialogRef.close(dialogRef);
  }

  private calculateBudgetFeedback(): void {
    this.adjustedBudgetLeft = Math.max(this.initialBudgetLeft - this.budget, 0);
  }

  protected getFormattedBudgetAvailableUntilDate(): string {
    return this.campaign.budgetAvailableUntilDate
      ? this.datePipe.transform(
          this.campaign.budgetAvailableUntilDate,
          this.dateService.getDateFormat(),
        ) ?? ""
      : "";
  }
}
