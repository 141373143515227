import { Injectable } from "@angular/core";
import { PartnerService } from "../../../../shared/services/api/partner.service";
import { HttpClient } from "../../../../shared/services/http-client";

export interface ICampaignContentDownload {
  postId?: number;
  postLogId?: number;
  imageFileId?: number;
  videoId?: number;
}

@Injectable({ providedIn: "root" })
export class PartnerContentDownloadTrackingService {
  constructor(
    private readonly http: HttpClient,
    private readonly partnerService: PartnerService,
  ) {}

  public trackCampaignContentDownload(
    campaignId: number,
    campaignContentDownload: ICampaignContentDownload,
  ): void {
    const partnerId = this.partnerService.currentPartnerId;

    this.http
      .post(
        `partner/${partnerId}/campaign/${campaignId}/track_content_download`,
        campaignContentDownload,
      )
      .subscribe(() => undefined);
  }
}
