import { Component, Inject, OnDestroy } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ConnectToLinkedInInteractor } from "../../../shared/interactors/linkedin/connect-to-linkedin.interactor";
import { LinkedInService } from "../../../shared/services/api/linkedin.service";
import { NotificationService } from "../../../shared/services/notification.service";

export interface LinkedInNotConnectedDialogData {
  partnerId: number;
}

export type LinkedInNotConnectedDialogResult = boolean;

@Component({
  selector: "app-linkedin-not-connected-dialog",
  templateUrl: "./linkedin-not-connected-dialog.component.html",
})
export class LinkedInNotConnectedDialogComponent implements OnDestroy {
  private readonly partnerId: number;

  constructor(
    private readonly dialogRef: MatDialogRef<
      LinkedInNotConnectedDialogComponent,
      LinkedInNotConnectedDialogResult
    >,
    private readonly linkedInService: LinkedInService,
    private readonly connectToLinkedin: ConnectToLinkedInInteractor,
    private readonly notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) data: LinkedInNotConnectedDialogData,
  ) {
    this.partnerId = data.partnerId;
  }

  public ngOnDestroy(): void {
    window.onstorage = null;
  }

  public async onActionConnect(): Promise<void> {
    window.onstorage = async () => {
      if (await this.connectToLinkedin.execute(this.partnerId)) {
        this.dialogRef.close(true);
        return;
      }
      this.notificationService.error("linkedinConnectDialog.error");
      this.dialogRef.close(false);
    };

    this.linkedInService.getAccessUrl(this.partnerId).then((url) => {
      window.open(url, "_blank");
    });
  }
}
