import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  AcceptedPartnerCampaignError,
  AcceptedPartnerCampaignErrorResponse,
} from "../../../../shared/services/api/partner-campaign.service";
import { PartnerGoogleAdPostLog } from "../../../../shared/models/partnerGoogleAdPostLog";
import { PartnerFacebookPostLog } from "../../../../shared/models/partnerFacebookPostLog";
import { PostLog } from "../../../../shared/models/postLog";

export interface CampaignPostsCheckDialogData {
  readonly postsWithError: AcceptedPartnerCampaignErrorResponse[];
}

export type CampaignPostsCheckDialogResult = undefined;

@Component({
  selector: "app-campaign-posts-check-dialog",
  templateUrl: "./campaign-posts-check-dialog.component.html",
  styleUrls: ["./campaign-posts-check-dialog.component.scss"],
})
export class CampaignPostsCheckDialogComponent {
  public readonly postsWithError: AcceptedPartnerCampaignErrorResponse[] = [];
  public readonly CampaignError = AcceptedPartnerCampaignError;

  constructor(@Inject(MAT_DIALOG_DATA) data: CampaignPostsCheckDialogData) {
    this.postsWithError = data.postsWithError;
  }

  public isUnknownError(errorKey: AcceptedPartnerCampaignError): boolean {
    return !Object.values(this.CampaignError).includes(errorKey);
  }

  public getFacebookPostLog(postLog: PostLog): PartnerFacebookPostLog {
    return postLog as PartnerFacebookPostLog;
  }

  public getGoogleAdPostLog(postLog: PostLog): PartnerGoogleAdPostLog {
    return postLog as PartnerGoogleAdPostLog;
  }
}
