<h2 mat-dialog-title>
  {{ "facebookConnectDialog.title" | translate }}
  <span class="close-icon" [mat-dialog-close]="">
    <mat-icon>close</mat-icon>
  </span>
</h2>
<mat-dialog-content [ngClass]="{ loading: isLoading }">
  <p>{{ "facebookConnectDialog.subtitle" | translate }}</p>
  <mat-form-field class="full-width">
    <mat-label>{{
      "facebookConnectDialog.facebookPage" | translate
    }}</mat-label>
    <mat-select [(ngModel)]="selectedPage">
      <mat-option *ngFor="let page of availablePages" [value]="page">
        {{ page.name }}
        <span *ngIf="page.instagramUsername">
          - {{ page.instagramUsername }}</span
        >
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="selected-page-box" *ngIf="selectedPage">
    <div class="facebook-page" *ngIf="selectedPage.facebookPageId">
      <img
        src="{{ selectedPage.facebookPagePicture }}"
        alt=""
        class="facebook-page-image"
      />
      <div>
        <span class="facebook-page-name">{{
          selectedPage.facebookPageName
        }}</span>
        <span class="facebook-page-followers">
          {{ selectedPage.facebookPageLikes }}
          {{ "shared.followers" | translate }}
        </span>
      </div>
    </div>
    <div
      *ngIf="selectedPage.instagramBusinessAccountId"
      class="instagram-connected"
    >
      <img
        src="{{ selectedPage.instagramPictureUrl }}"
        alt=""
        class="instagram-connected-image"
      />
      <div class="instagram-connected-page">
        <div class="instagram-connected-page-title">
          {{ "partner.edit.instagramAccount" | translate }}
        </div>
        <div class="instagram-connected-page-name">
          {{ selectedPage.instagramUsername }}
        </div>
      </div>
    </div>
  </div>
  <p class="fb-connect-feedback text-dark-grey text-small" *ngIf="isLoading">
    <img
      src="../../../../assets/images/icons/ic_loader.svg"
      class="icon spin-loader"
    />
    {{ "facebookConnectDialog.loadingText" | translate }}
  </p>
  <p
    class="fb-connect-feedback text-dark-grey text-small"
    *ngIf="!isLoading && availablePages.length === 0"
  >
    <img
      src="../../../../assets/images/icons/ic_search_result.svg"
      class="icon"
    />
    {{ "facebookConnectDialog.noResults" | translate }}&nbsp;
  </p>
  <app-loader
    [text]="'facebookConnectDialog.connectingLoader' | translate"
    [ngClass]="{ visible: isLoading }"
  >
  </app-loader>
  <div *ngIf="isSaving" class="fb-connect-bar">
    <span class="connecting-to-facebook">
      {{ "facebookConnectDialog.connecting" | translate }}
    </span>
    <mat-progress-bar
      style="margin-top: 10px; margin-bottom: 5px"
      [color]="'accent'"
      mode="indeterminate"
    >
    </mat-progress-bar>
    <span class="connecting-to-facebook-subtitle">
      {{ "facebookConnectDialog.connectingPatient" | translate }}
    </span>
  </div>
</mat-dialog-content>
<mat-dialog-actions [ngClass]="{ loading: isLoading }">
  <button
    class="button button-orange button-full-width"
    [disabled]="isSaving || !selectedPage"
    (click)="savePage()"
  >
    {{ "shared.save" | translate }}
  </button>
</mat-dialog-actions>
