import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { FacebookService } from "../../../shared/services/facebook.service";
import { FacebookPageInfoResponse } from "../../../shared/entities/facebook";

export type FacebookConnectDialogData = undefined;
export type FacebookConnectDialogResult = FacebookPageInfoResponse | undefined;

@Component({
  selector: "app-facebook-connect-dialog",
  templateUrl: "./facebook-connect-dialog.component.html",
  styleUrls: ["./facebook-connect-dialog.component.scss"],
})
export class FacebookConnectDialogComponent implements OnInit {
  public selectedPage?: FacebookPageInfoResponse = undefined;
  public availablePages: FacebookPageInfoResponse[] = [];
  public isLoading = false;
  public isSaving = false;

  constructor(
    private readonly fbService: FacebookService,
    private readonly dialogRef: MatDialogRef<
      FacebookConnectDialogComponent,
      FacebookConnectDialogResult
    >,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.isSaving = false;

    try {
      this.availablePages = await this.fbService.getUserAccounts();
    } catch (err) {
      console.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  public async savePage(): Promise<void> {
    this.isSaving = true;
    this.dialogRef.close(this.selectedPage);
  }
}
