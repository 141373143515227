<div class="post-item-form">
  <div class="post-item-input-container step-container">
    <span *ngIf="showBulletPoint" class="step-number">1</span>
    <div
      *ngIf="suggestedText && textHasChanged"
      class="suggested-text-button"
      (click)="useSuggestedText(post)"
    >
      <mat-icon>text_fields</mat-icon>
      <span>{{
        "partner.campaign.suggestedTextEditor.clickToUseSuggestedText"
          | translate
      }}</span>
    </div>
    <span *ngIf="showBulletPoint" class="suggested-text-extra">
      ({{ "partner.campaign.suggestedTextEditor.customizeBelow" | translate }})
    </span>
    <mat-form-field>
      <mat-label>{{
        "partner.campaign.suggestedTextEditor.add" +
          (post.platform | caseTransform: "RegularToCapitalized") +
          "PostText" | translate
      }}</mat-label>
      <textarea
        matInput
        [(ngModel)]="post.text"
        (ngModelChange)="onTextEdit(post.text)"
      ></textarea>
      <mat-hint align="end">{{ postCharacters }}</mat-hint>
    </mat-form-field>
  </div>
  <div *ngIf="showSuggestedText" class="post-item-suggested-text">
    <p class="suggested-title">
      {{ "partner.campaign.suggestedTextEditor.suggestedText" | translate }}
    </p>
    <pre class="suggested-text" [innerHtml]="suggestedText"></pre>
  </div>
</div>
