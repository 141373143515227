<h2 mat-dialog-title>
  {{ "partner.campaign.connectWithLinkedIn.title" | translate }}
</h2>
<mat-dialog-content>
  <p>
    {{ "partner.campaign.connectWithLinkedIn.connectTitle" | translate }}
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn btn--transparent" [mat-dialog-close]="">
    {{ "partner.campaign.connectWithLinkedIn.dismiss" | translate }}
  </button>
  <button class="btn btn--primary" (click)="onActionConnect()">
    {{ "partner.campaign.connectWithLinkedIn.connect" | translate }}
  </button>
</mat-dialog-actions>
