<h2 mat-dialog-title>
  {{ "partner.edit.googleIntegration" | translate }}
</h2>

<mat-dialog-content class="connection">
  <img src="../../../../assets/images/google-ads-logo-rectangle.svg" />
  <div class="connection__text">
    {{ "partner.edit.googleIntegrationInfo" | translate }}
  </div>
  <div class="connection__checkbox">
    <form [formGroup]="form">
      <mat-checkbox formControlName="connection">
        {{ "partner.edit.googleIntegrationSubtitle" | translate }}
      </mat-checkbox>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button class="btn btn--transparent" [mat-dialog-close]="false">
    {{ "shared.cancel" | translate }}
  </button>
  <button
    class="btn btn--primary"
    (click)="save()"
    [disabled]="isConnected === form.controls['connection'].value || loading"
  >
    <ng-container *ngIf="!loading">{{
      "shared.save" | translate
    }}</ng-container>
    <ng-container *ngIf="loading">{{
      "shared.saving" | translate
    }}</ng-container>
  </button>
</mat-dialog-actions>
