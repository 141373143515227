import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { Address, AddressType } from "../../shared/models/address";
import { NotificationService } from "../../shared/services/notification.service";
import { cloneWith } from "../../shared/services/clonable";
import { UnreachableCaseError } from "../../../harmony/core";

export interface ChooseAddressDialogData {
  readonly address?: Address;
  readonly addresses: Address[];
  readonly type: AddressType;
}

export type ChooseAddressDialogResult = Address;

@Component({
  selector: "app-partner-choose-address",
  templateUrl: "./choose-address-dialog.component.html",
  styleUrls: ["./choose-address-dialog.component.scss"],
})
export class ChooseAddressDialogComponent {
  protected readonly address: Address;
  protected readonly canChangeDefault: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: ChooseAddressDialogData,
    private readonly dialogRef: MatDialogRef<
      ChooseAddressDialogComponent,
      ChooseAddressDialogResult
    >,
    private readonly notificationService: NotificationService,
  ) {
    if (data.address) {
      this.address = cloneWith(data.address);
      this.canChangeDefault = data.addresses.some(
        ({ type }) => type === this.data.type,
      );
    } else {
      switch (this.data.type) {
        case AddressType.Billing:
          this.address = Address.createBilling();
          break;

        case AddressType.Shipping:
          this.address = Address.createShipping();
          break;

        default:
          throw new UnreachableCaseError(this.data.type);
      }

      this.canChangeDefault = true;
    }
  }

  protected addressCreatedOrUpdated(address: Address): void {
    if (this.hasTwoEqualAddresses(address)) {
      this.notificationService.error("partner.edit.editAddressChanged");
    }

    this.dialogRef.close(address);
  }

  protected cancel(): void {
    this.dialogRef.close();
  }

  private hasTwoEqualAddresses(address: Address): boolean {
    const equal = (a: string, b: string) =>
      a.trim().toLowerCase() === b.trim().toLowerCase();

    return this.data.addresses
      .filter(({ id, type }) => id !== address.id && type === address.type)
      .some(
        ({ postalCode, streetAndNumber, country }) =>
          equal(postalCode, address.postalCode) &&
          equal(streetAndNumber, address.streetAndNumber) &&
          equal(country, address.country),
      );
  }
}
