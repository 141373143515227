import { PostLog } from "../../shared/models/postLog";

export class SavePostLogData {
  constructor(
    public readonly postLog: PostLog,
    public readonly scheduledPublishDateAndTime: Date,
    public readonly isPromoAd: boolean,
    public readonly postToFacebook: boolean,
    public readonly postToInstagram: boolean,
  ) {}
}
