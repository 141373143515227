import { Component } from "@angular/core";

export type LeaveDialogData = undefined;
export type LeaveDialogResult = true;

@Component({
  selector: "app-leave-dialog",
  templateUrl: "./leave-dialog.component.html",
})
export class LeaveDialogComponent {}
