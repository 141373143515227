<button class="close-icon" tabindex="-1" [mat-dialog-close]="false">
  <mat-icon>close</mat-icon>
</button>
<h2 mat-dialog-title>
  {{ "partner.campaign.header.campaignWaitingValidationTitle" | translate }}
</h2>
<mat-dialog-content class="pre-approve">
  <img
    class="pre-approve__image"
    src="/assets/images/as_pal_check.svg"
    alt="info"
  />
  <h1 class="pre-approve__title">
    {{ "partner.campaign.header.pendingPartnerApprovalMessage" | translate }}
  </h1>
  <p class="pre-approve__description">
    {{ "partner.preApproveDialog.acceptedNotStarted" | translate }}
  </p>
  <p class="pre-approve__description pre-approve__description--highlighted">
    {{ "partner.preApproveDialog.acceptedNotStartedSub" | translate }}
  </p>
  <form [formGroup]="form" *ngIf="canPreApproveABrand">
    <mat-radio-group class="pre-approve__options" formControlName="source">
      <mat-radio-button [value]="Sources.Brand">
        {{ "partner.preApproveDialog.preApproveAllFromBrand" | translate }}
      </mat-radio-button>
      <mat-radio-button [value]="Sources.All">
        {{ "partner.preApproveDialog.preApproveAllFromAnyBrand" | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button [mat-dialog-close]="false" class="btn btn--transparent">
    {{ "partner.preApproveDialog.waitVerificationFinish" | translate }}
  </button>
  <button (click)="onActionPreApprove()" class="btn btn--primary">
    {{ "partner.preApproveDialog.preApproveContent" | translate }}
  </button>
</mat-dialog-actions>
