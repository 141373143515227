<div class="promo-ads-instructions">
  <div class="row">
    <div class="col width-40">
      <div class="header">
        <img
          height="88"
          src="../../../../assets/images/as_palgirl_onemorething.png"
        />
        <div
          class="header-title"
          [innerHTML]="'partner.setUpFacebookAds.title' | translate"
        ></div>
      </div>
      <ul>
        <li>
          <span>1</span>
          <div
            [innerHTML]="
              'partner.setUpFacebookAds.stepOne'
                | translate
                  : { target: '_blank', facebookPageId: facebookPageId }
            "
          ></div>
        </li>
        <li>
          <span>2</span>
          <ng-container>{{
            "partner.setUpFacebookAds.stepTwo" | translate
          }}</ng-container>
        </li>
        <li>
          <span>3</span>
          <ng-container>{{
            "partner.setUpFacebookAds.stepThree" | translate
          }}</ng-container>
        </li>
      </ul>
      <p>{{ "partner.setUpFacebookAds.descriptionOne" | translate }}</p>
      <p>{{ "partner.setUpFacebookAds.descriptionTwo" | translate }}</p>
      <p>
        <ng-container>{{
          "partner.setUpFacebookAds.descriptionThree" | translate
        }}</ng-container>
      </p>
      <div *ngIf="showPermissionsNotGiven" class="permissions-not-given">
        {{ "partner.setUpFacebookAds.permissionsNotGiven" | translate }}
      </div>
      <div *ngIf="loading" class="loading-bar">
        <div class="loading-bar-title">
          {{ "partner.setUpFacebookAds.checkingLoader" | translate }}
        </div>
        <mat-progress-bar
          mode="indeterminate"
          color="accent"
        ></mat-progress-bar>
        <div class="loading-bar-info">
          {{ "partner.setUpFacebookAds.checkingLoaderInfo" | translate }}
        </div>
      </div>
      <div class="button-wrap">
        <button
          *ngIf="!loading"
          [disabled]="!partner"
          class="button button-orange"
          (click)="checkClaimStatus()"
        >
          {{ "partner.setUpFacebookAds.refreshButton" | translate }}
        </button>
      </div>
    </div>
    <div class="col width-60">
      <img
        class="instructions-image"
        src="/assets/images/facebook-ad-rights-dialog/{{
          assetsLanguage()
        }}@3x.png"
      />
    </div>
  </div>
</div>
