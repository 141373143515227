import {
  animate,
  AnimationBuilder,
  AnimationFactory,
  style,
} from "@angular/animations";
import {
  AfterViewInit,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  Input,
  QueryList,
  ViewChild,
} from "@angular/core";

import { PostsCarouselItemDirective } from "./posts-carousel-item.directive";

@Directive({
  selector: ".posts-carousel-item",
})
export class PostsCarouselItemElementDirective {}

@Component({
  selector: "app-posts-carousel",
  templateUrl: "./posts-carousel.component.html",
  styleUrl: "./posts-carousel.component.scss",
})
export class PostsCarouselComponent implements AfterViewInit {
  @Input() public showControls = true;

  @ContentChildren(PostsCarouselItemDirective)
  protected items!: QueryList<PostsCarouselItemDirective>;

  @ViewChild("carousel") private carousel!: ElementRef;

  protected currentSlide = 0;
  protected carouselWrapperStyle = {};

  private itemWidth!: number;

  constructor(private readonly builder: AnimationBuilder) {}

  public ngAfterViewInit(): void {
    // For some reason only here I need to add setTimeout, in my local env it's working without this.
    setTimeout(() => {
      this.itemWidth = 560; // this.itemsElements.first.nativeElement.getBoundingClientRect().width;
      this.carouselWrapperStyle = {
        width: `100%`,
      };
    });
  }

  public next(): void {
    if (this.currentSlide + 1 === this.items.length) {
      return;
    }

    this.currentSlide = (this.currentSlide + 1) % this.items.length;
    const offset = this.currentSlide * this.itemWidth;

    this.buildAnimation(offset).create(this.carousel.nativeElement).play();
  }

  private buildAnimation(offset: number): AnimationFactory {
    return this.builder.build([
      animate(
        "350ms ease-in-out",
        style({ transform: `translateX(-${offset}px)` }),
      ),
    ]);
  }

  public prev(): void {
    if (this.currentSlide === 0) {
      return;
    }

    this.currentSlide =
      (this.currentSlide - 1 + this.items.length) % this.items.length;
    const offset = this.currentSlide * this.itemWidth;

    this.buildAnimation(offset).create(this.carousel.nativeElement).play();
  }
}
