import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { AdActionType } from "../../shared/enums/campaign.enums";
import { CampaignPostContentType } from "../../shared/enums/campaignPost.enums";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

import { Partner } from "../../shared/models/partner";
import { PartnerCampaign } from "../../shared/models/partnerCampaign";
import { PartnerCampaignFacebookPost } from "../../shared/models/partnerCampaignFacebookPost";
import { PartnerCampaignPost } from "../../shared/models/partnerCampaignPost";
import { PartnerFacebookPostLog } from "../../shared/models/partnerFacebookPostLog";
import { PostLog } from "../../shared/models/postLog";
import { HOURS_24, MINUTES } from "../../shared/services/calendar.data";
import { SavePostLogData } from "./save-post-log-data";
import { PublishPostData } from "./publish-post-data";

@Component({
  selector: "app-partner-campaign-publish-post-time-picker",
  templateUrl: "./partner-campaign-publish-post-time-picker.component.html",
  styleUrl: "./partner-campaign-publish-post-time-picker.component.scss",
})
export class PartnerCampaignPublishPostTimePickerComponent implements OnInit {
  @Input({ required: true }) public campaign!: PartnerCampaign;
  @Input() public partner?: Partner;
  @Input() public post?: PartnerCampaignPost;
  @Input() public postLog?: PostLog;
  @Input() public publishing = false;
  @Input() public showPromoteAdCheckbox = false;
  @Input() public stickButtonToBottom = true;
  @Input() public hasError = false;

  @Output() public readonly publishButtonClickedEvent =
    new EventEmitter<PublishPostData>();
  @Output() public readonly saveButtonClickedEvent =
    new EventEmitter<SavePostLogData>();

  protected readonly HOURS_24 = HOURS_24;
  protected readonly MINUTES = MINUTES;
  protected isPostToInstagramDisabled = false;
  protected isPromoAd = false;
  protected maxDate!: Date;
  protected minDate!: Date;
  protected postToFacebook = false;
  protected postToInstagram = false;
  protected scheduledPublishDate!: Date;
  protected scheduledPublishDateHour!: string;
  protected scheduledPublishDateMinute!: string;

  private isFacebookPost = false;
  private isCreateAdNotAllowed = false;
  private scheduledPublishDateAndTime!: Date;

  public ngOnInit(): void {
    this.maxDate = this.campaign.endDate;
    this.minDate = this.campaign.startDate;

    if (this.minDate < new Date()) {
      this.minDate = new Date();
    }

    if (this.postLog instanceof PartnerFacebookPostLog) {
      this.initData(this.postLog);
    }

    if (this.post instanceof PartnerCampaignFacebookPost) {
      this.initData(this.post);
    }

    if (this.postLog) {
      this.initPostLogDates();
    } else if (this.post) {
      this.initPostDates();
    }

    this.updatePostDate();
  }

  private initPostDates(): void {
    this.scheduledPublishDateAndTime = new Date();
    // If the campaign starts in the future we set the default date to
    // the campaign start date
    if (this.scheduledPublishDateAndTime < this.campaign.startDate) {
      this.scheduledPublishDateAndTime = this.campaign.startDate;
    }
    // If it's past the campaign end date we set the date to the last day of the
    // campaign (Should not happen it's an edge case)
    const endTime = dayjs(this.campaign.endDate).utc().endOf("day").toDate();
    if (this.scheduledPublishDateAndTime > endTime) {
      this.scheduledPublishDateAndTime = endTime;
    }
    this.scheduledPublishDateAndTime = dayjs(this.scheduledPublishDateAndTime)
      .add(1, "hours")
      .toDate();
    this.scheduledPublishDateHour = dayjs(
      this.scheduledPublishDateAndTime,
    ).format("HH");
    this.scheduledPublishDateMinute = dayjs(
      this.scheduledPublishDateAndTime,
    ).format("mm");
    this.scheduledPublishDate = dayjs(this.scheduledPublishDateAndTime)
      .add(1, "hours")
      .toDate();
  }

  private initPostLogDates(): void {
    this.scheduledPublishDateAndTime = this.postLog!.scheduledPublishDate;
    this.scheduledPublishDateHour = dayjs(
      this.postLog!.scheduledPublishDate,
    ).format("HH");
    this.scheduledPublishDateMinute = dayjs(
      this.postLog!.scheduledPublishDate,
    ).format("mm");
    this.scheduledPublishDate = this.postLog!.scheduledPublishDate;
  }

  private initData(
    post: PartnerFacebookPostLog | PartnerCampaignFacebookPost,
  ): void {
    this.postToFacebook = post.postToFacebook;
    this.isFacebookPost = post.isFacebookPost;
    this.isPromoAd = !!this.postLog?.hasAd || !!this.post?.willBecomeAd;

    if (!post.canBePostedToInstagram) {
      this.postToInstagram = false;
      this.isPostToInstagramDisabled = true;
    } else {
      this.postToInstagram = post.postToInstagram;
      this.isPostToInstagramDisabled = false;
    }

    this.isCreateAdNotAllowed = this.getIsCreateAdCheckboxNotAllowed(post);
  }

  private getIsCreateAdCheckboxNotAllowed(
    post: PartnerCampaignFacebookPost | PartnerFacebookPostLog,
  ): boolean {
    return (
      this.campaign.adActionType === AdActionType.Boost &&
      (post.mediaType === CampaignPostContentType.Gif ||
        post.mediaType === CampaignPostContentType.Image360)
    );
  }

  protected updatePostDate(): void {
    this.scheduledPublishDateAndTime = dayjs(this.scheduledPublishDate)
      .hour(+this.scheduledPublishDateHour)
      .minute(+this.scheduledPublishDateMinute)
      .toDate();
  }

  protected saveButtonClick(): void {
    this.saveButtonClickedEvent.emit(
      new SavePostLogData(
        this.postLog!,
        dayjs(this.scheduledPublishDateAndTime).utc().toDate(),
        this.isPromoAd,
        this.postToFacebook,
        this.postToInstagram,
      ),
    );
  }

  protected publishButtonClick(): void {
    this.publishButtonClickedEvent.emit(
      new PublishPostData(
        this.post!,
        dayjs(this.scheduledPublishDateAndTime).utc().toDate(),
        this.postToFacebook,
        this.postToInstagram,
      ),
    );
  }

  protected getAdsCreatedString(): string {
    if (!this.campaign) {
      return "";
    }
    return `${this.campaign.numberOfAdsCreated} of ${this.campaign.maximumNumberOfAds}`;
  }

  public get checkDateSelected(): boolean {
    return (
      this.scheduledPublishDate &&
      dayjs(this.scheduledPublishDate).isSameOrAfter(this.campaign.startDate) &&
      dayjs(this.scheduledPublishDate).isSameOrBefore(
        dayjs(this.campaign.endDate).endOf("day"),
      )
    );
  }

  protected isPublishDisabled(): boolean {
    if (this.publishing || this.hasError) {
      return true;
    }

    if (!this.checkDateSelected) {
      return true;
    }

    if (
      !this.scheduledPublishDateAndTime ||
      !this.scheduledPublishDateHour ||
      !this.scheduledPublishDateMinute
    ) {
      return true;
    }

    if (this.isFacebookPost) {
      if (!this.postToFacebook && !this.postToInstagram) {
        return true;
      }
      if (this.isPromoAd && this.isCreateAdNotAllowed) {
        return true;
      }
    }

    return false;
  }

  protected isAPostLogWithAlreadyPaidAd(): boolean {
    return !!this.postLog && this.postLog.hasAd && !!this.postLog.ad?.isPaidAd;
  }

  protected showPromoAdsOption(): boolean {
    if (!this.campaign) {
      return false;
    }
    if (!this.campaign.useFacebookAds) {
      return false;
    }
    if (this.postLog?.isLinkedInPost) {
      return false;
    }
    if (!this.campaign.promoAdsEnabled) {
      return false;
    }
    if (this.isCreateAdNotAllowed) {
      return false;
    }
    return this.showPromoteAdCheckbox;
  }

  protected showPostToCheckboxes(): boolean {
    const post = this.post || this.postLog;
    return post!.isFacebookPost && post!.canBePostedToInstagram;
  }
}
