import { Component, Inject, OnDestroy, inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { lastValueFrom } from "rxjs";
import { ConnectToLinkedInInteractor } from "../../../shared/interactors/linkedin/connect-to-linkedin.interactor";
import { Partner } from "../../../shared/models/partner";
import { LinkedInService } from "../../../shared/services/api/linkedin.service";
import { PartnerCampaignService } from "../../../shared/services/api/partner-campaign.service";
import { PartnerService } from "../../../shared/services/api/partner.service";
import { NotificationService } from "../../../shared/services/notification.service";
import {
  CheckId,
  SnConnectionsViewState,
} from "./partner-check-third-party-connections-dialog.view-state";
import { PartnerDialogService } from "../../partner-dialog.service";

export interface PartnerCheckThirdPartyConnectionsDialogData {
  readonly context: "calendar" | "pre-approve";
  readonly thirdPartyConnections?: CheckId[];
}

export type PartnerCheckThirdPartyConnectionsDialogResult = true;

@Component({
  selector: "app-partner-check-third-party-connections-dialog",
  templateUrl: "./partner-check-third-party-connections-dialog.component.html",
  styleUrl: "./partner-check-third-party-connections-dialog.component.scss",
})
export class PartnerCheckThirdPartyConnectionsDialogComponent
  implements OnDestroy
{
  protected viewState!: SnConnectionsViewState;

  private readonly partner: Partner;
  private readonly partnerCampaign = inject(PartnerCampaignService)
    .currentPartnerCampaign;

  constructor(
    private readonly partnerService: PartnerService,
    private readonly partnerDialogService: PartnerDialogService,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: PartnerCheckThirdPartyConnectionsDialogData,
    private readonly connectToLinkedin: ConnectToLinkedInInteractor,
    private readonly linkedInService: LinkedInService,
    private readonly notificationService: NotificationService,
  ) {
    this.partner = this.partnerService.currentPartner;
    this.refreshView();
  }

  private refreshView(): void {
    this.viewState = new SnConnectionsViewState(
      this.data.context,
      this.partnerCampaign,
      this.partner.areGoogleAdsAuthorized,
      this.partner.isConnectedToFB,
      this.partner.isConnectedToInstagram,
      this.partner.isAuthorizedFBAds,
      this.partner.isConnectedToLinkedIn,
      this.data.thirdPartyConnections,
    );
  }

  public async ngOnDestroy(): Promise<void> {
    window.onstorage = null;
  }

  public async onActionLinkPressed(check: CheckId): Promise<void> {
    const partnerData = {
      partner: this.partner,
    };

    const campaignData = {
      campaign: this.partnerCampaign,
    };

    let connectionChanged = false;

    switch (check) {
      case CheckId.FbConnection:
      case CheckId.IgConnection:
        connectionChanged =
          !!(await this.partnerDialogService.showFacebookNotConnected(
            partnerData,
          ));
        break;
      case CheckId.GoogleConnection:
        connectionChanged =
          (await this.partnerDialogService.showGoogleAdsConnection()) ?? false;
        break;
      case CheckId.LinkedInConnection:
        await this.startLinkedinConnection();
        break;
      case CheckId.FbAdsPermissions:
        connectionChanged =
          (await this.partnerDialogService.showPartnerAuthorizeFacebookAds(
            partnerData,
          )) ?? false;
        break;
      case CheckId.ShortUrlExists:
        connectionChanged =
          !!(await this.partnerDialogService.showInvalidCampaignShortUrl(
            campaignData,
          ));
        break;
    }

    if (connectionChanged) {
      await this.refreshView();
    }
  }

  private async startLinkedinConnection(): Promise<void> {
    window.onstorage = async () => {
      if (await this.connectToLinkedin.execute(this.partner.id)) {
        await lastValueFrom(this.partnerService.fetchCurrentPartner());
        await this.refreshView();
      } else {
        this.notificationService.error("linkedinConnectDialog.error");
      }
    };

    this.linkedInService.getAccessUrl(this.partner.id).then((url) => {
      window.open(url, "_blank");
    });
  }
}
