import { Injectable } from "@angular/core";
import { LinkedInService } from "../../services/api/linkedin.service";
import { StorageKeys } from "../../services/local-storage.service";
import { safeLocalStorage } from "../../utils/safe-storage";
import { PartnerDialogService } from "../../../partner/partner-dialog.service";

@Injectable()
export class ConnectToLinkedInInteractor {
  constructor(
    private readonly linkedInService: LinkedInService,
    private readonly partnerDialogService: PartnerDialogService,
  ) {}

  public async execute(partnerId: number): Promise<boolean> {
    const connectionRaw = safeLocalStorage.getItem(
      StorageKeys.LinkedInConnection,
    );

    if (connectionRaw) {
      const connection = JSON.parse(connectionRaw);

      safeLocalStorage.removeItem(StorageKeys.LinkedInConnection);

      try {
        await this.linkedInService.requestPartnerAccess(
          partnerId,
          connection.code,
          connection.state,
        );

        return (
          (await this.partnerDialogService.showLinkedinOrganizationsConnection()) ??
          false
        );
      } catch {}
    }

    return false;
  }
}
