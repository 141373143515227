import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PartnerCampaign } from "../../../../shared/models/partnerCampaign";
import { PartnerCampaignDetails } from "../../../../shared/models/partnerCampaignDetail";
import { PartnerCampaignCalendar } from "../../../../shared/models/partnerCampaignCalendar";

export interface CalendarSetupStartDialogData {
  readonly campaign: PartnerCampaign;
}

export type CalendarSetupStartDialogResult = true;

@Component({
  selector: "app-setup-start-dialog",
  templateUrl: "./setup-start-dialog.component.html",
  styleUrl: "./setup-start-dialog.component.scss",
})
export class CalendarSetupStartDialogComponent {
  protected readonly campaignDetails: PartnerCampaignDetails;
  protected readonly currentCalendar: PartnerCampaignCalendar;
  protected readonly showDetails: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) data: CalendarSetupStartDialogData) {
    this.campaignDetails = data.campaign.currentDetails!;
    this.currentCalendar = data.campaign.currentCalendar!;
    this.showDetails = (this.campaignDetails.calendars ?? []).length > 1;
  }
}
