import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { map } from "rxjs/operators";
import { FacebookPageDetails } from "../entities/facebook/facebook-page-details";
import { PartnerService } from "./api/partner.service";
import { HttpClient } from "./http-client";
import { FacebookPageInfoResponse } from "../entities/facebook";
import { FacebookApiService } from "./facebook-api.service";
import { isNonEmptyString } from "../utils/assert";

@Injectable()
export class FacebookService {
  private readonly REQUIRED_SCOPES = [
    "public_profile",
    "pages_manage_posts",
    "pages_read_engagement",
    "pages_read_user_content",
    "read_insights",
    "business_management",
  ];

  private readonly SCOPES = [
    "instagram_basic",
    "instagram_content_publish",
    "instagram_manage_insights",
    "pages_show_list",
    ...this.REQUIRED_SCOPES,
  ];

  constructor(
    private readonly fb: FacebookApiService,
    private readonly http: HttpClient,
    private readonly partnerService: PartnerService,
  ) {}

  public async login(forceLogout = false): Promise<string | undefined> {
    const accessToken = await this.fb.getAccessToken();

    if (accessToken) {
      if (!forceLogout) {
        return accessToken;
      }

      await this.logout();
    }

    const authResponse = await this.fb.login(this.SCOPES);

    if (authResponse) {
      if (!isNonEmptyString(authResponse.grantedScopes)) {
        return undefined;
      }

      const grantedScopes = authResponse.grantedScopes.split(",");
      const hasRequiredScopes = this.REQUIRED_SCOPES.every((scope) =>
        grantedScopes.includes(scope),
      );

      if (hasRequiredScopes) {
        return authResponse.accessToken;
      }
    }
  }

  public async logout(): Promise<void> {
    const accessToken = await this.fb.getAccessToken();

    if (!accessToken) {
      return;
    }

    return this.fb.logout();
  }

  public async getUserAccounts(): Promise<FacebookPageInfoResponse[]> {
    const accessToken = await this.fb.getAccessToken();

    if (!accessToken) {
      return [];
    }

    // We exchange the User's token for a long-lived one via our server
    // Using a long-lived token to fetch the user's pages
    // will result in Page Tokens that never expire
    // More info in: https://developers.facebook.com/docs/facebook-login/access-tokens/expiration-and-extension
    const userLongLivedAccessToken = await this.exchangeToken(accessToken);

    // Set the access token in the partner service, it will be used later
    this.partnerService.facebookUserAccessToken = userLongLivedAccessToken;

    // Get the FB pages for which the current logged in user is 'admin'
    const userPages = await this.fb.getUserPages(userLongLivedAccessToken);

    const pages = (
      await Promise.all(
        userPages.map<Promise<FacebookPageInfoResponse | undefined>>(
          async (page) => {
            const details = await this.getPageDetails(
              page.id,
              page.access_token,
            );

            if (details.facebookPageId || details.instagramBusinessAccountId) {
              return {
                id: page.id,
                likes: details.likes,
                name: page.name,
                accessToken: page.access_token,
                picture: details.picture,
                facebookPageId: details.facebookPageId ?? undefined,
                facebookPageLikes: details.facebookPageLikes ?? undefined,
                facebookPageName: details.facebookPageName ?? undefined,
                facebookPageAbout: details.facebookPageAbout ?? undefined,
                facebookPageCategory: details.facebookPageCategory ?? undefined,
                facebookPagePicture: details.facebookPagePicture ?? undefined,
                instagramBusinessAccountId:
                  details.instagramBusinessAccountId ?? undefined,
                instagramPictureUrl: details.instagramPictureUrl ?? undefined,
                instagramUsername: details.instagramUsername ?? undefined,
              };
            } else {
              return undefined;
            }
          },
        ),
      )
    ).filter((page): page is FacebookPageInfoResponse => !!page);

    // Sort pages by name ASC
    pages.sort((a, b) =>
      a.name.toUpperCase().localeCompare(b.name.toUpperCase()),
    );

    return pages;
  }

  private exchangeToken(shortLivedToken: string): Promise<string> {
    return lastValueFrom(
      this.http
        .post(
          `facebook/access-token`,
          JSON.stringify({ facebookUserAccessToken: shortLivedToken }),
        )
        .pipe(map(({ body }) => body.facebookUserAccessTokenLongLived)),
    );
  }

  public getPageDetails(
    id: string,
    accessToken?: string,
  ): Promise<FacebookPageDetails> {
    return lastValueFrom(
      this.http
        .post(
          `facebook/page`,
          JSON.stringify({
            facebookPageId: id,
            facebookPageAccessToken: accessToken,
          }),
        )
        .pipe(map(({ body }) => body)),
    );
  }
}
